import { Controller } from "stimulus"
import { TempusDominus } from "@eonasdan/tempus-dominus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {

  static targets = [ "filter", "customer", "contact", "address", "date", "state", "paid", "retention", "equivalence_surcharge", "transport", "total", "total_retention", "total_with_vat", "total_vat", "total_vat_sum", "total_eqs", "total_eqs_sum", "item", "item_total", "total_transport", "item_total_with_vat", "item_vat", "state_notification", "state_notification_addresses", "invoice_address_div", "invoice_address", "chkboxInvoiceAddress", "address_select", "invoice_address_select", "address_select_div", "currency", "currency_abbrvs", "vats_summary", "loading", "payment_methods", "payment_conditions", "shipment", "quote_validity", "price_category", "fulfillment", "locale", "item_position", "documents", "attachments_counter", "attachment_uploading", "addButton", "addButtonIconPlus", "addButtonIconSpinner", "ext_reference", "ext_link", "ext_state", "delivery_costs", "delivery_warehouse_remove_alert", "delivery_warehouse_add_alert", "advStockMgmtPositions" ]

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String
  }

  initialize() {
    if(this.element.getAttribute("data-orders-vats")) {
      this.vats = this.element.getAttribute("data-orders-vats").split(';');
      this.eqs = this.element.getAttribute("data-orders-eqs").split(';');
    }

    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }

    if(this.element.getAttribute("data-vat-label")) {
      this.vat_label = this.element.getAttribute("data-vat-label");
    }

    if(this.element.getAttribute("data-currency")) {
      this.currency = this.element.getAttribute("data-currency");
    }

    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }
    if(this.element.getAttribute("data-adv-warehouse-mgmt")) {
      this.advWarehouseMgmt = true;
    }
  }

  connect() {
    let that = this;
    if(this.page == 'index' || this.page == 'archive') {
      this.initTableFunctions();
      this.initData();

      let filter;
      if(this.page == 'archive') {
        filter = Cookies.get('filter_orders_archive');
      } else {
        filter = Cookies.get('filter_orders');
      }

      if(filter && this.hasFilterTarget) {
        this.filterTarget.value = filter;
        this.filterOrders();
      }
    }

    if(this.page == 'dhl_express') {
      this.initDHLTableFunctions();
      this.initDHLData();
    }

    if(this.element.getAttribute("data-copy")) {
      this.loadOrder();
      this.newOrderFromExisting();
    }

    if(this.element.getAttribute("data-uuid")) {
      this.loadOrder();
    }

    const queryString = window.location.search;
    if(queryString != '') {
      const urlParams = new URLSearchParams(queryString);
      let param = urlParams.get('tab');
      if(param) {
        let btn = document.querySelector(`button[data-bs-target="#nav-${param}"]`)
        if(btn) {
          var tab = new bootstrap.Tab(btn)
          tab.show();
        }
      }
    }

    var sp_state = $("#order_state").selectpicker({ width: '150px'});

    var sp = $("#order_customer").selectpicker({ width: '100%'});
    sp.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      that.customerSelected(e);
    });

    if(document.querySelector('#order_state_notification')) {
      if(that.state_notificationTarget.checked) {
        $('#order_state_notification_addresses').attr('disabled', false)
        document.querySelector('#infoStateNotification').style.display = 'block';
      } else {
        $('#order_state_notification_addresses').attr('disabled', true);
      }

      var sp_notification = $("#order_state_notification_addresses").selectpicker({ width: '100%'});
      sp_notification.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        if(that.state_notification_addressesTarget.value.trim() == '') {
          document.querySelector('#infoStateNotificationWarning').classList.remove('d-none');
        } else {
          document.querySelector('#infoStateNotificationWarning').classList.add('d-none');
        }
      });
    }

    if(this.hasAddress_selectTarget) {
      $(this.address_selectTarget).selectpicker().on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.setAlternateCustomerAddress(e.target.options[clickedIndex].dataset);
      });
    }

    if(this.hasInvoice_address_selectTarget) {
      $(this.invoice_address_selectTarget).selectpicker().on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.setAlternateCustomerInvoiceAddress(e.target.options[clickedIndex].dataset);
      });
    }

    var sp = $(".selectpicker.currency").selectpicker({ width: '250px'});
    sp.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      let items = document.querySelector(".items").querySelectorAll(".card");
      that.updateItemPrices(items, event.target.value);
    });

    var sp_fulfillment = $("#data_fulfillment").selectpicker({ width: '100%'});
    if(sp_fulfillment) {
      sp_fulfillment.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.fulfillmentSetWarehouses(e);
      });
    }

    if(document.querySelector('#datetimepicker1')) {
      new TempusDominus(document.getElementById('datetimepicker1'), {
        localization: {
          locale: this.locale
        },
        useCurrent: true,
        display: {
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false
          }
        }
      });
    }

    if(this.page == "new" || this.page == "edit") {
      let items = document.querySelector("#items");
      if(items) {
        this.enableItemSorting();
      }

      this.initItemSelectDialog();
    }

    if(this.page == "show") {
      const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
      const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
    }

    document.querySelectorAll(".trix-content").forEach((div) => {
      div.querySelectorAll("a").forEach((link) => {
        link.setAttribute("target", "_blank");
      });
    });

    $("#order_locale").selectpicker({ width: '150px'});
    $("#order_currency").selectpicker({ width: 'auto'});
    $("#order_price_category").selectpicker({ width: 'auto'});

    $(".selectpicker").selectpicker({ width: '250px'});

    $('#loading').fadeOut(200);
  }

  onScroll(event) {
    if(this.hasLoadingTarget) {
      const rect = this.loadingTarget.getBoundingClientRect();
      const isInViewport = rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    }
  }

  filterOrders() {
    if(this.page == "archive") {
      Cookies.set('filter_orders_archive', this.filterTarget.value.toLowerCase());
    } else {
      Cookies.set('filter_orders', this.filterTarget.value.toLowerCase());
    }
  }

  clearFilter() {
    this.filterTarget.value = '';

    if(this.page == "archive") {
      Cookies.expire('filter_orders_archive');
    } else {
      Cookies.expire('filter_orders');
    }

    $("#orders").bootstrapTable('resetSearch', '');
    this.filterTarget.focus();
    this.filterTarget.blur();
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      return currency.toUpperCase();
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  loadOrder() {
    let that = this;
    let customer = this.customerTarget.value;
    let orderState = this.stateTarget.dataset.state;

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/contact/${customer}.json`,
      type: "get",
      data: "",
      success: function(data) {
        //that.setCustomerAddress(data);
        that.addressTarget.dataset.groups = data.groups;

        if(data.emails.length > 0) {
          var select = document.querySelector("#order_state_notification_addresses");
          for(let i = select.options.length - 1; i >= 0; i--) {
            select.remove(i);
          }

          data.emails.forEach((item, index) => {
            select.options[select.options.length] = new Option(item.email_address);
          });

          if(select.dataset.selected) {
            $(select).val(select.dataset.selected.split(','));
          }
          $(select).selectpicker('refresh');
        }
      },
      error: function(data) {}
    });

    let order_locale = this.locale;
    if(this.hasLocaleTarget) {
      order_locale = this.localeTarget.value;
    }

    //Set items
    document.querySelectorAll(".card").forEach((card, i) => {
      let uuid = card.querySelector("#item_item").value;
      let selectpicker = card.querySelector("select.warehouse");

      card.querySelector("#btn_item_open").classList.remove("d-none");
      card.querySelector("#btn_item_open").href = card.querySelector("#btn_item_open").href+uuid;

      if(uuid == '') {
        uuid = 'null';
      }

      //var sp_wh = $(selectpicker).selectpicker({ width: 'auto' });

      Rails.ajax({
        url: `/${this.locale}/item/${uuid}.json`,
        type: "get",
        data: "",
        success: function(data) {
          let label = card.querySelector("#item_label").parentElement.querySelector('label');
          label.innerText = data.name;

          if(order_locale != that.locale)
          {
            data.i18n.forEach( (i18n) => {
              if(i18n.locale == order_locale) {
                label.innerText = i18n.name;
              }
            })
          }

          if(data.number) {
            label.innerText += ` | ${data.number}`;
          }

          selectpicker.innerHTML = '';
          if(data.stocks) {
            for(let i = 0; i < data.stocks.length; i++) {
              let stock_quantity = data.stocks[i].stock;
              let subtext = '';
              if(stock_quantity) {
                subtext = `${card.dataset.item_available}:  ${stock_quantity}`
                if(data.reserved) {
                  subtext += ` | ${card.dataset.item_reserved}: ${data.reserved}`;
                  stock_quantity = stock_quantity-data.reserved;
                }
              }
              selectpicker.innerHTML += `<option value="${data.stocks[i].warehouse}" data-subtext="${subtext}" data-quantity="${stock_quantity}" style="color: ${data.stocks[i].color}">${data.stocks[i].warehouse_name}</option>`;

              if(this.advWarehouseMgmt) {
                let pos_count = 0;
                let html_positions = ``
                if(data.stocks[i].data && data.stocks[i].data.positions) {
                  let stock = data.stocks[i];

                  html_positions += `<div class="d-flex flex-column smaller adv-stock-mgmt-positions">`
                  data.stocks.forEach((stock, i) => {
                    html_positions += `<div class="form-group mb-2"><label for="item_warehouse">${stock.warehouse_name}</label><br>`
                    if(stock.data && stock.data.positions && stock.data.positions.length > 0) {

                      let positions = stock.data.positions.sort(function(a, b) {
                        return (a.expiration_date < b.expiration_date) ? -1 : ((a.expiration_date > b.expiration_date) ? 1 : 0);
                      });

                      html_positions += `
                        <table class="table table-sm w-auto">
                        <tr>
                          <th>${I18n.t('item.batch')}</th>
                          <th>${I18n.t('item.expiration_date')}</th>
                          <th>${I18n.t('item.available')}</th>
                          <th>${I18n.t('item.quantity')}</th>
                        </tr>
                      `
                      positions.forEach((position, i) => {

                        if(position.batch == '' || position.expiration_date == '') {
                          return
                        }

                        html_positions += `
                          <tr class="align-middle">
                            <td class="px-2">${position.batch}</td>
                            <td class="px-2">${position.expiration_date}</td>
                            <td class="px-2 text-end">${position.stock}</td>
                            <td>
                              <input type="hidden" id="position_item" name="adv_wh_mgmt_positions[][item]" value="${data.uuid}">
                              <input type="hidden" id="position_warehouse" name="adv_wh_mgmt_positions[][warehouse_uuid]" value="${stock.warehouse}">
                              <input type="hidden" id="position_warehouse" name="adv_wh_mgmt_positions[][warehouse_name]" value="${stock.warehouse_name}">
                              <input type="hidden" id="position_uuid" name="adv_wh_mgmt_positions[][uuid]" value="${position.uuid}">
                              <input type="hidden" id="position_batch" name="adv_wh_mgmt_positions[][batch]" value="${position.batch}">
                              <input type="hidden" id="position_expiration_date" name="adv_wh_mgmt_positions[][expiration_date]" value="${position.expiration_date}">
                              <input type="number" id="position_quantity" name="adv_wh_mgmt_positions[][quantity]" steps="any" class="form-control form-control-sm text-end" style="width:150px;" data-stock="${position.stock}" min="0" max="${position.stock}">
                            </td>
                          </tr>
                        `

                        pos_count += 1;
                      });
                      html_positions += `</table>`
                    }
                    html_positions += `</div>`
                  });
                  html_positions += `</div>`

                  if(pos_count > 0) {
                    let card_note = card.querySelector('.card-body > .collapse');
                    card_note.insertAdjacentHTML('beforebegin', html_positions);

                    if(card.querySelector(".adv-stock-mgmt-positions")) {
                      that.advStockMgmtUpdatePositions(card);
                    }  
                  }
                }
              }
            }
          }
          selectpicker.value = selectpicker.dataset.selected;
          let sp_wh = $(selectpicker).selectpicker({width: '250px'});
          sp_wh.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            let card = e.target.closest('.card');
            that.checkStockQuantity(card);
          });
/*
          sp_wh.selectpicker('destroy').selectpicker();
          sp_wh.selectpicker('val', selectpicker.dataset.selected);
          */
          that.checkStockQuantity(card);

          if(data.prices && data.prices.length > 0) {
            let customer = document.querySelector("#order_customer");
            for(let i = 0; i < data.prices.length; i++) {
              if(data.prices[i].contact == customer.value) {
                if(data.prices[i].price) {
                  card.querySelector("#customer_price").classList.remove('d-none');
                }
              }
            }
            if(data.prices.length > 1) {
              card.querySelector("#item_price").parentNode.querySelector(".multiple-prices").classList.remove("d-none");
            }
          }

          if(['40_shipped', '50_delivered', '60_completed'].includes(orderState)) {
            selectpicker.querySelectorAll('option').forEach((option, i) => {
              if(option.value != selectpicker.dataset.selected) {
                option.disabled = true;
              }
            });

            let vat_select = card.querySelector('.form-select.vat');
            vat_select.querySelectorAll('option').forEach((option, i) => {
              if(option.value != vat_select.value) {
                option.disabled = true;
              }
            });
          }
          sp_wh.selectpicker({width: '100%'});
        },
        error: function(data) {
          card.querySelector(".selectpicker.item").parentElement.classList.add("d-none");
        }
      });

/*
      sp_wh.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        let card = e.target.closest('.card');
        that.checkStockQuantity(card);
      });
      */

      if(['40_shipped', '50_delivered', '60_completed'].includes(orderState)) {
        card.querySelectorAll("input").forEach((item, i) => {
          item.readOnly = true;
          item.classList.add('bg-white');
        });

        card.querySelector(".btn-outline-danger").classList.add("d-none");
        card.querySelector(".btn-outline-info").classList.add("d-none");
      }
    });

    if(this.vats_summaryTarget.querySelectorAll(":scope > div:not(.d-none)").length > 0) {
      that.vats_summaryTarget.classList.add("border-bottom", "border-secondary");
    }

    this.updatePaymentConditions();
    this.refreshItemNumbering();
  }

  customerSelected(e) {
    let uuid = e.target.value;
    let that = this;

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/contact/${uuid}.json`,
      type: "get",
      data: "",
      success: function(data) {
        that.setCustomerAddress(data);

        if(data.firstname || data.lastname) {
          that.contactTarget.value = [data.firstname, data.lastname].join(' ');
        } else {
          that.contactTarget.value = '';
        }

        if(data.equivalence) {
          that.equivalence_surchargeTarget.checked = true;
          that.toggleEquivalenceSurcharge();
        }

        if(data.data && data.data.locale) {
          that.localeTarget.value = data.data.locale;
          $(that.localeTarget).selectpicker('refresh');
        }

        if(data.emails.length > 0) {
          var select = document.querySelector("#order_state_notification_addresses");
          for(let i = select.options.length - 1; i >= 0; i--) {
            select.remove(i);
          }

          data.emails.forEach((item, index) => {
            select.options[select.options.length] = new Option(item.email_address);
          });
          $(select).selectpicker('refresh');
        }

        if(data.payment_conditions) {
          that.payment_conditionsTarget.value = data.payment_conditions;
        } else {
          that.payment_conditionsTarget.value = that.payment_conditionsTarget.dataset.default;
        }

      },
      error: function(data) {}
    });
  }

  setCustomerAddress(data) {

    //Hidden fields
    let address_addressline1 = document.querySelector('#address_addressline1');
    let address_addressline2 = document.querySelector('#address_addressline2');
    let address_name = document.querySelector('#address_name');
    let address_street = document.querySelector('#address_street');
    let address_zip = document.querySelector('#address_zip');
    let address_location = document.querySelector('#address_location');
    let address_state = document.querySelector('#address_state');
    let address_country = document.querySelector('#address_country');

    let data_taxid = document.querySelector('#data_taxid');
    let data_fiscal_code = document.querySelector('#data_fiscal_code');
    let data_unique_code = document.querySelector('#data_unique_code');
    let data_cem = document.querySelector('#data_cem');

    let addr = '';
    if(data.company && data.company.trim() != '') {
      address_name.value = data.company;
    } else {
      if(data.firstname != '' || data.lastname != '') {
        address_name.value = (`${data.firstname} ${data.lastname}`).trim();
      }
    }

    if(data.addresses.length > 0) {

      let address = data.addresses.find(a => a.address_type == 'shipping_address');
      if(address) {

        //If addrelines are set, do not set the company name
        if((address.addressline1 && address.addressline1 != '') || (address.addressline2 && address.addressline2 != '')) {
          if(address.addressline1 && address.addressline1 != '') {
            addr += address.addressline1+"\n";
            address_addressline1.value = address.addressline1;
          } else {
            address_addressline1.value = '';
          }
          if(address.addressline2 && address.addressline2 != '') {
            addr += address.addressline2+"\n";
            address_addressline2.value = address.addressline2;
          } else {
            address_addressline2.value = '';
          }

          address_name.value = '';
        } else {
          addr += address_name.value+"\n";

          address_addressline1.value = '';
          address_addressline2.value = '';
        }

        addr += address.street+"\n";

        if(address.country && address.country != '') {
          addr += `${address.country}-${address.zip} ${address.location}`;
        } else {
          addr += `${address.zip} ${address.location}`;
        }
        address_street.value = address.street;
        address_zip.value = address.zip;
        address_location.value = address.location;

        if(address.state) {
          //addr += "\n"+address.state;
          address_state.value = address.state;
        } else {
          address_state.value = '';
        }

        if(address.country) {
          //addr += "\n"+countries.getName(address.country, this.locale);
          address_country.value = address.country;
        } else {
          address_country.value = '';
        }

      } else {

        //If addrelines are set, do not set the company name
        if((data.addresses[0].addressline1 && data.addresses[0].addressline1 != '') || (data.addresses[0].addressline2 && data.addresses[0].addressline2 != '')) {
          if(data.addresses[0].addressline1 && data.addresses[0].addressline1 != '') {
            addr += data.addresses[0].addressline1+"\n";
            address_addressline1.value = data.addresses[0].addressline1;
          } else {
            address_addressline1.value = '';
          }
          if(data.addresses[0].addressline2 && data.addresses[0].addressline2 != '') {
            addr += data.addresses[0].addressline2+"\n";
            address_addressline2.value = data.addresses[0].addressline2;
          } else {
            address_addressline2.value = '';
          }

          address_name.value = '';
        } else {
          addr += address_name.value+"\n";

          address_addressline1.value = '';
          address_addressline2.value = '';
        }

        addr += data.addresses[0].street+"\n";

        if(data.addresses[0].country && data.addresses[0].country != '') {
          addr += `${data.addresses[0].country}-${data.addresses[0].zip} ${data.addresses[0].location}`;
        } else {
          addr += `${data.addresses[0].zip} ${data.addresses[0].location}`;
        }

        address_street.value = data.addresses[0].street;
        address_zip.value = data.addresses[0].zip;
        address_location.value = data.addresses[0].location;

        if(data.addresses[0].state) {
          //addr += "\n"+data.addresses[0].state;
          address_state.value = data.addresses[0].state;
        } else {
          address_state.value = '';
        }
        if(data.addresses[0].country) {
          //addr += "\n"+countries.getName(data.addresses[0].country, this.locale);
          address_country.value = data.addresses[0].country;
        } else {
          address_country.value = '';
        }
      }
    }

    data_taxid.value = '';
    data_fiscal_code.value = '';
    data_unique_code.value = '';
    data_cem.value = '';
    if(data.taxid != '' && data.taxid != null) {
      addr += `\n\n${this.vat_label}: ${data.taxid}`;

      data_taxid.value = data.taxid;
    }
    if(data.data && data.data.fiscal_code && data.data.fiscal_code != '') {
      addr += `\n${I18n.t("contact.fiscal_code_abbr")}: ${data.data.fiscal_code}`;

      data_fiscal_code.value = data.data.fiscal_code;
    }
    if(data.data && data.data.unique_code && data.data.unique_code != '') {
      addr += `\n${I18n.t("contact.unique_code")}: ${data.data.unique_code}`;

      data_unique_code.value = data.data.unique_code;
    }

    if(data.emails.length > 0) {
      data.emails.forEach((email) => {
        if(email.email_type == 'cem') {
          addr += `\n${I18n.t("contact.cem")}: ${email.email_address}`;

          data_cem.value = email.email_address;
        }
      })
    }

    this.addressTarget.value = addr;
    this.addressTarget.dataset.groups = data.groups;

    if(this.chkboxInvoiceAddressTarget.checked) {
      this.setCustomerInvoiceAddress(data);
    } else {
      this.invoice_addressTarget.value = null;
    }

    // Set alternate addresses if present
    this.addAlternateAddresses(data);

  }

  setCustomerInvoiceAddress(data) {

    //Hidden fields
    let address_addressline1 = document.querySelector('#invoice_address_addressline1');
    let address_addressline2 = document.querySelector('#invoice_address_addressline2');
    let address_name = document.querySelector('#invoice_address_name');
    let address_street = document.querySelector('#invoice_address_street');
    let address_zip = document.querySelector('#invoice_address_zip');
    let address_location = document.querySelector('#invoice_address_location');
    let address_state = document.querySelector('#invoice_address_state');
    let address_country = document.querySelector('#invoice_address_country');

    let data_taxid = document.querySelector('#data_taxid');
    let data_fiscal_code = document.querySelector('#data_fiscal_code');
    let data_unique_code = document.querySelector('#data_unique_code');
    let data_cem = document.querySelector('#data_cem');

    let addr = '';
    if(data.company.trim() != '') {
      address_name.value = data.company;
    } else {
      if(data.firstname != '' || data.lastname != '') {
        address_name.value = (`${data.firstname} ${data.lastname}`).trim();
      }
    }

    if(data.addresses.length > 0) {
      let address = data.addresses.find(a => a.address_type == 'invoice_address');
      if(address) {

        //If addrelines are set, do not set the company name
        if((address.addressline1 && address.addressline1 != '') || (address.addressline2 && address.addressline2 != '')) {
          if(address.addressline1 && address.addressline1 != '') {
            addr += address.addressline1+"\n";
            address_addressline1.value = address.addressline1;
          } else {
            address_addressline1.value = '';
          }
          if(address.addressline2 && address.addressline2 != '') {
            addr += address.addressline2+"\n";
            address_addressline2.value = address.addressline2;
          } else {
            address_addressline2.value = '';
          }

          address_name.value = '';
        } else {
          addr += address_name.value+"\n";

          address_addressline1.value = '';
          address_addressline2.value = '';
        }

        addr += address.street+"\n";

        if(address.country && address.country != '') {
          addr += `${address.country}-${address.zip} ${address.location}`;
        } else {
          addr += `${address.zip} ${address.location}`;
        }

        address_street.value = address.street;
        address_zip.value = address.zip;
        address_location.value = address.location;

        if(address.state) {
          //addr += "\n"+address.state;
          address_state.value = address.state;
        }
        if(address.country) {
          //addr += "\n"+countries.getName(address.country, this.locale);
          address_country.value = address.country;
        }

        if(data_taxid.value != '') {
          addr += `\n\n${this.vat_label}: ${data_taxid.value}`;
        }
        if(data_fiscal_code.value != '') {
          addr += `\n${I18n.t("contact.fiscal_code_abbr")}: ${data_fiscal_code.value}`;
        }
        if(data_unique_code.value != '') {
          addr += `\n${I18n.t("contact.unique_code")}: ${data_unique_code.value}`;
        }
        if(data_cem.value != '') {
          addr += `\n${I18n.t("contact.cem")}: ${data_cem.value}`;
        }

        this.invoice_addressTarget.value = addr;
      } else {
        this.invoice_addressTarget.value = this.addressTarget.value;

        address_addressline1.value = document.querySelector('#address_addressline1').value;
        address_addressline2.value = document.querySelector('#address_addressline2').value;
        address_name.value = document.querySelector('#address_name').value;
        address_street.value = document.querySelector('#address_street').value;
        address_zip.value = document.querySelector('#address_zip').value;
        address_location.value = document.querySelector('#address_location').value;
        address_state.value = document.querySelector('#address_state').value;
        address_country.value = document.querySelector('#address_country').value;
      }
    }

  }

  addAlternateAddresses(data) {

    this.address_selectTarget.innerHTML = '';
    this.invoice_address_selectTarget.innerHTML = ''

    if(data.addresses.length > 1) {
      for(let i = 0; i < data.addresses.length; i++) {

        let str = [];

        //If addrelines are set, do not set the company name
        if((data.addresses[i].addressline1 && data.addresses[i].addressline1 != '') || (data.addresses[i].addressline2 && data.addresses[i].addressline2 != '')) {
          if(data.addresses[i].addressline1 && data.addresses[i].addressline1 != '') {
            str.push(data.addresses[i].addressline1);
          }
          if(data.addresses[i].addressline2 && data.addresses[i].addressline2 != '') {
            str.push(data.addresses[i].addressline2);
          }
        } else {
          if(data.company.trim() != '') {
            str.push(data.company);
          } else {
            if(data.firstname != '' || data.lastname != '') {
              str.push((`${data.firstname} ${data.lastname}`).trim());
            }
          }
        }

        str.push(data.addresses[i].street);
        str.push(data.addresses[i].zip);
        str.push(data.addresses[i].location);
        if(data.addresses[i].state) {
          str.push(data.addresses[i].state);
        }
        if(data.addresses[i].country) {
          str.push(countries.getName(data.addresses[i].country, this.locale));
        }

        let option = document.createElement("option");
        let option2 = document.createElement("option");
        option.text = str.join(', ');
        option.dataset.subtext = data.addresses[i].label;
        option2.text = str.join(', ');
        option2.dataset.subtext = data.addresses[i].label;
        this.setAddressDataFields(option, data, data.addresses[i]);
        this.setAddressDataFields(option2, data, data.addresses[i]);
        this.address_selectTarget.appendChild(option);
        this.invoice_address_selectTarget.appendChild(option2);
      }

      $(this.address_selectTarget).selectpicker('refresh');
      $(this.invoice_address_selectTarget).selectpicker('refresh');
      this.address_select_divTargets.forEach((div) => {
        div.classList.remove("d-none");
      })
    } else {
      this.address_select_divTargets.forEach((div) => {
        div.classList.add("d-none");
      })

      $(this.address_selectTarget).selectpicker('refresh');
      $(this.invoice_address_selectTarget).selectpicker('refresh');
    }
  }

  setAlternateCustomerAddress(data) {
    let address_addressline1 = document.querySelector('#address_addressline1');
    let address_addressline2 = document.querySelector('#address_addressline2');
    let address_name = document.querySelector('#address_name');
    let address_street = document.querySelector('#address_street');
    let address_zip = document.querySelector('#address_zip');
    let address_location = document.querySelector('#address_location');
    let address_state = document.querySelector('#address_state');
    let address_country = document.querySelector('#address_country');

    let data_taxid = document.querySelector('#data_taxid');
    let data_fiscal_code = document.querySelector('#data_fiscal_code');
    let data_unique_code = document.querySelector('#data_unique_code');
    let data_cem = document.querySelector('#data_cem');

    address_addressline1.value = '';
    address_addressline2.value = '';
    address_name.value = '';
    address_street.value = '';
    address_zip.value = '';
    address_location.value = '';
    address_state.value = '';
    address_country.value = '';

    let str = [];

    if(data.addressline1) {
      address_addressline1.value = data.addressline1;
      str.push(data.addressline1);
    }
    if(data.addressline2) {
      address_addressline2.value = data.addressline2;
      str.push(data.addressline2);
    }
    if(data.name) {
      address_name.value = data.name;
      str.push(data.name);
    }

    address_street.value = data.street;
    address_zip.value = data.zip;
    address_location.value = data.location;

    str.push(data.street);

    if(data.country_code && data.country_code != '') {
      str.push(`${data.country_code}-${data.zip} ${data.location}`.trim());
    } else {
      str.push(`${data.zip} ${data.location}`.trim());
    }

    if(data.state) {
      //str.push(data.state);
      address_state.value = data.state;
    }
    if(data.country) {
      //str.push(data.country);
      address_country.value = data.country;
    }

    str.push('');

    if(data_taxid.value != '') {
      str.push(`${this.vat_label}: ${data_taxid.value}`);
    }
    if(data_fiscal_code.value != '') {
      str.push(`${I18n.t("contact.fiscal_code_abbr")}: ${data_fiscal_code.value}`);
    }
    if(data_unique_code.value != '') {
      str.push(`${I18n.t("contact.unique_code")}: ${data_unique_code.value}`);
    }
    if(data_cem.value) {
      str.push(`${I18n.t("contact.cem")}: ${data_cem.value}`);
    }

    this.addressTarget.value = str.join('\n').trim();
  }

  setAlternateCustomerInvoiceAddress(data) {
    let address_addressline1 = document.querySelector('#invoice_address_addressline1');
    let address_addressline2 = document.querySelector('#invoice_address_addressline2');
    let address_name = document.querySelector('#invoice_address_name');
    let address_street = document.querySelector('#invoice_address_street');
    let address_zip = document.querySelector('#invoice_address_zip');
    let address_location = document.querySelector('#invoice_address_location');
    let address_state = document.querySelector('#invoice_address_state');
    let address_country = document.querySelector('#invoice_address_country');

    let data_taxid = document.querySelector('#data_taxid');
    let data_fiscal_code = document.querySelector('#data_fiscal_code');
    let data_unique_code = document.querySelector('#data_unique_code');
    let data_cem = document.querySelector('#data_cem');

    address_addressline1.value = '';
    address_addressline2.value = '';
    address_name.value = '';
    address_street.value = '';
    address_zip.value = '';
    address_location.value = '';
    address_state.value = '';
    address_country.value = '';

    let str = [];

    if(data.addressline1) {
      address_addressline1.value = data.addressline1;
      str.push(data.addressline1);
    }
    if(data.addressline2) {
      address_addressline2.value = data.addressline2;
      str.push(data.addressline2);
    }
    if(data.name) {
      address_name.value = data.name;
      str.push(data.name);
    }

    address_street.value = data.street;
    address_zip.value = data.zip;
    address_location.value = data.location;

    str.push(data.street);

    if(data.country_code && data.country_code != '') {
      str.push(`${data.country_code}-${data.zip} ${data.location}`.trim());
    } else {
      str.push(`${data.zip} ${data.location}`.trim());
    }

    if(data.state) {
      //str.push(data.state);
      address_state.value = data.state;
    }
    if(data.country) {
      //str.push(data.country);
      address_country.value = data.country;
    }

    str.push('');

    if(data_taxid.value != '') {
      str.push(`${this.vat_label}: ${data_taxid.value}`);
    }
    if(data_fiscal_code.value != '') {
      str.push(`${I18n.t("contact.fiscal_code_abbr")}: ${data_fiscal_code.value}`);
    }
    if(data_unique_code.value != '') {
      str.push(`${I18n.t("contact.unique_code")}: ${data_unique_code.value}`);
    }
    if(data_cem.value) {
      str.push(`${I18n.t("contact.cem")}: ${data_cem.value}`);
    }

    this.invoice_addressTarget.value = str.join('\n').trim();
  }

  setAddressDataFields(target, data, address) {

    if(address.addressline1) {
      target.dataset.addressline1 = address.addressline1;
    }
    if(address.addressline2) {
      target.dataset.addressline2 = address.addressline2;
    }

    if(data.company.trim() != '') {
      target.dataset.name = data.company;
    } else {
      if(data.firstname != '' || data.lastname != '') {
        target.dataset.name = (`${data.firstname} ${data.lastname}`).trim();
      }
    }

    target.dataset.street = address.street;
    target.dataset.zip = address.zip;
    target.dataset.location = address.location;
    target.dataset.state = address.state;
    if(address.country) {
      target.dataset.country = countries.getName(address.country, this.locale);
      target.dataset.country_code = address.country;
    }

    if(data.taxid != '' && data.taxid != null) {
      target.dataset.vat = `${this.vat_label}: ${data.taxid}`;
    }
    if(data.data && data.data.fiscal_code && data.data.fiscal_code != '') {
      target.dataset.fiscal_code = `${I18n.t("contact.fiscal_code_abbr")}: ${data.data.fiscal_code}`;
    }
    if(data.data && data.data.unique_code && data.data.unique_code != '') {
      target.dataset.unique_code = `${I18n.t("contact.unique_code")}: ${data.data.unique_code}`;
    }

    if(data.emails.length > 0) {
      data.emails.forEach((email) => {
        if(email.email_type == 'cem') {
          target.dataset.cem = `${I18n.t("contact.cem")}: ${email.email_address}`;
        }
      })
    }
  }

  addItem() {
    var table = $('#itemSelect').bootstrapTable({});

    this.itemSelectDialog.show();

    let dialog = document.querySelector('#itemSelectDialog');

    dialog.addEventListener('shown.bs.modal', function (event) {
      event.target.querySelector(".search-input").focus();
    })
  }

  addSelectedItems(items) {
    let that = this;
    let vats;
    let div_items = document.querySelector("#items");

    let currency = this.currency;
    if(this.hasCurrencyTarget) {
      currency = this.currencyTarget.value;
    }

    let order_locale = this.locale;
    if(this.hasLocaleTarget) {
      order_locale = this.localeTarget.value;
    }

    this.toggleWithVAT();

    let customer = this.customerTarget.value;
    let groups = this.addressTarget.dataset.groups;

    items.forEach((item, i) => {

      let card = document.createElement("div");

      let p = this.getCustomerPrice(item.prices, currency, customer, groups);

      let price = 0;
      let price_with_vat = 0;

      if(p) {
        price = p.price ? p.price : 0;
        price_with_vat = p.price_with_vat ? p.price_with_vat : 0;
      }

      let total = price;
      let total_with_vat = price_with_vat;

      let warehouse_select = '';

      if(item.with_stock && item.stocks) {
        for(let i = 0;i < item.stocks.length;i++) {
          let wh = that.warehouses.filter(wh => wh.uuid == item.stocks[i].warehouse);
          if(wh.length > 0) {
            item.stocks[i].name = wh[0].name;

            let stock_quantity = item.stocks[i].stock;
            let subtext = '';
            if(stock_quantity) {
              subtext = `${I18n.t("item.available")}:  ${stock_quantity}`
              if(item.reserved) {
                subtext += ` | ${I18n.t("item.reserved")}: ${item.reserved}`;
                stock_quantity = stock_quantity-item.reserved;
              }
            }

            if(wh[0].data && wh[0].data.color) {
              item.stocks[i].color = wh[0].data.color;
              item.stocks[i].subtext = subtext;
              item.stocks[i].quantity = stock_quantity;
            }

            warehouse_select += `<option value="${item.stocks[i].warehouse}" data-subtext="${subtext}" data-quantity="${stock_quantity}" style="color: ${item.stocks[i].color}">${item.stocks[i].name}</option>`;
          }
        }
      }

      that.vat_select = ''
      Object.keys(that.vat).forEach((country, i) => {
        that.vat_select += `<optgroup label="${I18n.t(`countries.${country}`)}">`;
        that.vat[country].forEach((vat, i) => {
          that.vat_select += `<option value="${vat.value.toFixed(1)}" ${vat.value == (item.vat && item.vat.toString()) ? "selected" : ''}>${vat.label}</option>`;
        });
        that.vat_select += "</optgroup>";
      });

      let details = [];
      if(item.color && item.color != '') {
        details.push(`${I18n.t("item.color")}: ${item.color}`);
      }

      if(item.size && item.size !='') {
        details.push(`${I18n.t("item.size")}: ${item.size}`);
      }
      let note = details.join(', ');
      if(item.note_text) {
        note += `\n${item.note_text}`
      }

      let pos_count = 0;
      let html_positions = ``
      if(this.advWarehouseMgmt) {
        if(item.with_stock) {
          if(item.stocks && item.stocks.length > 0) {
            html_positions += `<div class="d-flex flex-column smaller adv-stock-mgmt-positions">`
            item.stocks.forEach((stock, i) => {
              html_positions += `<div class="form-group mb-2"><label for="item_warehouse">${stock.name}</label><br>`

              if(stock.data && stock.data.positions && stock.data.positions.length > 0) {
                let positions = stock.data.positions.sort(function(a, b) {
                  return (a.expiration_date < b.expiration_date) ? -1 : ((a.expiration_date > b.expiration_date) ? 1 : 0);
                });

                html_positions += `
                  <table class="table table-sm w-auto">
                  <tr>
                    <th>${I18n.t('item.batch')}</th>
                    <th>${I18n.t('item.expiration_date')}</th>
                    <th>${I18n.t('item.available')}</th>
                    <th>${I18n.t('item.quantity')}</th>
                  </tr>
                `
                positions.forEach((position, i) => {
                  if(position.batch == '' || position.expiration_date == '') {
                    return
                  }

                  html_positions += `
                    <tr class="align-middle">
                      <td class="px-2">${position.batch}</td>
                      <td class="px-2">${position.expiration_date}</td>
                      <td class="px-2 text-end">${position.stock}</td>
                      <td>
                        <input type="hidden" id="position_item" name="adv_wh_mgmt_positions[][item]" value="${item.uuid}">
                        <input type="hidden" id="position_warehouse" name="adv_wh_mgmt_positions[][warehouse_uuid]" value="${stock.warehouse}">
                        <input type="hidden" id="position_warehouse" name="adv_wh_mgmt_positions[][warehouse_name]" value="${stock.name}">
                        <input type="hidden" id="position_uuid" name="adv_wh_mgmt_positions[][uuid]" value="${position.uuid}">
                        <input type="hidden" id="position_batch" name="adv_wh_mgmt_positions[][batch]" value="${position.batch}">
                        <input type="hidden" id="position_expiration_date" name="adv_wh_mgmt_positions[][expiration_date]" value="${position.expiration_date}">
                        <input type="number" id="position_quantity" name="adv_wh_mgmt_positions[][quantity]" steps="any" class="form-control form-control-sm text-end" style="width:150px;" data-stock="${position.stock}" min="0" max="${position.stock}">
                      </td>
                    </tr>
                  `

                  pos_count += 1;
                });
                html_positions += `</table>`
              }
              html_positions += `</div>`
            });
            html_positions += `</div>`
          }
        }

        if(pos_count == 0) {
          html_positions = '';
        }
      }

      card.classList.add("card", "mb-3");
      card.dataset.target = "orders.item";
      card.dataset.prices = item.prices;

      card.innerHTML = `
        <input id="item_item" name="items[][item]" type="hidden" value="${item.uuid}">
        <input id="item_item_number" name="items[][item_number]" type="hidden" value="${item.number}">
        <div class="d-flex justify-content-between bg-secondary bg-opacity-25">
          <div class="">
            <div class="handle ps-2 pt-2 float-start pointer-move-up-down">
              <i class="fas fa-grip-vertical fa-lg"></i>
            </div>
            <span class="badge bg-secondary ms-2 mt-2" data-orders-target="item_position">
            </span>
          </div>
          <div>
            <button type="button" class="btn btn-sm btn-outline-secondary me-1 mt-1 mb-1" data-bs-toggle="collapse" data-bs-target="#collapseNote-${item.uuid}" aria-expanded="false" aria-controls="collapseNote">
              <i class="far fa-edit"></i> ${I18n.t('item.note')}
            </button>
            <button type="button" class="btn btn-sm btn-outline-danger me-1 mt-1 mb-1" data-action="click->orders#deleteItem">
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
        <div class="card-body pt-1 pb-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="form-group mb-3 flex-fill me-2">
              <label for="order_uuid" data-label="${item.i18n_name}">
                ${item.i18n_name} ${(item.number && item.number != '') ? ` | ${item.number}` : ''}
              </label>
              <input id="item_label" name="items[][label]" class="form-control" type="text" value="${item.i18n_name}">
            </div>
            <a class="btn btn-outline-secondary float-end mt-2 ms-0" target="_blank" id="btn_item_open" href="/${this.locale}/item/${item.uuid}">
              <i class="fas fa-external-link-alt"></i>
            </a>
          </div>

          <div class="d-flex justify-content-between">
            <div class="form-group mb-3 me-3 w-auto" style="min-width: 100px;width:150px;">
              <label for="item_warehouse">${I18n.t('warehouse.warehouse')}</label><br>
              <select id="item_warehouse" name="items[][warehouse]" class="selectpicker warehouse form-control form-control-sm me-auto w-100" data-selected="${item.warehouse}">
                ${warehouse_select}
              </select>
            </div>
            <div class="form-group mb-3 me-3 ">
              <label for="item_quantity">
                ${I18n.t('order.item.quantity')}
              </label>
              <input id="item_quantity" name="items[][quantity]" class="form-control form-control-sm text-end" style="width: 100px;" step="0.01" data-action="input->orders#updatePrice" type="number" value="1">
            </div>
            <div class="form-group mb-3 me-3">
              <label for="item_price">${I18n.t('order.item.price')}</label>
              <div class="input-group input-group-sm">
                <button class="btn btn-outline-info multiple-prices ${item.prices.length <= 1 ? 'd-none' : ''}" type="button" data-container="body" data-toggle="popover" data-placement="top" data-html="true" data-trigger="click" data-action="click->orders#showPrices">
                  <i class="fas fa-tags"></i>
                </button>

                <input id="item_price" name="items[][price]" class="form-control form-control-sm text-end" style="width: 100px;" step="any" data-action="input->orders#updatePrice" type="number" value="${price.toFixed(2)}">
                <div class="input-group-text bg-white" data-orders-target="currency_abbrvs">
                  ${that.currenciesAbbr(currency)}
                </div>
              </div>
              <span id="customer_price" class="badge bg-primary d-none">
                ${I18n.t('item.customer_price')}
              </span>
            </div>
            <div class="form-group mb-3 me-3">
              <label for="item_discount">
                ${I18n.t('order.item.discount')}
              </label>
              <div class="input-group input-group-sm">
                <input id="item_discount" name="items[][discount]" class="form-control form-control-sm text-end" style="width: 80px;" step="any" data-action="input->orders#editDiscount" type="number" value="${p.discount ? p.discount.toFixed(2) : ''}">
                <select id="item_discount_type" name="items[][discount_type]" class="form-select form-control-sm pe-0" data-action="change->orders#updatePrice" style="width: 75px;">
                  <option value="fix" data-orders-target="currency_abbrvs" ${(p.discount && p.discount_type == 'fix') ? 'selected' : ''} >
                    ${that.currenciesAbbr(currency)}
                  </option>
                  <option value="percentage" ${(p.discount && p.discount_type == 'percentage') ? 'selected' : ''} >
                    %
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group mb-3 me-3 ${(p.discount && p.discount != '') ? "" : "d-none"}">
              <label for="item_price_with_discount">
                ${I18n.t('order.item.price_with_discount')}
              </label>
              <div class="input-group input-group-sm">
                <input id="item_price_with_discount" name="items[][price_with_discount]" class="form-control form-control-sm text-danger text-end" style="width: 100px;" step="any" data-action="input->orders#updatePrice" type="number" value="${p.price_with_discount ? p.price_with_discount.toFixed(2) : ''}">
                <div class="input-group-text bg-white" data-orders-target="currency_abbrvs">
                  ${that.currenciesAbbr(currency)}
                </div>
              </div>
            </div>
            <div class="form-group mb-3" style="width:85px;">
              <label for="item_vat">
                ${I18n.t('item.vat')}
              </label><br>
              <select id="item_vat" name="items[][vat]" class="vat form-select form-select-sm" data-orders-target="item_vat" data-action="change->orders#updatePrice">
                ${that.vat_select}
              </select>
            </div>
            <div class="flex-fill">
            </div>
            <div class="form-group mb-3 items-totals">
              <label for="item_total" class="semi-bold">
                ${I18n.t('item.total')}
              </label>
              <div class="input-group input-group-sm">
                <input id="item_total" name="items[][total]" class="form-control form-control-sm text-end" style="width: 100px;" step="any" data-orders-target="item_total" data-action="input->orders#calcUnitPrice" type="number" value="${total.toFixed(2)}">
                <div class="input-group-text bg-white" data-orders-target="currency_abbrvs">
                  ${that.currenciesAbbr(currency)}
                </div>
              </div>
            </div>
            <div class="form-group mb-3 items-totals-with-vat d-none">
              <label for="item_total_with_vat" class="semi-bold">
                ${I18n.t('item.total_with_vat')}
              </label>
              <div class="input-group input-group-sm">
                <input id="item_total_with_vat" name="items[][total_with_vat]" class="form-control form-control-sm text-end" style="width: 100px;" step="any" data-orders-target="item_total_with_vat" data-action="input->orders#calcPriceWithoutVAT" type="number" value="${total_with_vat.toFixed(2)}">
                <div class="input-group-text bg-white" data-orders-target="currency_abbrvs">
                  ${that.currenciesAbbr(currency)}
                </div>
              </div>
            </div>
          </div>
          ${html_positions}
          <div class="collapse ${note != '' ? "show" : ""}" id="collapseNote-${item.uuid}">
            <div class="form-group mt-auto mb-3">
              <label for="order_direction">
                ${I18n.t('item.note')}
              </label>
              <textarea id="item_note" name="items[][note]" class="form-control form-control-sm" rows="3">${note.trim()}</textarea>
            </div>
          </div>
        </div>
      `;

      div_items.appendChild(card);

      let select_wh = card.querySelector("select.warehouse");
      let select_vat = card.querySelector("select.vat");

      select_vat.value = item.vat ? item.vat.toFixed(1) : '0.0';

      //Check if fulfillment provider is set and set warehouse
      let sp_fulfillment = document.querySelector("#data_fulfillment");
      if(sp_fulfillment && sp_fulfillment.options.length > 1) {
        let option = sp_fulfillment.options[sp_fulfillment.selectedIndex];
        if(option && option.dataset.warehouse) {
          select_wh.value = option.dataset.warehouse;
        }
      }

      let sp_wh = $(select_wh).selectpicker('destroy').selectpicker({ width: 'auto' });
      sp_wh.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        let card = e.target.closest('.card');
        that.checkStockQuantity(card)
      });

      that.checkStockQuantity(card);
      that.updatePrice(card);
    });

    this.refreshItemNumbering();
  }

  deleteItem() {
    event.target.closest('.card').remove();
    this.updateTotal();
    $('.popover').popover('hide');
    this.refreshItemNumbering();
  }

  getItemPrice(uuid, card, currency) {
    let that = this;
    let price_category;
    if(this.hasPrice_categoryTarget && this.price_categoryTarget.value != '') {
      price_category = this.price_categoryTarget.value;
    } else {
      price_category = null;
    }

    let order_locale = this.locale;
    if(this.hasLocaleTarget) {
      order_locale = this.localeTarget.value;
      //I18n.locale = order_locale;
    }

    Rails.ajax({
      url: `/${this.locale}/item/${uuid}.json?currency=${currency}`,
      type: "get",
      data: "",
      success: function(data) {
        let selectpicker = card.querySelector("select.warehouse");
        selectpicker.innerHTML = '';

        if(data.with_stock) {
          for(let i = 0; i < data.stocks.length; i++) {
            let stock_quantity = data.stocks[i].stock;
            let subtext = '';
            if(stock_quantity) {
              subtext = `${I18n.t("item.available")}:  ${stock_quantity}`
              if(data.reserved) {
                subtext += ` | ${I18n.t("item.reserved")}: ${data.reserved}`;
                stock_quantity = stock_quantity-data.reserved;
              }
            }

            selectpicker.innerHTML += `<option value="${data.stocks[i].warehouse}" data-subtext="${subtext}" data-quantity="${stock_quantity}" style="color: ${data.stocks[i].color}">${data.stocks[i].warehouse_name}</option>`;
          }
        }

        let details = [];
        if(data.color) {
          details.push(`${I18n.t("item.color")}: ${data.color}`);
        }

        if(data.size) {
          details.push(`${I18n.t("item.size")}: ${data.size}`);
        }
        card.querySelector("#item_note").value = details.join(', ');

        //Check if fulfillment provider is set and set warehouse
        let sp_fulfillment = document.querySelector("#data_fulfillment");
        if(sp_fulfillment && sp_fulfillment.options.length > 1) {
          let option = sp_fulfillment.options[sp_fulfillment.selectedIndex];
          if(option && option.dataset.warehouse) {
            selectpicker.value = option.dataset.warehouse;
          }
        }

        $(selectpicker).selectpicker('refresh');

        let quantity = card.querySelector("#item_quantity");
        if(!quantity.value || quantity.value == '' || quantity.value == '0') {
          quantity.value = 1;
        }

        if(data.prices.length > 0) {
          let default_price;
          let group_price;
          let own_price;
          let price;

          for(let i = 0; i < data.prices.length; i++) {
            if(data.prices[i].active && (data.prices[i].default || !data.prices[i].contact) == true && data.prices[i].category == price_category) {
              if(default_price) {
                if(data.prices[i].currency == currency) {
                  default_price = data.prices[i];
                }
              } else {
                //default_price = data.prices[i];
              }
            }
          }

          let customer = document.querySelector("#order_customer");

          if(that.addressTarget.dataset.groups) {
            for(let i = 0; i < data.prices.length; i++) {
              if(data.prices[i].active && that.addressTarget.dataset.groups.includes(data.prices[i].contact) && data.prices[i].category == price_category) {
                if(data.prices[i].price) {
                  if(group_price) {
                    if(data.prices[i].currency == currency) {
                      group_price = data.prices[i];
                    }
                  } else {
                    group_price = data.prices[i];
                  }
                }
              }
            }
          }

          for(let i = 0; i < data.prices.length; i++) {
            if(data.prices[i].active && data.prices[i].contact == customer.value && data.prices[i].category == price_category) {
              if(data.prices[i].price) {
                if(own_price) {
                  if(data.prices[i].currency == currency) {
                    own_price = data.prices[i];
                  }
                } else {
                  own_price = data.prices[i];
                }
              }
            }
          }

          if(default_price) {
            price = default_price;
          }
          if(group_price) {
            price = group_price;
          }
          if(own_price) {
            price = own_price;
          }

          if(price) {
            if(price.currency != currency) {
              price.price = price.price_in_currency;
            }

            if(price.price) {
              card.querySelector("#item_price").value = price.price.toFixed(2);
            } else {
              card.querySelector("#item_price").value = '0.00';
            }
            if(price.vat) {
              card.querySelector("#item_vat").value = price.vat.toFixed(1);
            } else {
              card.querySelector("#item_vat").value = '0.0';
            }
            if(price.discount) {
              card.querySelector("#item_discount").value = price.discount.toFixed(2);
              card.querySelector("#item_discount_type").value = price.discount_type;

              card.querySelector("#item_price_with_discount").value = price.price_with_discount.toFixed(2);

              card.querySelector("#item_price_with_discount").parentElement.parentElement.classList.remove("d-none");
            }

            $(".selectpicker.vat").selectpicker("refresh");
          }

          if(data.prices.length > 1) {
            card.querySelector("#item_price").parentNode.querySelector(".multiple-prices").classList.remove("d-none");
          } else {
            card.querySelector("#item_price").parentNode.querySelector(".multiple-prices").classList.add("d-none");
          }

          if(card.querySelector("#item_price").value == '') {
            that.showPrices(card);
          } else {
            that.updatePrice(card);
          }

        }
      },
      error: function(data) {}
    });
  }

  getCustomerPrice(prices, currency, customer, groups = []) {
    let price_category;
    if(this.hasPrice_categoryTarget && this.price_categoryTarget.value != '') {
      price_category = this.price_categoryTarget.value;
    } else {
      price_category = null;
    }

    let order_locale = this.locale;
    if(this.hasLocaleTarget) {
      order_locale = this.localeTarget.value;
      //I18n.locale = order_locale;
    }

    if(prices.length > 0) {
      let default_price;
      let group_price;
      let own_price;
      let price;

      for(let i = 0; i < prices.length; i++) {
        if(prices[i].active && (prices[i].default || !prices[i].contact) == true && prices[i].category == price_category) {
          if(default_price) {
            if(prices[i].currency == currency) {
              default_price = prices[i];
            }
          } else {
            default_price = prices[i];
          }
        }
      }

      if(groups.length > 0) {
        for(let i = 0; i < prices.length; i++) {
          if(prices[i].active && groups.includes(prices[i].contact) && prices[i].category == price_category) {
            if(prices[i].price) {
              if(group_price) {
                if(prices[i].currency == currency) {
                  group_price = prices[i];
                }
              } else {
                group_price = prices[i];
              }
            }
          }
        }
      }

      for(let i = 0; i < prices.length; i++) {
        if(prices[i].active && prices[i].contact == customer && prices[i].category == price_category) {
          if(prices[i].price) {
            if(own_price) {
              if(prices[i].currency == currency) {
                own_price = prices[i];
              }
            } else {
              own_price = prices[i];
            }
          }
        }
      }

      if(default_price) {
        price = default_price;
      }
      if(group_price) {
        price = group_price;
      }
      if(own_price) {
        price = own_price;
      }
      return price;
    }
  }

  updateItemPrices(items, currency) {
    let that = this;
    items.forEach((card) => {
      let uuid = card.querySelector("#item_item").value;

      if(uuid != '') {
        this.getItemPrice(uuid, card, currency);
      }
    })

    this.currency_abbrvsTargets.forEach((lbl) => {
      lbl.textContent = that.currenciesAbbr(currency);
    });
  }

  enableItemSorting() {
    let that = this;
    new Sortable(items, {
        handle: '.handle',
        animation: 150,
        onEnd: () => {
          that.refreshItemNumbering();
        }
    });
  }

  editDiscount() {
    let card = event.target.closest('.card');
    if(event.target.value == '') {
      card.querySelector("#item_price_with_discount").parentElement.parentElement.classList.add("d-none");
      card.querySelector("#item_price_with_discount").value = "";
    } else {
      card.querySelector("#item_price_with_discount").parentElement.parentElement.classList.remove("d-none");
    }
    this.updatePrice();
  }

  updatePrice(card) {
    if(card instanceof Event || typeof card === 'undefined') {
      card = event.target.closest('.card');
    }
    let quantity = card.querySelector("#item_quantity");
    let price = card.querySelector("#item_price");
    let discount = card.querySelector("#item_discount");
    let discount_type = card.querySelector("#item_discount_type");
    let price_with_discount = card.querySelector("#item_price_with_discount");
    let vat = card.querySelector("#item_vat");
    let total = card.querySelector("#item_total");
    let total_with_vat = card.querySelector("#item_total_with_vat");

    this.checkStockQuantity(card)

    if(discount.value != '') {
      if(discount_type.value == "percentage") {
        price_with_discount.value = (price.value - (price.value * (discount.value/100))).toFixed(2);

        total.value = ((price.value - (price.value * (discount.value/100))) * quantity.value).toFixed(2);
        total_with_vat.value = ((price.value - (price.value * (discount.value/100))) * quantity.value * (1+(vat.value/100))).toFixed(2);
      } else {
        price_with_discount.value = (price.value - discount.value).toFixed(2);

        total.value = ((price.value - discount.value) * quantity.value).toFixed(2);
        total_with_vat.value = ((price.value - discount.value) * quantity.value * (1+(vat.value/100))).toFixed(2);
      }
    } else {
      total.value = (price.value * quantity.value).toFixed(2);
    }

    total_with_vat.value = (total.value * (1+(vat.value/100))).toFixed(2);

    this.updateTotal();

    if(card.querySelector(".adv-stock-mgmt-positions")) {
      this.advStockMgmtUpdatePositions(card);
    }
  }

  updateTotal() {
    let total = 0;
    let total_with_vat = 0;
    let total_vat = {};
    let total_vat_sum = {};
    let total_eqs = {};
    let total_eqs_sum = {};
    let total_retention = 0;
    let with_vat = false;

    this.vats.forEach((v, i) => {
      total_vat[v] = 0;
      total_vat_sum[v] = 0;
    });

    this.item_totalTargets.forEach((el, i) => {
      total += parseFloat(el.value);
      total_with_vat += parseFloat(el.value);
    })

    this.item_vatTargets.forEach((el, i) => {
      let vat_str = el.value.toString();
      let tot = this.item_totalTargets[i].value;
      total_vat_sum[vat_str] = total_vat_sum[vat_str] + parseFloat(tot);
    })

    this.totalTarget.textContent = total.toFixed(2);

    this.total_vat_sumTargets.forEach((el, i) => {
      var vat_position = document.querySelector("#vat_"+el.dataset.vat.replace('.', '-'));
      if(total_vat_sum[el.dataset.vat].toFixed(2) > 0) {
        vat_position.classList.remove('d-none');
        with_vat = true;
      } else {
        vat_position.classList.add('d-none');
      }
      el.textContent = total_vat_sum[el.dataset.vat].toFixed(2);
      document.querySelector("#total_vat_sum_"+el.dataset.vat.replace('.', '-')).value = el.textContent;

      total_vat[el.dataset.vat] = total_vat_sum[el.dataset.vat] * (el.dataset.vat/100);
      total_with_vat += total_vat[el.dataset.vat];
    })

    if(with_vat) {
      this.vats_summaryTarget.classList.add("border-bottom", "border-secondary");
    } else {
      this.vats_summaryTarget.classList.remove("border-bottom", "border-secondary");
    }

    this.total_vatTargets.forEach((el, i) => {
      el.textContent = total_vat[el.dataset.vat].toFixed(2);
      document.querySelector("#total_vat_"+el.dataset.vat.replace('.', '-')).value = el.textContent;
    })

    if(this.equivalence_surchargeTarget.checked) {
      this.eqs.forEach((v, i) => {
        let values = v.split(',');
        total_eqs_sum[values[1]] = total_vat_sum[values[0]];
        total_eqs[values[1]] = total_vat_sum[values[0]]*(values[1]/100);

        total_with_vat += total_eqs[values[1]];
      });

      this.total_eqs_sumTargets.forEach((el, i) => {
        el.textContent = total_eqs_sum[el.dataset.eqs].toFixed(2);
        document.querySelector("#total_vat_sum_"+el.dataset.eqs.replace('.', '-')).value = el.textContent;
      })

      this.total_eqsTargets.forEach((el, i) => {
        el.textContent = total_eqs[el.dataset.eqs].toFixed(2);
        document.querySelector("#total_vat_"+el.dataset.eqs.replace('.', '-')).value = el.textContent;
      })
    }

    let retention = parseFloat(this.retentionTarget.dataset.retention);
    total_retention = total.toFixed(2)*(retention/100);
    if(this.retentionTarget.checked) {
      this.total_retentionTarget.textContent = (0-total_retention).toFixed(2);
      total_with_vat = (total_with_vat - total_retention);
    } else {
      this.total_retentionTarget.textContent = 0;
    }

    if(this.transportTarget.value != '') {
      document.querySelector("#total_transport").classList.remove("d-none");
      this.total_transportTarget.innerText = parseFloat(this.transportTarget.value).toFixed(2);

      total_with_vat = total_with_vat + parseFloat(this.transportTarget.value);
    } else {
      document.querySelector("#total_transport").classList.add("d-none");
    }

    if(this.hasDelivery_costsTarget) {
      this.delivery_costsTargets.forEach((el, i) => {
        total_with_vat += parseFloat(el.dataset.amount);
      });
    }

    this.total_with_vatTarget.textContent = total_with_vat.toFixed(2);

    //Set form values
    document.querySelector("#order_total").value = total.toFixed(2);
    document.querySelector("#order_total_with_vat").value = total_with_vat.toFixed(2);
    document.querySelector("#order_total_retention").value = total_retention.toFixed(2);
  }

  toggleRetention() {
    setTimeout(() => {
      if(this.retentionTarget.checked) {
        document.querySelector("#total_retention").classList.remove('d-none');
      } else {
        document.querySelector("#total_retention").classList.add('d-none');
      }

      this.updateTotal();
    }, 100);
  }

  toggleWithVAT() {
    setTimeout(() => {
      let tot = document.querySelectorAll(".items-totals");
      let tot_w_vat = document.querySelectorAll(".items-totals-with-vat");
      if(document.querySelector("#swchWithVAT").checked) {
        for(let i = 0; i < tot.length; i++) {
          tot[i].classList.add('d-none');
          tot_w_vat[i].classList.remove('d-none');
        }
      } else {
        for(let i = 0; i < tot.length; i++) {
          tot_w_vat[i].classList.add('d-none');
          tot[i].classList.remove('d-none');
        }
      }
    }, 100);
  }

  toggleEquivalenceSurcharge() {
    setTimeout(() => {
      let eqs = document.querySelectorAll(".equivalence_surcharge");
      if(this.equivalence_surchargeTarget.checked) {
        for(let i = 0; i < eqs.length; i++) {
          eqs[i].classList.remove("d-none");
          eqs[i].classList.add("d-flex");
        }
      } else {
        for(let i = 0; i < eqs.length; i++) {
          eqs[i].classList.remove("d-flex");
          eqs[i].classList.add("d-none");
        }
      }

      this.updateTotal();
    }, 100);
  }

  checkStockQuantity(card) {
    let quantity = card.querySelector('#item_quantity');
    let warehouses = card.querySelector("#item_warehouse");
    let warehouse = warehouses.options[$(warehouses).prop('selectedIndex')];

    if(warehouse) {
      let btn = card.querySelector("button[data-id='item_warehouse']");

      if(warehouse.style.color) {
        btn.style.color = warehouse.style.color;
      } else {
        btn.style.removeProperty('color');
      }

      if(quantity.value > parseInt(warehouse.dataset.quantity)) {
        quantity.classList.add('is-invalid');
      } else {
        quantity.classList.remove('is-invalid');
      }
    }
  }

  deleteConfirm() {
    let that = this
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead semi-bold">${event.target.dataset.orderref}</span><br>${event.target.dataset.customer}`;
    bootbox.dialog({
      message: `${I18n.t('order.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/order/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  invoiceConfirm() {
    var uuid = event.target.dataset.uuid;
    var msg = `
      ${I18n.t('order.confirm.invoice')}<br><br><span class="lead"><b>${I18n.t('order.order')}:</b>&nbsp;${event.target.dataset.orderref}</span><br><br>
    `;

    Rails.ajax({
      url: `/${this.locale}/subscription/config/invoice_numbering`,
      type: "get",
      data: "",
      success: function(data) {
        var numb = []
        var count = 1;
        data.forEach( (n) => {
          if(n.enabled == true) {
            let text = n.reference;
            if(n.note && n.note != '') {
              text += ` - ${n.note}`
            }
            numb.push({text: text, value: count})
          }
          count += 1;
        })

        bootbox.prompt({
          title: I18n.t('document.invoice'),
          message: msg,
          onEscape: true,
          inputType: 'radio',
          inputOptions: numb,
          callback: function (result) {
            if(result) {
              window.open(`/${that.locale}/order/${uuid}/invoice/${result}`, '_blank');
              setTimeout(() => {
                location.reload();
                bootbox.hideAll();
              }, 500);
            }
          }
        });
      },
      error: function(data) {}
    });
  }

  reloadPage() {
    setTimeout(() => {
      location.reload();
    }, 500);
  }

  resetOrderDate() {
    var e = event;
    setTimeout(() => {
      if(e.target.checked) {
        $("#datetimepicker1").datetimepicker('date', moment(e.target.dataset.date) );
      } else {
        $("#datetimepicker1").datetimepicker('date', moment() );
      }
    }, 100);
  }

  toggleStateNotification() {
    let that = this;
    setTimeout(() => {
      if(that.state_notificationTarget.checked) {
        $('#order_state_notification_addresses').attr('disabled',false)
        $('#order_state_notification_addresses').selectpicker('refresh');
      } else {
        $('#order_state_notification_addresses').attr('disabled',true);
        $('#order_state_notification_addresses').selectpicker('refresh');
      }

      if(that.state_notificationTarget.checked && that.stateTarget.value != that.stateTarget.dataset.state) {
        document.querySelector('#infoStateNotification').style.display = 'block';
      } else {
        document.querySelector('#infoStateNotification').style.display = '';
      }
    }, 100);
  }

  toggleInvoiceAddress() {
    let that = this;
    let uuid = this.customerTarget.value;

    setTimeout(() => {
      if(that.chkboxInvoiceAddressTarget.checked) {
        if(that.invoice_addressTarget.value == '') {

          //Get contact details
          Rails.ajax({
            url: `/${this.locale}/contact/${uuid}.json`,
            type: "get",
            data: "",
            success: function(data) {
              that.setCustomerInvoiceAddress(data);
            },
            error: function(data) {}
          });

        }
        that.invoice_address_divTarget.classList.remove("d-none");
      } else {
        that.invoice_address_divTarget.classList.add("d-none");

        document.querySelector('#invoice_address_addressline1').value = '';
        document.querySelector('#invoice_address_addressline2').value = '';
        document.querySelector('#invoice_address_name').value = '';
        document.querySelector('#invoice_address_street').value = '';
        document.querySelector('#invoice_address_zip').value = '';
        document.querySelector('#invoice_address_location').value = '';
        document.querySelector('#invoice_address_state').value = '';
        document.querySelector('#invoice_address_country').value = '';
      }
    }, 100);
  }

  showPrices(card) {
    let that = this;
    if(event.target.type == 'button') {
      card = event.target.closest(".card");
    }
    let uuid = card.querySelector("#item_item").value;
    let label = card.querySelector("#item_label").value;

    let sender = event.target;

    let currency = this.currency;
    if(this.hasCurrencyTarget) {
      currency = this.currencyTarget.value;
    }

    Rails.ajax({
      url: `/${this.locale}/item/${uuid}.json?currency=${currency}`,
      type: "get",
      data: "",
      success: function(data) {

        let content = '<table class="table table-sm table-hover mt-3 mb-3 smaller">';
        let prices = data.prices.filter(function (el) {
          return (el.default || !el.contact);
        })

        for(let i = 0; i < prices.length; i++) {
          if(prices[i].price) {
            if(prices[i].currency == currency) {
              content += `<tr class="pointer-hand" data-price="${prices[i].price.toFixed(2)}" data-vat="${prices[i].vat.toFixed(1)}">`;
              content += "<td>"
              if(!prices[i].active) {
                content += '<i class="fa-solid fa-ban text-danger me-2"></i>'
              }
              content += `<b>${prices[i].label}</b></td>`;
              content += `<td class="text-nowrap">${that.currenciesFormat(prices[i].price.toFixed(2), currency)}</td>`;
              content += `<td>${that.currenciesFormat(prices[i].price_with_vat.toFixed(2), currency)}</td><td></td>`;
            } else {
              content += `<tr class="pointer-hand" data-price="${prices[i].price_in_currency.toFixed(2)}" data-vat="${prices[i].vat.toFixed(1)}">`;
              content += `<td><b>${prices[i].label}</b></td>`;
              content += `<td class="text-nowrap">${that.currenciesFormat(prices[i].price_in_currency.toFixed(2), currency)}<br><span class="small text-muted">${that.currenciesFormat(prices[i].price.toFixed(2), prices[i].currency)}</span></td>`;
              let price_with_vat = prices[i].price_in_currency*(1+(prices[i].vat/100));
              content += `<td>${that.currenciesFormat(price_with_vat.toFixed(2), currency)}<br><span class="small text-muted">${that.currenciesFormat(prices[i].price_with_vat.toFixed(2), prices[i].currency)}</span></td>`;
              content += `<td class="small text-secondary pt-2">${that.currenciesFormat(1, prices[i].currency)} = ${that.currenciesFormat(prices[i].exchange_rate.toFixed(3), currency)}</td>`
            }

            content += `<td class="text-nowrap">${prices[i].vat.toFixed(1)} %</td>`;
            content += `<td class="text-nowrap">`;
            if(prices[i].category_name) {
              content += prices[i].category_name;
            }
            content += '</td><td class="small text-secondary">'
            if(prices[i].valid_from) {
              content += `${I18n.t('item.price_from')} ${I18n.l('date.formats.price_validity', prices[i].valid_from)} `
            }
            if(prices[i].valid_until) {
              content += `${I18n.t('item.price_until')} ${I18n.l('date.formats.price_validity', prices[i].valid_until)}`
            }
            content += '</td></tr>';
          }
        }

        if(that.addressTarget.dataset.groups) {
          let prices = data.prices.filter(function (el) {
            return !el.default && that.addressTarget.dataset.groups.includes(el.contact)
          })

          for(let i = 0; i < prices.length; i++) {
            if(prices[i].price) {
              if(prices[i].currency == currency) {
                content += `<tr class="pointer-hand text-primary" data-price="${prices[i].price.toFixed(2)}" data-vat="${prices[i].vat.toFixed(1)}">`;
                content += `<td><b>${prices[i].label}</b></td>`;
                content += `<td class="text-nowrap">${that.currenciesFormat(prices[i].price.toFixed(2), currency)}</td>`;
                content += `<td>${that.currenciesFormat(prices[i].price_with_vat.toFixed(2), currency)}</td><td></td>`;
              } else {
                content += `<tr class="pointer-hand" data-price="${prices[i].price_in_currency.toFixed(2)}" data-vat="${prices[i].vat.toFixed(1)}">`;
                content += `<td><b>${prices[i].label}</b></td>`;
                content += `<td class="text-nowrap">${that.currenciesFormat(prices[i].price_in_currency.toFixed(2), currency)}<br><span class="small text-muted">${that.currenciesFormat(prices[i].price.toFixed(2), prices[i].currency)}</span></td>`;
                let price_with_vat = prices[i].price_in_currency*(1+(prices[i].vat/100));
                content += `<td>${that.currenciesFormat(price_with_vat.toFixed(2), currency)}<br><span class="small text-muted">${that.currenciesFormat(prices[i].price_with_vat.toFixed(2), prices[i].currency)}</span></td>`;
                content += `<td class="small text-secondary pt-2">${that.currenciesFormat(1, prices[i].currency)} = ${that.currenciesFormat(prices[i].exchange_rate.toFixed(3), currency)}</td>`
              }

              content += `<td class="text-nowrap">${prices[i].vat.toFixed(1)} %</td>`;
              content += `<td class="text-nowrap">`;
              if(prices[i].category_name) {
                content += prices[i].category_name;
              }
              content += '</td><td class="small text-secondary">'
              if(prices[i].valid_from) {
                content += `${I18n.t('item.price_from')} ${I18n.l('date.formats.price_validity', prices[i].valid_from)} `
              }
              if(prices[i].valid_until) {
                content += `${I18n.t('item.price_until')} ${I18n.l('date.formats.price_validity', prices[i].valid_until)}`
              }
              content += '</td></tr>';
            }
          }
        }

        if(that.customerTarget.value) {
          let prices = data.prices.filter(function (el) {
            return !el.default && el.contact == that.customerTarget.value
          })

          for(let i = 0; i < prices.length; i++) {
            if(prices[i].price) {
              if(prices[i].currency == currency) {
                content += `<tr class="pointer-hand text-success" data-price="${prices[i].price.toFixed(2)}" data-vat="${prices[i].vat.toFixed(1)}">`
                content += `<td><b>${prices[i].label}</b></td>`;
                content += `<td class="text-nowrap">${that.currenciesFormat(prices[i].price.toFixed(2), currency)}</td>`;
                content += `<td>${that.currenciesFormat(prices[i].price_with_vat.toFixed(2), currency)}</td><td></td>`;
              } else {
                content += `<tr class="pointer-hand text-success" data-price="${prices[i].price_in_currency.toFixed(2)}" data-vat="${prices[i].vat.toFixed(1)}">`
                content += `<td><b>${prices[i].label}</b></td>`;
                content += `<td class="text-nowrap">${that.currenciesFormat(prices[i].price_in_currency.toFixed(2), currency)}<br><span class="small text-muted">${that.currenciesFormat(prices[i].price.toFixed(2), prices[i].currency)}</span></td>`;
                let price_with_vat = prices[i].price_in_currency*(1+(prices[i].vat/100));
                content += `<td>${that.currenciesFormat(price_with_vat.toFixed(2), currency)}<br><span class="small text-muted">${that.currenciesFormat(prices[i].price_with_vat.toFixed(2), prices[i].currency)}</span></td>`;
                content += `<td class="small text-secondary pt-2">${that.currenciesFormat(1, prices[i].currency)} = ${that.currenciesFormat(prices[i].exchange_rate.toFixed(3), currency)}</td>`
              }

              content += `<td class="text-nowrap">${prices[i].vat.toFixed(1)} %</td>`;
              content += `<td class="text-nowrap">`;
              if(prices[i].category_name) {
                content += prices[i].category_name;
              }
              content += '</td><td class="small text-secondary">'
              if(prices[i].valid_from) {
                content += `${I18n.t('item.price_from')} ${I18n.l('date.formats.price_validity', prices[i].valid_from)} `
              }
              if(prices[i].valid_until) {
                content += `${I18n.t('item.price_until')} ${I18n.l('date.formats.price_validity', prices[i].valid_until)}`
              }
              content += '</td></tr>';
            }
          }
        }

        content += '</table>';

        var box = bootbox.dialog({
          message: content,
          onEscape: true,
          size: 'large',
          title: label,
          buttons: {
            close: {
              label: I18n.t("btn.close"),
              className: "btn-outline-secondary",
            }
          }
        });

        box.init(function(){
          $('tr').on('click', (e) => {
            let tr = e.target
            if(e.target.nodeName != 'TR') {
              tr = event.target.closest('tr');
            }
            box.modal('hide');

            if(tr.dataset.price != '') {
              card.querySelector("#item_price").value = parseFloat(tr.dataset.price).toFixed(2);
            } else {
              card.querySelector("#item_price").value = '0.00';
            }
            if(tr.dataset.vat != '') {
              card.querySelector("#item_vat").value = parseFloat(tr.dataset.vat).toFixed(1);
            } else {
              card.querySelector("#item_vat").value = '0.0';
            }
            that.updatePrice(card);
          })
        });
      }
    });
  }

  editAddress() {
    let address_addressline1;
    let address_addressline2;
    let address_name;
    let address_street;
    let address_zip;
    let address_location;
    let address_state;
    let address_country;

    //Hidden fields
    if(event.target.id == 'order_address') {
      address_addressline1 = document.querySelector('#address_addressline1');
      address_addressline2 = document.querySelector('#address_addressline2');
      address_name = document.querySelector('#address_name');
      address_street = document.querySelector('#address_street');
      address_zip = document.querySelector('#address_zip');
      address_location = document.querySelector('#address_location');
      address_state = document.querySelector('#address_state');
      address_country = document.querySelector('#address_country');
    } else {
      address_addressline1 = document.querySelector('#invoice_address_addressline1');
      address_addressline2 = document.querySelector('#invoice_address_addressline2');
      address_name = document.querySelector('#invoice_address_name');
      address_street = document.querySelector('#invoice_address_street');
      address_zip = document.querySelector('#invoice_address_zip');
      address_location = document.querySelector('#invoice_address_location');
      address_state = document.querySelector('#invoice_address_state');
      address_country = document.querySelector('#invoice_address_country');
    }

    let data_taxid = document.querySelector('#data_taxid');
    let data_fiscal_code = document.querySelector('#data_fiscal_code');
    let data_unique_code = document.querySelector('#data_unique_code');
    let data_cem = document.querySelector('#data_cem');

    let str = `target=${event.target.id}`;
    if(address_addressline1.value != '') {
      str += `&addressline1=${address_addressline1.value}`
    }
    if(address_addressline2.value != '') {
      str += `&addressline2=${address_addressline2.value}`
    }
    if(address_name.value != '') {
      str += `&name=${address_name.value}`
    }
    str += `&street=${address_street.value}`
    str += `&zip=${address_zip.value}`
    str += `&city=${address_location.value}`
    str += `&state=${address_state.value}`
    str += `&country=${address_country.value}`

    if(data_taxid.value != '') {
      str += `&vat=${data_taxid.value}`
    }
    if(data_fiscal_code.value != '') {
      str += `&fiscal_code=${data_fiscal_code.value}`
    }
    if(data_unique_code.value != '') {
      str += `&unique_code=${data_unique_code.value}`
    }
    if(data_cem.value != '') {
      str += `&cem=${data_cem.value}`
    }

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/order/0/address/edit`,
      type: "get",
      data: str,
      success: function(data) {},
      error: function(data) {}
    });
  }

  fulfillmentSetWarehouses(event) {
    let that = this;
    let option = this.fulfillmentTarget.options[event.target.selectedIndex];
    if(option.dataset.warehouse) {
      document.querySelectorAll("div.card").forEach((card) => {
        let sp_wh = card.querySelector("#item_warehouse");
        if(sp_wh.options.length > 0) {
          sp_wh.value = option.dataset.warehouse;
          $(sp_wh).selectpicker('val', option.dataset.warehouse);
        }
        that.checkStockQuantity(card);
      })
    }
  }

  importOrders() {
    let that  = this;
    switch(event.target.dataset.plugin) {
      case "jimdo":
        let input = document.createElement('input');
        input.type = "file";
        input.accept = ".xml";

        input.addEventListener("change", () => {
          $('#loading').fadeIn(200);

          var formData = new FormData();
          formData.append("plugin", "jimdo");
          formData.append("xmlfile", event.target.files[0]);

          Rails.ajax({
            url: `/${that.locale}/orders/import`,
            type: "POST",
            data: formData,
            contentType: false,
            processData: false,
            method: 'POST',
            success: function(data) {},
            error: function(data) {}
          });

          input.remove();
        });

        document.querySelector('body').appendChild(input);
        input.click();
        break;
      default:
        break;
    }
  }

  calcUnitPrice() {
    let row = event.target.closest('div.card');

    let inp_tot = row.querySelector("#item_total");
    let inp_pwov = row.querySelector("#item_price");
    let inp_disc = row.querySelector("#item_discount");
    let inp_pwdisc = row.querySelector("#item_price_with_discount");
    let inp_vat = row.querySelector("#item_vat");
    let inp_pvw = row.querySelector("#item_total_with_vat");
    let inp_quantity = row.querySelector("#item_quantity");

    let price = inp_tot.value / inp_quantity.value;
    let vat = inp_vat.options[inp_vat.selectedIndex].value;

    if(inp_disc.value != '') {
      inp_pwdisc.value = price.toFixed(2);

      let discount_type = row.querySelector("#item_discount_type");

      if(discount_type.value == "percentage") {
        price = (price*100)/(100-inp_disc.value)
      } else {
        price = price + parseFloat(inp_disc.value);
      }

      inp_pwov.value = price.toFixed(2);
    } else {
      inp_pwov.value = price.toFixed(2);
    }

    let price_with_vat = inp_tot.value*(1+(vat/100));
    inp_pvw.value = price_with_vat.toFixed(2);
    this.updateTotal();
  }

  calcPriceWithoutVAT() {
    let row = event.target.closest('div.card');

    let inp_pwov = row.querySelector("#item_price");
    let inp_disc = row.querySelector("#item_discount");
    let inp_pwdisc = row.querySelector("#item_price_with_discount");
    let inp_vat = row.querySelector("#item_vat");
    let inp_tot = row.querySelector("#item_total");
    let inp_pvw = row.querySelector("#item_total_with_vat");
    let inp_quantity = row.querySelector("#item_quantity");
    let quantity = inp_quantity.value;
    let price_with_vat = inp_pvw.value/quantity;
    let vat = inp_vat.options[inp_vat.selectedIndex].value;
    let price = price_with_vat/(1+(vat/100));

    if(inp_disc.value != '') {
      inp_pwdisc.value = price.toFixed(4);

      let discount_type = row.querySelector("#item_discount_type");

      if(discount_type.value == "percentage") {
        price = (price*100)/(100-inp_disc.value)
      } else {
        price = price + parseFloat(inp_disc.value);
      }

      inp_pwov.value = price.toFixed(4);
      inp_tot.value = (inp_pwov.value*quantity).toFixed(4);
    } else {
      inp_pwov.value = price.toFixed(4);
      inp_tot.value = (inp_pwov.value*quantity).toFixed(4);
    }

    this.updateTotal();
  }

  newOrderFromExisting() {
    let that = this;
    let items = document.querySelectorAll(".card");
    items.forEach( (card) => {
      let item_select = card.querySelector("#item_item");
      let uuid = item_select.value;
      card.querySelector("#btn_item_open").classList.remove("d-none");
      card.querySelector("#btn_item_open").href = card.querySelector("#btn_item_open").href+uuid;

      var sp_wh = $(".selectpicker.warehouse").selectpicker({ width: 'auto' });
      sp_wh.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        let card = e.target.closest('.card');
        that.checkStockQuantity(card)
      });

      var sp_vat = $(".selectpicker.vat").selectpicker({ width: '80px' });
      sp_vat.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.updatePrice();
      });
    })

    if(this.hasCurrencyTarget) {
      $(this.currencyTarget).selectpicker({ width: '250px'});
      //this.updateItemPrices(items, this.currencyTarget.value);
    }

    this.refreshItemNumbering();
    this.updateTotal();
  }

  updatePaymentConditions() {
    if(this.payment_conditionsTarget.value.match(/\D/)) {
      this.payment_conditionsTarget.parentNode.querySelector('.input-group-text').classList.add('d-none');
      this.payment_conditionsTarget.classList.add('rounded-end');
    } else {
      this.payment_conditionsTarget.parentNode.querySelector('.input-group-text').classList.remove('d-none');
      this.payment_conditionsTarget.classList.remove('rounded-end');
    }
  }

  refreshItemNumbering() {
    this.item_positionTargets.forEach((lbl, i) => {
      lbl.innerText = i+1;
    });
  }

  shippingConfirm() {
    let that = this
    var uuid = event.target.dataset.uuid;
    let state = event.target.dataset.state;
    let stateDescription = event.target.dataset.stateDescription;
    var msg = `<span class="lead">${I18n.t('order.order')}: ${event.target.dataset.orderref}</span><br><br>${I18n.t('order.confirm.set_shipped_and_remove_for_warehouse', {state: stateDescription})}`;
    let next = '';
    if(state != 'shipped') {
      next = `/${state}`
    }
    bootbox.dialog({
      message: `${event.target.dataset.stateDescription}<br><br>${msg}`,
      onEscape: true,
      size: 'large',
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        ignore: {
          label: I18n.t('order.confirm.dont_change_warehouse_quantities'),
          className: "btn-outline-success",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/order/${uuid}/state/${state}`,
              type: "post",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        },
        confirm: {
          label: I18n.t('btn.apply'),
          className: "btn-success",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/order/${uuid}/shipped${next}`,
              type: "post",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  stateConfirm() {
    let that = this
    var uuid = event.target.dataset.uuid;
    var state = event.target.dataset.state;
    var stateDescription = event.target.dataset.stateDescription;
    var msg = `<span class="lead">${I18n.t('order.order')}: ${event.target.dataset.orderref}<br>${I18n.t('order.state')}: <span class="semi-bold">${event.target.dataset.stateDescription}</span></span>`;
    if(['new', 'progress', 'ready_shipment'].includes(state)) {
      msg += `<br><br><i class="fas fa-exclamation-circle"></i> ${I18n.t('order.confirm.reset_status_warehouse')}`
    }
    bootbox.dialog({
      message: `${I18n.t('order.confirm.reset_status')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        confirm: {
          label: I18n.t('btn.apply'),
          className: "btn-outline-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/order/${uuid}/state/${state}`,
              type: "post",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  cancelConfirm() {
    let that = this
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead semi-bold">${event.target.dataset.orderref}</span><br>${event.target.dataset.customer}`;
    bootbox.dialog({
      message: `${I18n.t('order.confirm.cancel')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.cancel_order'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/order/${uuid}/state/cancelled`,
              type: "post",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  selectAttachment() {
    var input = document.querySelector("#filepicker");
    input.click();
  }

  uploadAttachment() {
    let that = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;
    var files = input.files.length;

    let counter = parseInt(this.attachments_counterTarget.dataset.counter);

    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      that.attachment_uploadingTarget.classList.remove('d-none');

      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/order/attachment/add/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              counter += 1;
              that.attachments_counterTarget.innerText = counter;
              that.attachments_counterTarget.dataset.counter = counter;
              that.attachment_uploadingTarget.classList.add('d-none');
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

  deleteAttachment() {
    var attachment = event.target.closest("div.d-flex");
    attachment.remove();
    this.attachments_counterTarget.dataset.counter = parseInt(this.attachments_counterTarget.dataset.counter - 1)
    this.attachments_counterTarget.innerText = this.attachments_counterTarget.dataset.counter;
  }

  switchTab() {
    let url;
    if(event.target.dataset.path) {
      url = location.origin+location.pathname+`?tab=${event.target.dataset.path}`;
    } else {
      url = location.origin+location.pathname;
    }
    window.history.replaceState({}, document.title, url);
  }

  checkExtReference() {
    let ref = event.target.value.trim();
    let link = '';

    if(ref.match(/9[7-9]\.\d{2}.\d{6}.\d{8}/)) {
      // Swiss Post
      link = `https://www.post.ch/swisspost-tracking?formattedParcelCodes=${ref}`

      let modal = document.querySelector(".bootbox.modal");
      let inp_disp = modal.querySelector("#delivery_dispatch_notification");
      if(inp_disp && inp_disp.disabled == false) {
        inp_disp.checked = true
      }

    } else if(ref.match(/^[A-Z]{2}\d{9}ES$/)) {
      // Correos Spain
      link = `https://www.correos.es/es/en/tools/tracker/items/details?tracking-number=${ref}`
    } else {
      link = '';
    }
    this.ext_linkTarget.value = link;
    if(this.ext_stateTarget.value == 'new') {
      $(this.ext_stateTarget).selectpicker('val', 'sent');
    }
  }

  changeDeliveryState() {
    let inp = document.querySelector("#delivery_dispatch_notification")
    if(inp) {
        if(event.target.value == 'sent' || event.target.value == 'delivered') {
          if(!inp.disabled) {
            inp.checked = true;
            let ext = document.querySelector("#ext-reference");
            if(ext) {
              ext.classList.remove('d-none');
            }
          }
        } else {
          inp.checked = false;
        }
    }

    if(event.target.value == 'sent' || event.target.value == 'delivered') {
      if(event.target.dataset.current == 'new') {
        this.delivery_warehouse_remove_alertTarget.classList.remove('d-none');
      } else {
        this.delivery_warehouse_add_alertTarget.classList.add('d-none');
      }
    } else {
      if(event.target.dataset.current == 'new') {
        this.delivery_warehouse_remove_alertTarget.classList.add('d-none');
      } else {
        this.delivery_warehouse_add_alertTarget.classList.remove('d-none');
      }
    }
  }

  confirmDeleteDelivery() {
    let that = this;
    let tr = event.target.closest('tr');
    let items = tr.querySelector('table');

    let uuid = event.target.dataset.uuid;
    let delivery = event.target.dataset.delivery;

    bootbox.dialog({
      message: `${I18n.t('order.confirm.delete_delivery')}<br><br>${I18n.t('item.items')}<br><small>${items.outerHTML}</small>`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/order/${uuid}/delivery/${delivery}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
          }
        }
      }
    });
  }

  advStockMgmtUpdatePositions(card) {
    let quantity = card.querySelector("#item_quantity");
    let table_positions = card.querySelector(".adv-stock-mgmt-positions > div > table")
    let pos_q = quantity.value;
    for (var i = 0, row; row = table_positions.rows[i]; i++) {
      let inp = row.cells[3].querySelector('input#position_quantity');
      if(inp) {
        if(pos_q > 0) {
          let pos_stock = parseFloat(inp.dataset.stock);
          if(pos_stock > pos_q) {
            inp.value = pos_q;
            pos_q -= pos_q;
          } else {
            inp.value = pos_stock;
            pos_q -= pos_stock;
          }
        } else {
          inp.value = 0;
        }
      }
    }
  }

  initItemSelectDialog() {
    let that = this;

    this.itemSelectDialog = new bootstrap.Modal(document.querySelector('#itemSelectDialog'), {
      keyboard: true,
      focus: true
    });

    let table = $('#itemSelect').bootstrapTable({
      locale: this.locale,
      url: `/${this.locale}/orders/items`,
      search: true,
      loadingFontSize: '1rem',
      classes: 'table',
      searchTimeOut: 500,
      clickToSelect: true,
      showColumns: true,
    });

    document.querySelector('#itemSelectDialog div.search').classList.add('w-75');
    document.querySelector('#itemSelectDialog input.search-input').style.height = '34px';

    that.vat_select = '';
    Rails.ajax({
      url: `/${that.locale}/orders/vats`,
      type: "get",
      data: "",
      success: function(data) {
        that.vat = data;
        /*
        Object.keys(data).forEach((country, i) => {
          that.vat_select += `<optgroup label="${I18n.t(`countries.${country}`)}">`;
          data[country].forEach((vat, i) => {
            that.vat_select += `<option value="${vat.value.toFixed(1)}">${vat.label}</option>`;
          });
          that.vat_select += "</optgroup>";
        });
        */
      },
      error: function(data) {
      }
    });

    that.warehouse_select = '';
    Rails.ajax({
      url: `/${that.locale}/warehouses`,
      type: "get",
      data: "",
      success: function(data) {
        that.warehouses = data;
        data.forEach((warehouse, i) => {
          that.warehouse_select += `<option value="${warehouse.uuid}" style="color: ${warehouse.data.color}">${warehouse.name}</option>`
        });
      },
      error: function(data) {
      }
    });


    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))

      Rails.ajax({
        url: `/${that.locale}/subscription/config`,
        type: "post",
        data: `parameter=orders_items_select_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });

    let dialog = document.querySelector('#itemSelectDialog');
    let btnSelect = dialog.querySelector("button#btnSelect");
    btnSelect.addEventListener('click', function (event) {
      btnSelect.disabled = true;
      let icon = document.createElement("i");
      icon.classList.add("fas", "fa-spinner", "fa-pulse");
      btnSelect.prepend(icon);

      setTimeout(() => {
        let items = table.bootstrapTable('getSelections');
        if(items.length > 0) {
          that.addSelectedItems(items);
        }
      }, 50);

      setTimeout(() => {
        that.itemSelectDialog.hide();
        let icon2 = btnSelect.querySelector('svg');
        btnSelect.removeChild(icon2);
        btnSelect.disabled = false;

        dialog.querySelectorAll("button[data-loaded='true']").forEach((btn, i) => {
          let tab = btn.parentNode.querySelector('table');
          tab.innerHTML = '';
          btn.dataset.loaded = 'false';
        });

        table.bootstrapTable('uncheckAll');
        table.bootstrapTable('refresh', {silent: true});
      }, 250)
    });

    window.itemNameFormatter = function(name, item) {
      return `${name}&nbsp;&nbsp;<span class="small text-muted">${[item.size, item.color].filter(item => item).join(' | ')}</span>`
    }

    window.itemStockFormatter = function(stock, item) {
      if(stock == null) {
        stock = '-'
      }
      if(item.with_stock) {
        return `
          <button type="button" class="btn btn-outline-light btn-sm text-dark" data-action="click->items#loadStockQuantities" data-uuid="${item.uuid}" data-loaded="false">
            ${stock}
          </button>
          <table class="small table-borderless table-sm warehouse-quantities"></table>
        `
      } else {
        return ''
      }
    }

    window.itemStatusFormatter = function(status, item) {
      let str = '';
      if(item.statusname) {
        if(item.status_subscription_id) {
          str += item.statusname;
        } else {
          str += I18n.t(`item.states.${item.statusname}`);
        }
      }
      return str
    }

    window.itemPriceFormatter = function(price, item) {
      if(price && !isNaN(price)) {
        if(item.price_with_discount) {
          return `
            <span class="text-danger">
              ${that.currenciesFormat(item.price_with_discount.toFixed(2), item.currency)}
              </span>
            <br>
            <small class="text-muted">
              ${that.currenciesFormat(item.price_with_vat.toFixed(2), item.currency)}
            </small>
          `
        } else {
          return `
            ${that.currenciesFormat(price.toFixed(2), item.currency)}
            <br>
            <small class="text-muted">
              ${that.currenciesFormat(item.price_with_vat.toFixed(2), item.currency)}
            </small>
          `
        }
      } else {
        return ''
      }
    }

    window.itemReservedFormatter = function(reserved, item) {
      if(item.with_stock && item.reserved && item.reserved > 0) {
        return reserved
      } else {
        return ''
      }
    }

    window.itemAvailabilityFormatter = function(available, item) {
      if(item.with_stock) {
        return item.availability
      } else {
        return ''
      }
    }
  }

  initData() {
    let that = this;
    let table = $("#orders");

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('order.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('order.per_page', {count: count});
    }

    table.bootstrapTable({
      url: `${window.location.href}`,//?limit=500`,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchSelector: '#filter_search',
      searchText: this.filterTarget.value,
      searchTimeOut: 500,
      loadingFontSize: '1rem'
    });
    table.on('load-success.bs.table', () => {
      if(this.filterTarget.value != '') {
        this.filterTarget.focus();
        this.filterTarget.blur();
      }
      if(table.bootstrapTable('getData').length >= 500) {
        //this.loadNextData(500);
      }

      //Disable show/hide toggle for actions column
      let chks = document.querySelector('.fixed-table-toolbar').querySelectorAll('input[type=checkbox]');
      if(chks) {
        chks[chks.length-1].parentNode.classList.add('d-none');
      }
    });

    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))
      Rails.ajax({
        url: `/${that.locale}/subscription/config`,
        type: "post",
        data: `parameter=orders_list_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });
  }

  initDHLData() {
    let that = this;
    let table = $("#dhl_express");

    let url = window.location.href
    if(Cookies.get('dhlexpress_mydhl_show_archive') == "true") {
      url = `${window.location.href}?archive=true`
    }

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('order.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('order.per_page', {count: count});
    }

    table.bootstrapTable({
      url: url,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchSelector: '#filter_search',
      searchText: this.filterTarget.value,
      searchTimeOut: 500,
      loadingFontSize: '1rem'
    });
    table.on('load-success.bs.table', () => {
      if(this.filterTarget.value != '') {
        this.filterTarget.focus();
        this.filterTarget.blur();
      }

      //Disable show/hide toggle for actions column
      let chks = document.querySelector('.fixed-table-toolbar').querySelectorAll('input[type=checkbox]');
      if(chks) {
        chks[chks.length-1].parentNode.classList.add('d-none');
      }

      const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
      const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
    });
  }

  loadDHLArchive() {
    Cookies.set('dhlexpress_mydhl_show_archive', event.target.checked);
    if(event.target.checked) {
      $('#dhl_express').bootstrapTable('refresh', {url: `${window.location.href}?archive=true`})
    } else {
      $('#dhl_express').bootstrapTable('refresh', {url: window.location.href})
    }
  }

  loadNextData(offset) {
    let that = this;
    Rails.ajax({
      url: `${window.location.href}?limit=500&offset=${offset}`,
      type: "get",
      data: "",
      success: function(data) {
        $("#orders").bootstrapTable('append', data)
        if(data.length == 500) {
          that.loadNextData(offset+500);
        }
      },
      error: function(data) {
        console.log("Error loading orders");
      }
    });
  }

  initTableFunctions() {
    let that = this;
    I18n.locale = this.locale;
    let locale = this.locale;
    let ordernext = I18n.t('order.states.progress');

    window.referenceFormatter = function(reference, row) {
      let link = `/${locale}/order/${row.uuid}`;
      return `<a href="${link}">${reference}</a>`
    }

    window.dateFormatter = function(date) {
      return I18n.l("date.formats.default", date);
    }

    window.paidFormatter = function(paid, row) {
      var icon = '';
      if(row.state == '50_delivered' || row.state == '60_completed') {
        if(paid) {
          icon = '<i class="far fa-check-square text-success"></i>';
        } else {
          icon = '<i class="fas fa-minus-square text-danger"></i>';
        }
      } else {
        if(paid) {
          icon = '<i class="far fa-check-square text-success"></i>';
        }
      }
      return icon
    }

    window.stateFormatter = function(state) {
      switch(state) {
        case '10_new':
          ordernext = I18n.t('order.states.progress');
          return `<span class="text-danger semi-bold">${I18n.t('order.states.new')}</span>`
          break;
        case '20_progress':
          ordernext = I18n.t('order.states.ready_for_shipment');
          return `<span class="text-primary semi-bold">${I18n.t('order.states.progress')}</span>`
          break;
        case '30_ready_shipment':
          ordernext = I18n.t('order.states.shipped');
          return `<span class="text-secondary-light semi-bold">${I18n.t('order.states.ready_for_shipment')}</span>`
          break;
        case '39_shipped_partial':
          ordernext = I18n.t('order.states.shipped');
          return `<span class="text-secondary semi-bold">${I18n.t('order.states.shipped_partial')}</span>`
          break;
        case '40_shipped':
          ordernext = I18n.t('order.states.delivered');
          return `<span class="text-secondary semi-bold">${I18n.t('order.states.shipped')}</span>`
          break;
        case '51_delivered_partial':
          ordernext = I18n.t('order.states.delivered');
          return `<span class="text-success semi-bold">${I18n.t('order.states.delivered_partial')}</span>`
          break;
        case '50_delivered':
          ordernext = I18n.t('order.states.completed');
          return `<span class="text-success semi-bold">${I18n.t('order.states.delivered')}</span>`
          break;
        case '60_completed':
          return `<span class="text-body semi-bold">${I18n.t('order.states.completed')}</span>`
          break;
        case '90_cancelled':
          return `<span class="text-black-50 semi-bold">${I18n.t('order.states.cancelled')}</span>`
          break;
      }
    }

    window.customerFormatter = function (company, row) {
      let link = `/${locale}/order/${row.uuid}`;
      var subject = '';
      if(row.subject && row.subject != '') {
        subject = `
          <small class="small text-muted">
            <br>${row.subject}
          </small>
        `;
      }
      return `<a href="${link}">${company}</a>${subject}`
    }

    window.addressFormatter = function (address, row) {
      let addr = [];

      if(address) {
        if(address.addressline1 && address.addressline1 != '') addr.push(address.addressline1)
        if(address.addressline2 && address.addressline2 != '') addr.push(address.addressline2)
        if(address.name && address.name != '') addr.push(address.name)
        if(address.street && address.street != '') addr.push(address.street)
        let str = `${address.zip} ${address.location}`.trim();
        if(str != '') {
          if(address.country && address.country != '') {
            str = `${address.country}-${str}`
          }
        }
        if(str != '') addr.push(str)

        return `<address class="small order_shipping_address">${addr.join('<br>')}</address>`
      } else {
        return `<address class="small order_shipping_address"></address>`
      }
    }

    window.countryFormatter = function(address, row) {
      let country = '';
      if(row.invoice_address && row.invoice_address.name != '') {
        if(row.invoice_address.country && row.invoice_address.country != '') {
          country = countries.getName(row.invoice_address.country, that.locale);
        }
      }
      else if(row.shipping_address) {
        if(row.shipping_address.country && row.shipping_address.country != '') {
          country = countries.getName(row.shipping_address.country, that.locale);
        }
      }
      return `<address class="small">${country}</address>`
    }

    window.fulfillmentFormatter = function(fulfillment, row) {
      let info = ''
      if (fulfillment == 'fluehmann') {
        info = 'Flühmann';
      } else if(fulfillment == 'lufapak') {
        info = 'Lufapak';
      } else if(fulfillment == 'evasioni') {
        info = 'Evasioni';
      }
      return `<span class="badge text-bg-secondary">${info}</span>`
    }

    window.amountFormatter = function (total, row) {
      if(!isNaN(total) && row.total_with_vat) {
        return `<span class="smaller">
          ${that.currenciesFormat(total.toFixed(2), row.currency)}
          <br>
          ${that.currenciesFormat(row.total_with_vat.toFixed(2), row.currency)}
          </span>`
      } else {
        return `<span class="smaller">
          ${that.currenciesFormat('0.00', row.currency)}
          <br>
          ${that.currenciesFormat('0.00', row.currency)}
          </span>`
      }
    }

    window.customGalaxusFormatter = function (foo, order) {
      let icons = '';
      if(order.data.galaxus)
      {
        if(!order.deliveries || order.deliveries.length == 0) {
          icons = '<i class="fas fa-shipping-fast text-muted"></i>';
        } else {
          let count_notification = 0;
          for(let i = 0; i<order.deliveries.length; i++) {
            if(order.deliveries[i].data && order.deliveries[i].data.galaxus && order.deliveries[i].data.galaxus.dispatchId) {
              count_notification++;
            }
          }
          if(count_notification == order.deliveries.length) {
            icons = '<i class="fas fa-shipping-fast text-success"></i>'
          } else if(count_notification > 0) {
            icons = '<i class="fas fa-shipping-fast text-warning"></i>'
          } else {
            icons = '<i class="fas fa-shipping-fast text-danger"></i>'
          }
        }
        icons += '&nbsp;';
        if(order.data.galaxus.invoiceId) {
          icons += '<i class="fas fa-file-invoice text-success"></i>'
        } else {
          icons += '<i class="fas fa-file-invoice text-danger"></i>'
        }
      }

      return icons;
    }

    window.actionsFormatter = function(data, row) {
      let reference = '';
      if(row.reference) {
        reference = row.reference;
      }

      let html = '';
      if(that.page == 'index') {
        html += `
          <div class="btn-group">
            <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" data-bs-boundary="window" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-ellipsis-v"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
            `
        if(row.state == '30_ready_shipment' || row.state == '39_shipped_partial') {
          html += `
            <h6 class="dropdown-header">${I18n.t('order.state')}</h6>
            <a class="dropdown-item" data-remote="true" href="/${locale}/order/${row.uuid}/delivery/new"><i class="fas fa-chevron-circle-right"></i> ${ordernext}</a>
          `
        }
        else if(row.state == '50_delivered') {
          html += `
            <h6 class="dropdown-header">${I18n.t('order.state')}</h6>
            <a class="dropdown-item" data-remote="true" href="/${locale}/order/${row.uuid}/complete"><i class="fas fa-chevron-circle-right"></i> ${ordernext}</a>
          `
        }
        else if(row.state != '60_completed' && row.state != '90_cancelled') {
          html += `
            <h6 class="dropdown-header">${I18n.t('order.state')}</h6>
            <a class="dropdown-item" data-remote="true" href="/${locale}/order/${row.uuid}/next"><i class="fas fa-chevron-circle-right"></i> ${ordernext}</a>
          `
        }

        if(!row.paid) {
          html += `
            <div class="dropdown-divider"></div>
            <h6 class="dropdown-header">${I18n.t('order.payment')}</h6>
            <a class="dropdown-item" data-remote="true" href="/${locale}/order/${row.uuid}/paid"><i class="far fa-check-circle"></i> ${I18n.t('order.paid')}</a>
          `
        }

        if(row.data && row.data.fulfillment && row.data.fulfillment != '' && row.data.ext_api) {
          html += `
            <div class="dropdown-divider"></div>
            <h6 class="dropdown-header">${I18n.t('order.fulfillment_service')}</h6>
          `
          if(row.data.ext_api && row.data.ext_api.fluehmann && row.data.ext_api.fluehmann.orderId) {
            html += `
              <span class="dropdown-item">
                ${I18n.t('order.order')} ${row.data.ext_api.fluehmann.orderId}
              </span>
            `
          } else {
            `<a class="dropdown-item" data-remote="true" href="/${locale}/order/${row.uuid}/submit_fulfillment_order">${I18n.t('order.submit_fulfillment_order')}</a>`
          }
        }
        html += `
            </div>
          </div>
        `
      }


      html += `
        <div class="btn-group" role="group">
          <a class="btn btn-outline-secondary" href="/${locale}/order/${row.uuid}/edit"><i class="fas fa-edit"></i></a>

          <button type="button" class="btn btn-outline-danger" data-uuid="${row.uuid}" data-orderref="${row.orderref}" data-customer="${row.company}" data-action="click->orders#deleteConfirm"><i class="fas fa-trash"></i></button>
        </div>
      `

      return html;
    }
  }

  initDHLTableFunctions() {
    let that = this;
    I18n.locale = this.locale;
    let locale = this.locale;

    window.orderFormatter = function(reference, row) {
      let link = `/${locale}/order/${row.uuid}`;
      return `<a href="${link}">${reference}</a>`
    }

    window.dateFormatter = function(date) {
      return I18n.l("date.formats.default", date);
    }

    window.deliveryFormatter = function(reference, delivery) {
      if(delivery.ext_link && delivery.ext_link != '') {
        return `<a href="${delivery.ext_link}" target="_blank">${reference}</a>`
      } else {
        return reference;
      }
    }

    window.statusFormatter = function(status) {
      let icon = '<i class="fa-solid fa-clock fa-fw"></i>'
      switch(status) {
        case "picked_up":
          icon = '<i class="fa-solid fa-person-dolly fa-fw"></i>';
          break;
        case "intransit":
          icon = '<i class="fa-solid fa-truck fa-fw"></i>';
          break;
        case "delivered":
          icon = '<i class="fa-solid fa-box-check fa-fw"></i>';
          break;
      }
      return `${icon} ${I18n.t(`order.states.${status}`)}`;
    }

    window.eventsFormatter = function(reference, row) {
      let str = ''
      if(row.data.shipment && row.data.shipment.events) {
        let events = ``
        row.data.shipment.events.forEach((event, i) => {
          events += `<span class='small semi-bold'>${event.date} ${event.time}</span><br>${event.description}<br><br>`
        });
        str = `<button type="button" class="btn btn-sm btn-outline-secondary ms-2" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="focus" data-bs-content="${events}"><i class='fa-regular fa-circle-info'></i></button>`
      }
      return str;
    }
  }

}
