import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {

  static targets = [ "company_label", "vatid_label", "smtp_port", "webshop_company", "webshop_name", "webshop_description", "webshop_countries", "webshop_languages", "webshop_currencies", "webshop_payment_gateways", "webshop_payment_methods", "webshop_template", "webshop_tabs_languages", "webshop_version",  "webshop_tab_content_languages", "webshop_locales", "startpage_sections", "webshop_tabs_startpage_countries", "webshop_tab_content_startpage_countries", "webshop_categories", "webshop_subcategories", "categories", "webshop_pages", "vouchers", "webshop_vouchers", "emailTemplateContent", "cm_bexio_pt_matching", "expiration_date_notification_unit" ]

  static values = {
    directUrl: String,
    blobUrlTemplate: String
  }

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-section")) {
      this.section = this.element.getAttribute("data-section");
    }
    if(this.element.getAttribute("data-module")) {
      this.module = this.element.getAttribute("data-module");
    }
    if(this.element.getAttribute("data-reference")) {
      this.reference = this.element.getAttribute("data-reference");
    }
    if(this.element.getAttribute("data-uuid")) {
      this.uuid = this.element.getAttribute("data-uuid");
    }
    if(this.element.getAttribute("data-plugin")) {
      this.plugin = this.element.getAttribute("data-plugin");
    }
  }

  connect() {
    $("#subscription_country").selectpicker({ width: '100%' });
    $("#subscription_locale").selectpicker({ width: '100%' });
    $("#subscription_locale_docs").selectpicker({ width: '100%' });
    $("#store_warehouse").selectpicker({ width: '100%' });
    $("#documents_font").selectpicker({ width: '100%' });
    $("#advanced_smtp_encryption").selectpicker({ width: '100%' });
    $(".selectpicker.currencies").selectpicker({ width: '100%' });
    $(".selectpicker.vat-countries").selectpicker({ width: '100%' });
    $(".selectpicker.document-locale").selectpicker({ width: '200px' });
    $("#orders_customers").selectpicker({ width: '100%' });
    $("#purchases_suppliers").selectpicker({ width: '100%' });
    $("#orders_payment_methods").selectpicker({ width: '100%' });

    if(this.section == 'documents') {
      new Sortable(columns_offers, {
          handle: '.handle',
          animation: 150
      });
      new Sortable(columns_order_confirmations, {
          handle: '.handle',
          animation: 150
      });
      new Sortable(columns_invoices, {
          handle: '.handle',
          animation: 150
      });
      new Sortable(columns_receipts, {
          handle: '.handle',
          animation: 150
      });
      new Sortable(columns_delivery_notes, {
          handle: '.handle',
          animation: 150
      });
      new Sortable(columns_pick_lists, {
          handle: '.handle',
          animation: 150
      });
      new Sortable(columns_purchase_orders, {
          handle: '.handle',
          animation: 150
      });
    }

    if(this.section == 'items') {
      this.loadItemStates();
      this.loadItemPriceCategories();
    }

    if(this.section == 'webshop') {
      this.loadShopConfiguration(this.uuid);
      this.loadShopPages(this.uuid);
      this.loadShopVouchers(this.uuid);
      this.shopInitCategorySelectDialog();
      this.shopInitItemSelectDialog();

      new Sortable(this.webshop_categoriesTarget, {
          handle: 'div.category',
          animation: 150,
      });

      this.webshop_subcategoriesTargets.forEach((div, i) => {
        new Sortable(div, {
            handle: '.handle',
            animation: 150,
        });
      });

      let sel = document.querySelector("#webshop_images_format");
      if(sel) {
        sel.value = sel.dataset.selected;
      }
    }

    if(this.section == 'email') {
      let chk = document.querySelector("input#email_smtp_enabled");
      if(chk) {
        let div = chk.closest("div.tab-pane");
        let table = div.querySelector('table');

        if(chk.checked) {
          table.querySelectorAll('input, select, button').forEach((inp, i) => {
            inp.removeAttribute('disabled')
          });
        } else {
          table.querySelectorAll('input, select, button').forEach((inp, i) => {
            inp.setAttribute('disabled', 'true')
          });
        }
      }
    }

    if(this.section == 'warehouses') {
      if(this.hasExpiration_date_notification_unitTarget) {
        this.expiration_date_notification_unitTarget.value = this.expiration_date_notification_unitTarget.dataset.selected;
      }
    }

    if(this.reference) {
      this.completeBankaccountLink();
    }

    if(this.plugin) {
      if(this.plugin == 'dhlexpress_mydhl') {
        let sel = document.querySelector("#settings_pickup_country");
        if(sel) {
          sel.value = sel.dataset.selected;
        }
        $("#settings_pickup_country").selectpicker({ width: '100%' });
      }
    }

    if(this.module) {
      if(this.module == 'bexio') {
        let sel = document.querySelector("#settings_user_contacts");
        if(sel) {
          sel.value = sel.dataset.selected;
        }
        sel = document.querySelector("#settings_user_documents");
        if(sel) {
          sel.value = sel.dataset.selected;
        }
        sel = document.querySelector("#settings_bank_account");
        if(sel) {
          sel.value = sel.dataset.selected;
        }
        this.cm_bexio_pt_matchingTarget.querySelectorAll('select').forEach((sel, i) => {
          sel.value = sel.dataset.selected;
        });

      }
    }

    if(this.module) {
      if(this.module == 'hci_solutions') {
        let sel = document.querySelector("#hci_solutions_swissmedic_supply_category");
        if(sel) {
          sel.value = sel.dataset.selected;
        }
      }
    }

    $('[data-bs-toggle="popover"]').popover();
    $('[data-bs-toggle="tooltip"]').tooltip();

    $('#loading').fadeOut(200);
  }

  toggleRetention() {
    $("#company_retention_percentage").attr('disabled', !event.target.checked);
    $("#company_retention_percentage").attr('required', event.target.checked);
  }

  toggleAssignCustomerNumber() {
    $("#contacts_customer_number_start").attr('readonly', !event.target.checked);
  }

  toggleCompanyType() {
    let c = document.querySelector("#subscription_country").value;
    if(event.target.value == 'juridical') {
      this.company_labelTarget.textContent = I18n.t('contact.company');

      if(c == 'ES') {
        this.vatid_labelTarget.textContent = 'CIF';
      }
    } else {
      this.company_labelTarget.textContent = I18n.t('contact.name');

      if(c == 'ES') {
        this.vatid_labelTarget.textContent = 'NIF';
      }
    }
  }

  deleteConfirmStore() {
    let that = this;
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead">${event.target.dataset.name}</span>`;
    bootbox.dialog({
      message: `${I18n.t('store.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/store/${uuid}`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  deleteConfirmWarehouse() {
    let that = this;
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead">${event.target.dataset.name}</span>`;
    bootbox.dialog({
      message: `${I18n.t('warehouse.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/warehouse/${uuid}`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  deleteConfirmBankaccount() {
    let that = this;
    var uuid = event.target.dataset.uuid;
    let name = event.target.dataset.name;
    let iban = event.target.dataset.iban;
    bootbox.dialog({
      message: `${I18n.t('accounting.bankaccount.confirm.delete')}<br><br><span class="lead">${name}</span><br><span class="small text-muted">${iban}</span>`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/bankaccount/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  changeSmtpEncryption() {
    switch(event.target.value) {
      case 'starttls':
        this.smtp_portTarget.value = 587;
        break;
      case 'ssl':
        this.smtp_portTarget.value = 465;
        break;
      default:
        this.smtp_portTarget.value = 25;
    }
  }

  testSmtpServer() {
    let that = this;
    var title = event.target.dataset.title;
    var message = event.target.dataset.message;

    var success = event.target.dataset.success;
    var error = event.target.dataset.error;

    bootbox.prompt({
      title: title,
      message: message,
      locale: that.locale,
      inputType: 'email',
      callback: function(address){
        if(address != '') {
          var form = document.querySelector("#formEmailSmtp");
          var formData = $(form).serialize();
          formData += `&recipient=${address}`
          Rails.ajax({
            url: `/${that.locale}/subscription/smtp_test`,
            type: "post",
            data: formData,
            success: function(data) {
              bootbox.alert(success);
            },
            error: function(data) {
              bootbox.alert(`${error}<br><br><b>${data}</b>`);
            }
          });
        }
      }
  });
  }

  disableButton() {
    event.target.classList.add('disabled');
    let i = event.target.querySelector('i');
    i.classList.add('fa-pulse');
  }

  loadItemStates() {
    Rails.ajax({
      url: `/${this.locale}/subscription/items/states`,
      type: "get",
      success: function(data) {}
    });
  }

  editItemStatus() {
    let parent = event.target.parentNode;
    parent.innerHTML = `
      <form class="row g-3 align-items-center w-100" action="/${this.locale}/subscription/items/states" accept-charset="UTF-8" data-remote="true" method="post">
        <input required="required" type="hidden" name="status[uuid]" id="status_uuid" value="${event.target.dataset.uuid}">
        <div class="col-auto">
          <input class="form-control flex-fill" required="required" type="text" name="status[name]" id="status_name" value="${event.target.dataset.name}">
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-outline-success btn-sm ms-2"><i class="fas fa-check"></i></button>
          <button type="button" class="btn btn-outline-secondary btn-sm ms-2" data-action="click->settings#loadItemStates"><i class="fas fa-times"></i></button>
        </div>
      </form>
    `;
  }

  deleteItemStatusConfirm() {
    let parent = event.target.parentNode;
    parent.innerHTML = `
      <span class="flex-fill">${event.target.dataset.name}</span>
      <button type="button" class="btn btn-danger btn-sm" data-action="click->settings#deleteItemStatus" data-uuid="${event.target.dataset.uuid}">${event.target.dataset.label}</button>
      <button type="button" class="btn btn-outline-secondary btn-sm ms-2" data-action="click->settings#loadItemStates"><i class="fas fa-times"></i></button>
    `;
  }

  deleteItemStatus() {
    let that = this;
    let uuid = event.target.dataset.uuid;
    Rails.ajax({
      url: `/${this.locale}/subscription/items/states/${uuid}`,
      type: "delete",
      success: function(data) {
        that.loadItemStates();
      }
    });
  }


  loadItemPriceCategories() {
    Rails.ajax({
      url: `/${this.locale}/subscription/items/prices`,
      type: "get",
      success: function(data) {}
    });
  }

  loadItemCustomFields() {
    Rails.ajax({
      url: `/${this.locale}/subscription/items/custom_fields`,
      type: "get",
      success: function(data) {}
    });
  }

  loadContactCustomFields() {
    Rails.ajax({
      url: `/${this.locale}/subscription/contacts/custom_fields`,
      type: "get",
      success: function(data) {}
    });
  }

  editItemPriceCategory() {
    let parent = event.target.parentNode;
    parent.innerHTML = `
      <form class="row g-3 align-items-center w-100" action="/${this.locale}/subscription/items/prices" accept-charset="UTF-8" data-remote="true" method="post">
        <input required="required" type="hidden" name="price_category[uuid]" id="price_category_uuid" value="${event.target.dataset.uuid}">
        <div class="col-auto">
          <input class="form-control flex-fill" required="required" type="text" name="price_category[name]" id="price_category_name" value="${event.target.dataset.name}">
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-outline-success btn-sm ms-2"><i class="fas fa-check"></i></button>
          <button type="button" class="btn btn-outline-secondary btn-sm ms-2" data-action="click->settings#loadItemPriceCategories"><i class="fas fa-times"></i></button>
        </div>
      </form>
    `;
  }

  editItemCustomField() {
    let parent = event.target.parentNode;
    parent.innerHTML = `
      <form class="row g-3 align-items-center w-100" action="/${this.locale}/subscription/items/custom_fields" accept-charset="UTF-8" data-remote="true" method="post">
        <input required="required" type="hidden" name="item_custom_field[uuid]" id="item_custom_field_uuid" value="${event.target.dataset.uuid}">
        <div class="col-auto">
          <input class="form-control flex-fill" required="required" type="text" name="item_custom_field[label]" id="item_custom_field_label" value="${event.target.dataset.label}">
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-outline-success btn-sm ms-2"><i class="fas fa-check"></i></button>
          <button type="button" class="btn btn-outline-secondary btn-sm ms-2" data-action="click->settings#loadItemCustomFields"><i class="fas fa-times"></i></button>
        </div>
      </form>
    `;
  }

  editContactCustomField() {
    let parent = event.target.parentNode;
    parent.innerHTML = `
      <form class="row g-3 align-items-center w-100" action="/${this.locale}/subscription/contacts/custom_fields" accept-charset="UTF-8" data-remote="true" method="post">
        <input required="required" type="hidden" name="contact_custom_field[uuid]" id="contact_custom_field_uuid" value="${event.target.dataset.uuid}">
        <div class="col-auto">
          <input class="form-control flex-fill" required="required" type="text" name="contact_custom_field[label]" id="contact_custom_field_label" value="${event.target.dataset.label}">
        </div>
        <div class="col-auto">
          <button type="submit" class="btn btn-outline-success btn-sm ms-2"><i class="fas fa-check"></i></button>
          <button type="button" class="btn btn-outline-secondary btn-sm ms-2" data-action="click->settings#loadContactCustomFields"><i class="fas fa-times"></i></button>
        </div>
      </form>
    `;
  }

  deleteItemPriceCategoryConfirm() {
    let parent = event.target.parentNode;
    parent.innerHTML = `
      <span class="flex-fill">${event.target.dataset.name}</span>
      <button type="button" class="btn btn-danger btn-sm" data-action="click->settings#deleteItemPriceCategory" data-uuid="${event.target.dataset.uuid}">${event.target.dataset.label}</button>
      <button type="button" class="btn btn-outline-secondary btn-sm ms-2" data-action="click->settings#loadItemPriceCategories"><i class="fas fa-times"></i></button>
    `;
  }

  deleteItemCustomFieldConfirm() {
    let parent = event.target.parentNode;
    parent.innerHTML = `
      <span class="flex-fill">${event.target.dataset.name}</span>
      <button type="button" class="btn btn-danger btn-sm" data-action="click->settings#deleteItemCustomField" data-uuid="${event.target.dataset.uuid}">${event.target.dataset.label}</button>
      <button type="button" class="btn btn-outline-secondary btn-sm ms-2" data-action="click->settings#loadItemCustomFields"><i class="fas fa-times"></i></button>
    `;
  }

  deleteContactCustomFieldConfirm() {
    let parent = event.target.parentNode;
    parent.innerHTML = `
      <span class="flex-fill">${event.target.dataset.name}</span>
      <button type="button" class="btn btn-danger btn-sm" data-action="click->settings#deleteContactCustomField" data-uuid="${event.target.dataset.uuid}">${event.target.dataset.label}</button>
      <button type="button" class="btn btn-outline-secondary btn-sm ms-2" data-action="click->settings#loadContactCustomFields"><i class="fas fa-times"></i></button>
    `;
  }

  deleteItemPriceCategory() {
    let that = this;
    let uuid = event.target.dataset.uuid;
    Rails.ajax({
      url: `/${this.locale}/subscription/items/prices/${uuid}`,
      type: "delete",
      success: function(data) {
        that.loadItemPriceCategories();
      }
    });
  }

  deleteItemCustomField() {
    let that = this;
    let uuid = event.target.dataset.uuid;
    Rails.ajax({
      url: `/${this.locale}/subscription/items/custom_field/${uuid}`,
      type: "delete",
      success: function(data) {
        that.loadItemCustomFields();
      }
    });
  }

  deleteContactCustomField() {
    let that = this;
    let uuid = event.target.dataset.uuid;
    Rails.ajax({
      url: `/${this.locale}/subscription/contacts/custom_field/${uuid}`,
      type: "delete",
      success: function(data) {
        that.loadContactCustomFields();
      }
    });
  }

  completeBankaccountLink() {
    Rails.ajax({
      url: `/${this.locale}/bankaccount/${this.reference}/link/complete`,
      type: "get",
      success: function(data) {}
    });
  }

  addAPIUser() {
    var that = this;
    let table = document.querySelector('table');
    let rows = table.rows.length;
    bootbox.prompt({
      title: I18n.t('api.new'),
      locale: that.locale,
      inputType: 'text',
      value: `API #${rows+1}`,
      callback: function(comment){
        if(comment) {
          Rails.ajax({
            url: `/${that.locale}/subscription/api/create`,
            type: "post",
            data: `comment=${comment}`,
            success: function(data) {
              location.reload()
            },
            error: function(data) {}
          });
        }
      }
    });
  }

  deleteAPIUser() {
    let td = event.target.parentNode;
    let btnDelete = td.querySelector('.btn.btn-danger');
    event.target.classList.add('d-none');
    btnDelete.classList.remove('d-none');
  }

  deleteFeed() {
    let td = event.target.parentNode;
    let btnDelete = td.querySelector('.btn.btn-danger');
    event.target.classList.add('d-none');
    btnDelete.classList.remove('d-none');
  }

  showAPISecret() {
    let td = event.target.parentNode.parentNode;
    td.querySelectorAll('span').forEach((span, i) => {
      span.classList.toggle('d-none');
    });
  }

  copyAPISecret() {
    let secret = event.target.dataset.secret;
    if(secret) {
      navigator.clipboard.writeText(secret);
      business.showToast(I18n.t('api.credentials_copied'));
    }
    event.target.blur();
  }

  messagingEmailToggleOwnServer() {
    let div = event.target.closest("div.tab-pane");
    let table = div.querySelector('table');

    if(event.target.checked) {
      table.querySelectorAll('input, select, button').forEach((inp, i) => {
        inp.removeAttribute('disabled')
      });
    } else {
      table.querySelectorAll('input, select, button').forEach((inp, i) => {
        inp.setAttribute('disabled', 'true')
      });
    }
  }

  changeEmailTemplateLanguage() {
    var element = document.querySelector("trix-editor")
    element.editor.loadHTML('');

    let div = event.target.closest("div.container-fluid");
    let template = div.querySelector("select#email_template");
    template.value = '';
  }

  loadEmailTemplate() {
    let div = event.target.closest("div.container-fluid");
    let language = div.querySelector("select#email_template_language").value;
    let template = div.querySelector("select#email_template").value;
    var element = document.querySelector("trix-editor")
    element.editor.loadHTML('');

    let content = div.querySelector('input[name="email[template]"]');
    content.value = '';

    if(template != '') {
      Rails.ajax({
        url: `/${this.locale}/settings/config?attr=messaging.templates.${language}.${template}`,
        type: "get",
        data: "",
        success: function(data) {
          element.editor.loadHTML(data);
        },
        error: function(data) {}
      });
    }
  }

  saveEmailTemplate() {
    let div = event.target.closest("div.container-fluid");
    let language = div.querySelector("select#email_template_language").value;
    let template = div.querySelector("select#email_template").value;
    let content = div.querySelector('input[name="email[template]"]');

    var fd = new FormData();
    fd.append("attr", `messaging.templates.${language}.${template}`);
    fd.append("value", content.value);

    Rails.ajax({
      url: `/${this.locale}/settings/config`,
      type: "post",
      data: fd,
      success: function(data) {
        business.showAlert('success', I18n.t('subscription.message.settings_saved'));
      },
      error: function(data) {}
    });
  }

  loadShopConfiguration(uuid) {
    var that = this;

    Rails.ajax({
      url: `/${this.locale}/webshop/${uuid}/config`,
      type: "get",
      success: function(data) {
        that.webshop_versionTarget.textContent = data.system.version;
        that.webshop_companyTarget.textContent = data.system.company;
        that.webshop_nameTarget.textContent = data.system.name;
        that.webshop_descriptionTarget.textContent = data.system.description;
        that.webshop_countriesTarget.innerHTML = `<b>${countries.getName(data.system.country, that.locale)}</b>`
        that.webshop_tabs_startpage_countriesTarget.innerHTML = `
          <button class="nav-item nav-link active" type="button" data-bs-target="#startpage_country_${data.system.country}" role="tab" data-bs-toggle="tab">
            ${countries.getName(data.system.country, that.locale)}
          </button>
        `
        that.webshop_tab_content_startpage_countriesTarget.innerHTML = `
          <div role="tabpanel" class="tab-pane active pt-4 pl-2 pr-2" id="startpage_country_${data.system.country}" data-country="${data.system.country}">
          </div>
        `

        for(let i = 0; i < data.system.countries.length; i++) {
          if(data.system.countries[i] == data.system.country) {
            continue;
          }
          that.webshop_countriesTarget.innerHTML += `<br>${countries.getName(data.system.countries[i], that.locale)}`

          that.webshop_tabs_startpage_countriesTarget.innerHTML += `
            <button class="nav-item nav-link" type="button" data-bs-target="#startpage_country_${data.system.countries[i]}" role="tab" data-bs-toggle="tab">
              ${countries.getName(data.system.countries[i], that.locale)}
            </button>
          `
          that.webshop_tab_content_startpage_countriesTarget.innerHTML += `
            <div role="tabpanel" class="tab-pane pt-4 pl-2 pr-2" id="startpage_country_${data.system.countries[i]}" data-country="${data.system.countries[i]}"></div>
          `
        }

        that.webshop_languagesTarget.innerHTML = `<b>${I18n.t(`language.${data.system.locale}`)}</b>`;

        that.webshop_tabs_languagesTarget.innerHTML = `
          <button class="nav-item nav-link active" type="button" data-bs-target="#language_${data.system.locale}" role="tab" data-bs-toggle="tab">
            ${I18n.t(`language.${data.system.locale}`)}
          </button>
        `
        that.webshop_tab_content_languagesTarget.innerHTML = `
          <div role="tabpanel" class="tab-pane active pt-2 pl-2 pr-2" id="language_${data.system.locale}">
            <div class="row mt-4">
              <div class="col-12 col-md-6">
                <div class="form-group mb-3 ms-2">
                  <label for="startpage_header">
                    ${I18n.t('webshop.settings.startpage_header')}
                    | ${I18n.t(`language.${data.system.locale}`)}
                  </label>
                  <input class="form-control" type="text" name="start[header_${data.system.locale}]" id="start_header_${data.system.locale}">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="form-group mb-3 ms-2">
                  <label for="startpage_description">
                    ${I18n.t('webshop.settings.startpage_description')}
                    | ${I18n.t(`language.${data.system.locale}`)}
                  </label>
                  <input type="hidden" name="start[description_${data.system.locale}]" id="start_description_${data.system.locale}">
                  <div class="trix-content">
                    <trix-editor id="trix_startpage_description_${data.system.locale}" class="description" input="start_description_${data.system.locale}" data-direct-upload-url="${that.directUrlValue}" data-blob-url-template="${that.blobUrlTemplateValue}">
                    </trix-editor>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="form-group mb-3 ms-2">
                  <label for="startpage_footer">
                    ${I18n.t('webshop.settings.startpage_footer')}
                    | ${I18n.t(`language.${data.system.locale}`)}
                  </label>
                  <input type="hidden" name="start[footer_${data.system.locale}]" id="start_footer_${data.system.locale}">
                  <div class="trix-content">
                    <trix-editor id="trix_startpage_footer_${data.system.locale}" class="" input="start_footer_${data.system.locale}" data-direct-upload-url="${that.directUrlValue}" data-blob-url-template="${that.blobUrlTemplateValue}">
                    </trix-editor>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `

        that.locales = data.system.locales;
        that.country = data.system.country;
        that.countries = data.system.countries;
        that.webshop_localesTarget.value = data.system.locales;

        for(let i = 0; i < data.system.locales.length; i++) {
          if(data.system.locales[i] == data.system.locale) {
            continue;
          }

          that.webshop_languagesTarget.innerHTML += `<br>${I18n.t(`language.${data.system.locales[i]}`)}`

          that.webshop_tabs_languagesTarget.innerHTML += `
            <button class="nav-item nav-link" type="button" data-bs-target="#language_${data.system.locales[i]}" role="tab" data-bs-toggle="tab">
              ${I18n.t(`language.${data.system.locales[i]}`)}
            </button>
          `

          that.webshop_tab_content_languagesTarget.innerHTML += `
            <div role="tabpanel" class="tab-pane active pt-2 pl-2 pr-2" id="language_${data.system.locales[i]}">
              <div class="row mt-4">
                <div class="col-12 col-md-6">
                  <div class="form-group mb-3 ms-2">
                    <label for="startpage_header">
                      ${I18n.t('webshop.settings.startpage_header')}
                      | ${I18n.t(`language.${data.system.locales[i]}`)}
                    </label>
                    <input class="form-control" data-settings-target="webshop_startpage_header" type="text" name="start[header_${data.system.locales[i]}]" id="start_header_${data.system.locales[i]}">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-9">
                  <div class="form-group mb-3 ms-2">
                    <label for="startpage_description">
                      ${I18n.t('webshop.settings.startpage_description')}
                      | ${I18n.t(`language.${data.system.locales[i]}`)}
                    </label><br>
                    <input type="hidden" name="start[description_${data.system.locales[i]}]" id="start_description_${data.system.locales[i]}">
                    <div class="trix-content">
                      <trix-editor id="trix_startpage_description_${data.system.locales[i]}" class="description" input="start_description_${data.system.locales[i]}"  data-direct-upload-url="${that.directUrlValue}" data-blob-url-template="${that.blobUrlTemplateValue}">
                      </trix-editor>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-9">
                  <div class="form-group mb-3 ms-2">
                    <label for="startpage_footer">
                      ${I18n.t('webshop.settings.startpage_footer')}
                      | ${I18n.t(`language.${data.system.locales[i]}`)}
                    </label><br>
                    <input type="hidden" name="start[footer_${data.system.locales[i]}]" id="start_footer_${data.system.locales[i]}">
                    <div class="trix-content">
                      <trix-editor id="trix_startpage_footer_${data.system.locales[i]}" class="" input="start_footer_${data.system.locales[i]}" data-direct-upload-url="${that.directUrlValue}" data-blob-url-template="${that.blobUrlTemplateValue}">
                      </trix-editor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `
        }

        let btns = that.webshop_tabs_languagesTarget.querySelectorAll('button.nav-item.nav-link');
        if(btns.length > 1) {
          btns[1].click();
          btns[0].click();
        }

        that.webshop_currenciesTarget.innerHTML = `<b>${data.system.currency}</b>`;
        for(let i = 0; i < data.system.currencies.length; i++) {
          if(data.system.currencies[i] == data.system.currency) {
            continue;
          }
          that.webshop_currenciesTarget.innerHTML += `<br>${data.system.currencies[i]}`
        }

        that.webshop_payment_gatewaysTarget.textContent = '';
        for (var key in data.system.payment_gateways) {
          var payment_gateway = data.system.payment_gateways[key];
          if(payment_gateway && payment_gateway.enabled) {
            switch(key) {
              case 'paypal':
                that.webshop_payment_gatewaysTarget.innerHTML += `PayPal<br>`
                break;
              case 'stripe':
                that.webshop_payment_gatewaysTarget.innerHTML += `Stripe<br>`
                break;
              case 'sumup':
                that.webshop_payment_gatewaysTarget.innerHTML += `SumUp<br>`
                break;
              case 'payrexx':
                that.webshop_payment_gatewaysTarget.innerHTML += `Payrexx<br>`
                break;
            }
          }
        }

        that.webshop_payment_methodsTarget.textContent = '';

        let g = 0;
        for (var key in data.system.payment) {
          var payment_method = data.system.payment[key];
          if(g > 0) {
            that.webshop_payment_methodsTarget.innerHTML += '<br>';
          }

          that.webshop_payment_methodsTarget.innerHTML += `<span class="badge bg-secondary">${countries.getName(key, that.locale)}</span><br>`

          for(let i = 0; i < payment_method.length; i++) {
            if(payment_method[i] == 'cards') {
              that.webshop_payment_methodsTarget.innerHTML += I18n.t(`payment_methods.card`)+'<br>';
            } else {
              that.webshop_payment_methodsTarget.innerHTML += I18n.t(`payment_methods.${payment_method[i]}`)+'<br>';
            }
          }

          g++;
        }

        that.webshop_templateTarget.textContent = '';
        switch(data.system.template) {
          case 'basic':
            that.webshop_templateTarget.innerHTML += `Basic`
            break;
          case 'advanced':
            that.webshop_templateTarget.innerHTML += `Advanced`

            that.startpage_sectionsTargets.forEach((el, i) => {
              el.classList.remove('d-none');
            });

            that.categoriesTargets.forEach((el, i) => {
              el.classList.remove('d-none');
            });

            break;
          case 'complete':
            that.webshop_templateTarget.innerHTML += `Complete`
            break;
        }

        if(data.system.vouchers && data.system.vouchers == true) {
          that.vouchersTarget.classList.remove("d-none");
        }

        for(let i = 0; i < data.config.length; i++ ) {
          switch(data.config[i].attr) {
            case 'start.header':
              for(let j = 0; j < data.system.locales.length; j++) {
                let inp = document.querySelector(`input#start_header_${data.system.locales[j]}`);
                if(inp) {
                  if(data.config[i].value[data.system.locales[j]]) {
                    inp.value = data.config[i].value[data.system.locales[j]];
                  }
                }
              }
              break;
            case 'start.description':
              for(let j = 0; j < data.system.locales.length; j++) {
                let trix = document.querySelector(`#trix_startpage_description_${data.system.locales[j]}`);
                if(trix) {
                  if(data.config[i].value[data.system.locales[j]]) {
                    trix.editor.insertHTML(data.config[i].value[data.system.locales[j]])
                  }
                }
              }
              break;
            case 'start.footer':
              for(let j = 0; j < data.system.locales.length; j++) {
                let trix = document.querySelector(`#trix_startpage_footer_${data.system.locales[j]}`);
                if(trix) {
                  if(data.config[i].value[data.system.locales[j]]) {
                    trix.editor.insertHTML(data.config[i].value[data.system.locales[j]])
                  }
                }
              }
              break;
            case 'start.sections':
              that.shopStartpageSections(data.config[i], data.system.locale, data.system.locales)
              break;
          }
        }
      },
      error: function(data) {}
    });
  }

  loadShopPages(uuid) {
    var that = this;

    Rails.ajax({
      url: `/${this.locale}/webshop/${uuid}/pages`,
      type: "get",
      success: function(data) {
        that.webshop_pagesTarget.innerHTML = "";
        if(data.length > 0) {
          data.forEach((page, i) => {
            let ctr_str = '';
            if(page.data && page.data.countries) {
              page.data.countries.map((country) => {
                ctr_str += `<span class="badge bg-secondary ms-1" title="${countries.getName(country, that.locale)}">${country.toUpperCase()}</span>`
              })
            }
            let li = document.createElement("li");
            li.classList.add("list-group-item")
            li.setAttribute("data-enabled", page.enabled)
            li.setAttribute("data-uuid", page.uuid)
            li.innerHTML = `
              <div class="d-flex justify-content-between">
                <span class="flex-grow">
                  ${page.system ? `${I18n.t(`webshop.page.${page.page}`)} <span class="small text-secondary ms-2">${I18n.t("webshop.system")}</span>` : ''}
                  ${!page.system && page.content[I18n.locale] ? page.content[I18n.locale].title : ''}
                  ${ctr_str}
                </span>
                <div>
                  <span class="enabled smaller">
                    ${page.enabled ? `<span class="badge bg-success"><i class="fa-solid fa-check fa-fw"></i> ${I18n.t('webshop.page.active')}</span>` : `<span class="badge bg-secondary"><i class="fa-solid fa-xmark fa-fw"></i> ${I18n.t('webshop.page.inactive')}</span>`}
                  </span>

                  <button type="button" class="btn btn-sm btn-outline-secondary ms-1" data-uuid="${page.uuid}" data-action="click->settings#shopEditPage">
                    <i class="fa-solid fa-pen"></i>
                  </button>
                  <button type="button" class="btn btn-sm btn-outline-danger ms-1" data-uuid="${page.uuid}" data-title="${page.content[I18n.locale].title}" data-action="click->settings#shopDeleteConfirmPage" ${page.system ? 'disabled' : ''}>
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </div>
              </div>
            `
            that.webshop_pagesTarget.append(li);
          });
        }
      },
      error: function(data) {}
    });
  }

  shopNewPage() {
    let that = this;

    let html = `
      <h6 class="mb-0">${I18n.t('webshop.page.page')}</h6>
      <div class="row">
        <div class="col">
          <div class="form-check my-3">
            <input class="form-check-input" type="checkbox" value="" id="enabled" checked>
            <label class="form-check-label" for="enabled">
              ${I18n.t('webshop.page.active')}
            </label>
          </div>
        </div>
        <div class="col">
          <div class="form-check my-3">
            <input class="form-check-input" type="checkbox" value="" id="navigation">
            <label class="form-check-label" for="navigation">
              ${I18n.t('webshop.page.show_in_navigation')}
            </label>
          </div>
        </div>
      </div>
      <h6 class="mt-3 mb-0">${I18n.t('subscription.countries')}</h6>
      <div class="row">
    `;
    that.countries.forEach((country, i) => {
      html += `
          <div class="col">
            <div class="form-check my-3">
              <input class="form-check-input country" type="checkbox" value="${country}" id="country_${country}" ${country == that.country ? 'checked' : ''}>
              <label class="form-check-label" for="country_${country}">
                ${countries.getName(country, that.locale)}
              </label>
            </div>
          </div>
      `;
    });
    html += `
      </div>
      <div class="accordion" id="pagesContent">
    `

    this.locales.forEach((locale, i) => {
      html += `
        <div class="accordion-item" data-locale="${locale}">
          <input type="hidden" id="content_${locale}" value=''>
          <h2 class="accordion-header">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#${locale}" aria-expanded="${i == 0 ? 'true' : 'false'}" aria-controls="${locale}">
              ${I18n.t(`language.${locale}`)}
            </button>
          </h2>
          <div id="${locale}" class="accordion-collapse collapse ${i == 0 ? 'show' : ''}" data-bs-parent="#pagesContent">
            <div class="accordion-body">
              <label for="title" class="form-label">${I18n.t('webshop.page.page')}</label>
              <input id="title_${locale}" type="text" class="form-control mb-4" value="" required>
              <div class="trix-content">
                <trix-editor input="content_${locale}" style="min-height:450px;max-height:60vh;overflow:auto;" data-direct-upload-url="${that.directUrlValue}" data-blob-url-template="${that.blobUrlTemplateValue}">
                </trix-editor>
              </div>
            </div>
          </div>
        </div>
      `
    });

    html += `
      </div>
    `

    var box = bootbox.dialog({
      title: I18n.t('webshop.page.page'),
      message: html,
      onEscape: true,
      size: 'xl',
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        apply: {
          label: I18n.t('btn.save'),
          className: "btn-success",
          callback: function() {
            let enabled = box[0].querySelector('#enabled').checked
            let navigationMenu = box[0].querySelector('#navigation').checked
            let countries = [];
            box[0].querySelectorAll(".form-check-input.country:checked").forEach((chk, i) => {
              countries.push(chk.value)
            });

            let json = {
              enabled: enabled,
              navigation: navigationMenu,
              countries: countries
            }

            box[0].querySelectorAll('div.accordion-item').forEach((div, i) => {
              json[div.dataset.locale] = {
                title: div.querySelector(`input#title_${div.dataset.locale}`).value,
                content: div.querySelector(`input#content_${div.dataset.locale}`).value
              }
            });

            var fd = new FormData();
            fd.append("webshop", that.uuid);
            fd.append("content", JSON.stringify(json));

            Rails.ajax({
              url: `/${that.locale}/webshop/page`,
              type: "post",
              data: fd,
              success: function(data) {
                that.loadShopPages(that.uuid);
                business.showAlert('success', I18n.t('webshop.message.page_saved'));
              },
              error: function(data) {
                business.showAlert('danger', I18n.t('webshop.message.page_save_error'));
              }
            });
          }
        }
      }
    });
  }

  shopEditPage() {
    let that = this;
    let li = event.target.closest('li');
    let page = li.dataset.uuid;

    Rails.ajax({
      url: `/${this.locale}/webshop/${this.uuid}/page/${page}`,
      type: "get",
      success: function(data) {
        let json = data.content;
        let html = `
          <h6 class="mb-0">${I18n.t('webshop.page.page')}</h6>
          <div class="row">
            <div class="col">
              <div class="form-check my-3">
                <input class="form-check-input" type="checkbox" value="" id="enabled" ${li.dataset.enabled == 'true' ? 'checked' : ''}>
                <label class="form-check-label" for="enabled">
                  ${I18n.t('webshop.page.active')}
                </label>
              </div>
            </div>
          `
        if(!data.system) {
          html += `
            <div class="col">
              <div class="form-check my-3">
                <input class="form-check-input" type="checkbox" value="" id="navigation" ${(data.data && data.data.navigation) ? 'checked' : ''}>
                <label class="form-check-label" for="navigation">
                  ${I18n.t('webshop.page.show_in_navigation')}
                </label>
              </div>
            </div>
          `
        }
        html += `
          </div>
        `
        if(!data.system) {
          html += `
            <h6 class="mt-3 mb-0">${I18n.t('subscription.countries')}</h6>
            <div class="row">
          `
          that.countries.forEach((country, i) => {
            html += `
                <div class="col">
                  <div class="form-check my-3">
                    <input class="form-check-input country" type="checkbox" value="${country}" id="country_${country}" ${data.data.countries.includes(country) ? 'checked' : ''}>
                    <label class="form-check-label" for="country_${country}">
                      ${countries.getName(country, that.locale)}
                    </label>
                  </div>
                </div>
            `;
          });
          html += `
            </div>
          `
        }
        html += `
          <div class="accordion" id="pagesContent">
            <input type="hidden" id="uuid" value='${page}'>
        `

        that.locales.forEach((locale, i) => {
          let title;
          if(data.system) {
            title = I18n.t(`webshop.page.${data.page}`);
          } else {
            if(data.content[locale]) {
              title = data.content[locale].title;
            } else {
              title = '';
            }
          }
          html += `
            <div class="accordion-item" data-locale="${locale}">
              <input type="hidden" id="content_${locale}" value=''>
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#${locale}" aria-expanded="${i == 0 ? 'true' : 'false'}" aria-controls="${locale}">
                  ${I18n.t(`language.${locale}`)}
                </button>
              </h2>
              <div id="${locale}" class="accordion-collapse collapse ${i == 0 ? 'show' : ''}" data-bs-parent="#pagesContent">
                <div class="accordion-body">
                  <label for="title" class="form-label">${I18n.t('webshop.page.page')}</label>
                  <input id="title_${locale}" type="text" class="form-control mb-4" value="${title}" required ${data.system ? 'readonly' : ''}>
                  <div class="trix-content">
                    <trix-editor input="content_${locale}" style="min-height:450px;max-height:60vh;overflow:auto;" data-direct-upload-url="${that.directUrlValue}" data-blob-url-template="${that.blobUrlTemplateValue}">
                    </trix-editor>
                  </div>
                </div>
              </div>
            </div>
          `
        });

        html += `
          </div>
        `

        var box = bootbox.dialog({
          title: I18n.t('webshop.page.page'),
          message: html,
          onEscape: true,
          size: 'xl',
          buttons: {
            cancel: {
              label: I18n.t('btn.cancel'),
              className: "btn-outline-secondary",
            },
            apply: {
              label: I18n.t('btn.save'),
              className: "btn-success",
              callback: function() {
                let uuid = box[0].querySelector('input#uuid').value
                let enabled = box[0].querySelector('#enabled').checked
                let navigationMenu = false;
                if(box[0].querySelector('#navigation')) {
                  navigationMenu = box[0].querySelector('#navigation').checked
                }

                let countries = [];
                box[0].querySelectorAll(".form-check-input.country:checked").forEach((chk, i) => {
                  countries.push(chk.value)
                });

                let json = {
                  uuid: uuid,
                  enabled: enabled,
                  navigation: navigationMenu,
                  countries: countries
                }

                box[0].querySelectorAll('div.accordion-item').forEach((div, i) => {
                  json[div.dataset.locale] = {
                    title: div.querySelector(`input#title_${div.dataset.locale}`).value,
                    content: div.querySelector(`input#content_${div.dataset.locale}`).value
                  }
                });

                var fd = new FormData();
                fd.append("webshop", that.uuid);
                fd.append("content", JSON.stringify(json));

                Rails.ajax({
                  url: `/${that.locale}/webshop/page`,
                  type: "post",
                  data: fd,
                  success: function(data) {
                    that.loadShopPages(that.uuid)
                    business.showAlert('success', I18n.t('webshop.message.page_saved'));
                  },
                  error: function(data) {
                    business.showAlert('danger', I18n.t('webshop.message.page_save_error'));
                  }
                });
              }
            }
          },
          onShown: function(e) {
            e.target.querySelectorAll('div.accordion-item').forEach((div, i) => {
              let el = div.querySelector('trix-editor');
              if(data.content[div.dataset.locale]) {
                el.editor.insertHTML(data.content[div.dataset.locale].content);
              }
            });

          }
        });
      },
      error: function(data) {
        business.showAlert('danger', I18n.t('webshop.message.page_save_error'));
      }
    });
  }

  loadShopVouchers(uuid) {
    var that = this;

    Rails.ajax({
      url: `/${this.locale}/webshop/${uuid}/vouchers`,
      type: "get",
      success: function(data) {
        if(data.length > 0) {
          data.forEach((voucher, i) => {
            let li = document.createElement("li");
            li.classList.add("list-group-item")
            li.setAttribute("data-enabled", voucher.enabled)
            li.setAttribute("data-uuid", voucher.uuid)
            li.innerHTML = `
              <div class="d-flex justify-content-between">
                <span class="flex-grow">
                  ${voucher.name} <span class="small text-secondary ms-2">${voucher.code}</span>
                  <span class="small ms-2">${voucher.amount ? `${voucher.currency} ${voucher.amount}` : `${voucher.percentage} %`}</span>
                </span>
                <div>
                  <span class="enabled smaller">
                    ${voucher.expired ? `<span class="badge bg-secondary"><i class="fa-solid fa-xmark fa-fw"></i> ${I18n.t('webshop.page.inactive')}</span>` : `<span class="badge bg-success"><i class="fa-solid fa-check fa-fw"></i> ${I18n.t('webshop.page.active')}</span>`}
                  </span>

                  <a href="/${I18n.locale}/webshop/${uuid}/voucher/${voucher.uuid}/edit" class="btn btn-sm btn-outline-secondary ms-1" data-remote="true">
                    <i class="fa-solid fa-pen"></i>
                  </a>
                  <button type="button" class="btn btn-sm btn-danger ms-1" data-uuid="${voucher.uuid}" data-name="${voucher.name}" data-code="${voucher.code}" data-action="click->settings#shopDeleteConfirmVoucher">
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </div>
              </div>
            `
            that.webshop_vouchersTarget.append(li);
          });
        } else {
          that.webshop_vouchersTarget.innerHTML = '';
        }
      },
      error: function(data) {

      }
    });
  }

  shopDeleteConfirmVoucher() {
    let that = this;
    let uuid = this.uuid;
    let voucher = event.target.dataset.uuid;
    let msg = `${event.target.dataset.name}<br><span class="small text-muted">${event.target.dataset.code}</span>`;

    bootbox.dialog({
      title: I18n.t('webshop.confirm.voucher_delete'),
      message: msg,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/webshop/${uuid}/voucher/${voucher}`,
              type: "delete",
              data: "",
              success: function(data) {
                that.loadShopVouchers(uuid)
              },
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  shopDeleteConfirmPage() {
    let that = this;
    let uuid = this.uuid;
    let page = event.target.dataset.uuid;
    let msg = event.target.dataset.title;

    bootbox.dialog({
      title: I18n.t('webshop.confirm.page_delete'),
      message: msg,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/webshop/${uuid}/page/${page}`,
              type: "delete",
              data: "",
              success: function(data) {
                that.loadShopPages(uuid)
              },
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  shopStartpageSections(data, locale, locales) {
    Object.keys(data.value).forEach((country) => {
      let sections = data.value[country];
      for(let i = 0; i < sections.length; i++ ) {
        let html = `
          <div class="card mb-3 rounded-1" data-style="${sections[i].style}" data-uuid="${sections[i].uuid}" data-item="${sections[i].item}" style="max-width:600px;">
            <input type="hidden" id="uuid" value="${sections[i].uuid}">
            <input type="hidden" id="style" value="${sections[i].style}">
            <input type="hidden" id="item" value="${sections[i].item}">
            <input type="hidden" id="picture" value="${sections[i].picture}">
            <input type="hidden" id="thumbnail" value="${sections[i].thumbnail}">
            <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
              <div class="handle pointer-move-up-down p-2">
                <i class="fa-solid fa-grip-vertical fa-fw"></i>
              </div>
              <div class="ms-0 me-auto small semi-bold item">
                ${sections[i].item == 'product' ? I18n.t('item.item') : ''}
                ${sections[i].item == 'category' ? I18n.t('item.category.category') : ''}
              </div>
              <div class="mt-1">
                <button type="button" class="btn btn-xs btn-outline-secondary my-auto" data-action="click->settings#shopEditItem">
                  <i class="fa-solid fa-pen"></i>
                  ${I18n.t('btn.edit')}
                </button>
                <button type="button" class="btn btn-xs btn-danger my-auto me-1" data-action="click->settings#shopDeleteItem" data-index="${i}">
                  <i class="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
            <div class="card-body pt-0">
        `
        switch(sections[i].style) {
          case 'single-left':
            html += `
              <div class="d-flex justify-content-between small border-bottom mb-2">
                <div data-header="style">
                  <i class="fa-solid fa-mattress-pillow fa-rotate-180"></i> ${I18n.t('webshop.sections.single_left')}
                </div>
                <div>
            `
            for(let l = 0; l < locales.length; l++) {
              html += `
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="locales_${i}" id="locale_${i}_${locales[l]}" value="${locales[l]}" ${locales[l] == locale ? 'checked' : ''} data-locale="${locales[l]}" data-action="click->settings#shopToggleLocale">
                  <label class="form-check-label" for="locale_${i}_${locales[l]}">
                    ${locales[l].toUpperCase()}
                  </label>
                </div>
              `
            }
            html += `
                </div>
              </div>
              <div class="d-flex justify-content-between">
            `
            for(let l = 0; l < locales.length; l++) {
              html += `
                <div class="content smaller pe-2 ${locales[l] == locale ? '' : 'd-none'}" data-locale="${locales[l]}" data-url="${sections[i][locales[l]] ? sections[i][locales[l]].url : ''}">
                  <input type="text" class="form-control-plaintext form-control-sm border p-1" id="name_${i}" value="${sections[i][locales[l]] ? sections[i][locales[l]].name : '-'}" readonly>
                  <div class="trix-content border pointer-text rounded-1 p-1 mt-2">
                    ${sections[i][locales[l]] ? sections[i][locales[l]].description.trim() : '-'}
                  </div>
                </div>
              `
            }
            html += `
                <figure class="figure" style="max-height:200px;max-width:200px;">
                  <img src="${sections[i].thumbnail}" class="figure-img img-fluid rounded-1">
                </figure>
              </div>
            `
            break;
          case 'single-right':
            html += `
              <div class="d-flex justify-content-between small border-bottom mb-2">
                <div data-header="style">
                  <i class="fa-solid fa-mattress-pillow"></i> ${I18n.t('webshop.sections.single_right')}
                </div>
                <div>
            `
              for(let l = 0; l < locales.length; l++) {
                html += `
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="locales_${i}" id="locale_${i}_${locales[l]}" value="${locales[l]}" ${locales[l] == locale ? 'checked' : ''} data-locale="${locales[l]}" data-action="click->settings#shopToggleLocale">
                    <label class="form-check-label" for="locale_${i}_${locales[l]}">
                      ${locales[l].toUpperCase()}
                    </label>
                  </div>
                `
              }
              html += `
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <figure class="figure" style="max-height:200px;max-width:200px;">
                    <img src="${sections[i].thumbnail}" class="figure-img img-fluid rounded-1">
                  </figure>
              `
              for(let l = 0; l < locales.length; l++) {
                html += `
                  <div class="content smaller ps-2 ${locales[l] == locale ? '' : 'd-none'}" data-locale="${locales[l]}" data-url="${sections[i][locales[l]] ? sections[i][locales[l]].url : ''}">
                    <input type="text" class="form-control-plaintext form-control-sm border p-1" id="name_${i}" value="${sections[i][locales[l]] ? sections[i][locales[l]].name : '-'}" readonly>
                    <div class="trix-content border pointer-text rounded-1 p-1 mt-2">
                      ${sections[i][locales[l]] ? sections[i][locales[l]].description.trim() : ''} &nbsp;
                    </div>
                  </div>
                `
              }
            html += `
              </div>
            `
            break;
          case 'banner':
            html += `
              <div class="d-flex justify-content-between small border-bottom mb-2">
                <div data-header="style">
                  <i class="fa-solid fa-image"></i>
                  ${I18n.t('webshop.sections.banner')}
                </div>
                <div>
            `
            for(let l = 0; l < locales.length; l++) {
              html += `
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="locales_${i}" id="locale_${i}_${locales[l]}" value="${locales[l]}" ${locales[l] == locale ? 'checked' : ''} data-locale="${locales[l]}" data-action="click->settings#shopToggleLocale">
                  <label class="form-check-label" for="locale_${i}_${locales[l]}">
                    ${locales[l].toUpperCase()}
                  </label>
                </div>
              `
            }
            html += `
                </div>
              </div>
              <figure class="figure w-100 me-2 text-center">
                <img src="${sections[i].thumbnail}" class="figure-img img-fluid rounded-1" style="max-height:250px;">
              </figure>
            `

            for(let l = 0; l < locales.length; l++) {
              html += `
                <div class="content smaller pe-2 ${locales[l] == locale ? '' : 'd-none'}" data-locale="${locales[l]}" data-url="${sections[i][locales[l]] ? sections[i][locales[l]].url : ''}">
                  <input type="text" class="form-control-plaintext form-control-sm border p-1" id="name_${i}" value="${sections[i][locales[l]] ? sections[i][locales[l]].name : '-'}" readonly>
                  <div class="trix-content border pointer-text rounded-1 p-1 mt-2">
                    ${sections[i][locales[l]] ? sections[i][locales[l]].description.trim() : '-'}
                  </div>
                </div>
                `
            }
            break;
          case 'gallery':
          case 'list':
            html += `
              <div class="d-flex justify-content-between small border-bottom mb-2">
                <div data-header="style">
                  <i class="fa-solid ${sections[i].style == "list" ? "fa-ellipsis" : "fa-ellipsis-stroke"}"></i>
                  ${I18n.t(`webshop.sections.${sections[i].style}`)}
                </div>
              <div>
            `
            for(let l = 0; l < locales.length; l++) {
              html += `
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="locales_${i}" id="locale_${i}_${locales[l]}" value="${locales[l]}" ${locales[l] == locale ? 'checked' : ''} data-locale="${locales[l]}" data-action="click->settings#shopToggleLocale">
                  <label class="form-check-label" for="locale_${i}_${locales[l]}">
                    ${locales[l].toUpperCase()}
                  </label>
                </div>
              `
            }
            html += `
                </div>
              </div>
            `

            for(let l = 0; l < locales.length; l++) {
              html += `
                <div class="content smaller mb-2 pe-2 ${locales[l] == locale ? '' : 'd-none'}" data-locale="${locales[l]}">
                  <input type="text" class="form-control-plaintext form-control-sm border p-1 ${sections[i].style == "gallery" ? "d-none" : ""}" id="header" value="${sections[i][locales[l]] ? sections[i][locales[l]].header : ''}" readonly>
                </div>
                `
            }

            html += `
              <div class="d-flex justify-content-between items">
            `
            for(let ii = 0; ii < sections[i].items.length; ii++) {
              let item = sections[i].items[ii];

              html += `
                <div class="item">
                  <input type="hidden" name="items[]" id="uuid" value="${item.uuid}">
                  <input type="hidden" name="items[]" id="style" value="${item.style}">
                  <input type="hidden" name="items[]" id="item" value="${item.item}">
                  <input type="hidden" name="items[]" id="picture" value="${item.picture}">
                  <input type="hidden" name="items[]" id="thumbnail" value="${item.thumbnail}">

                  <figure class="figure me-2" style="max-height:150px;max-width:150px;">
                    <img src="${item['thumbnail']}" class="figure-img img-fluid rounded-1">
              `
              for(let l = 0; l < locales.length; l++) {
                html += `
                    <figcaption class="figure-caption small ${locales[l] == locale ? '' : 'd-none'}" data-locale="${locales[l]}" data-url="${sections[i][locales[l]] ? sections[i][locales[l]].url : ''}">
                      ${item[locales[l]] ? item[locales[l]].name : '-'}
                    </figcaption>
                `
              }
              html += `
                  </figure>
              `

              for(let l = 0; l < locales.length; l++) {
                html += `
                  <input type="hidden" name="items[]" id="name_${locales[l]}" value="${item[locales[l]] ? item[locales[l]].name : '-'}">
                  <input type="hidden" name="items[]" id="description_${locales[l]}" value="${item[locales[l]] ? item[locales[l]].description : '-'}">
                `
              }
              html += `
                </div>
              `
            }
            html += `
              </div>
            `
            break;
        }
        html += `
            </div>
          </div>
        `

        let div = this.webshop_tab_content_startpage_countriesTarget.querySelector(`#startpage_country_${country}`);
        div.innerHTML += html;
      }
    })

    this.webshop_tab_content_startpage_countriesTarget.querySelectorAll("div.tab-pane").forEach((div, i) => {
      new Sortable(div, {
          handle: '.handle',
          animation: 150,
      });
    });
  }

  shopToggleLocale() {
    let locale = event.target.dataset.locale;
    let div = event.target.closest('div.card')
    div.querySelectorAll('div.content').forEach((div, i) => {
      if(div.dataset.locale == locale) {
        div.classList.remove('d-none');
      } else {
        div.classList.add('d-none');
      }
    });
    div.querySelectorAll('figcaption.figure-caption').forEach((figure, i) => {
      if(figure.dataset.locale == locale) {
        figure.classList.remove('d-none');
      } else {
        figure.classList.add('d-none');
      }
    });
  }

  shopEditItem() {
    let that = this;
    let card = event.target.closest('.card');

    let style = card.dataset.style;

    let locale = this.locale
    if(card.querySelector('input:checked')) {
      locale = card.querySelector('input:checked').value;
    } else {
      card.querySelector('input[type=radio]').checked = true;
      locale = card.querySelector('input:checked').value;
    }

    let item = card.dataset.item;
    let items = card.querySelectorAll('div.items > .item')

    let inp_uuid = card.querySelector('input#uuid')
    let inp_item = card.querySelector('input#item')
    let inp_style = card.querySelector('input#style');
    let inp_image = card.querySelector('input#picture')
    let inp_thumbnail = card.querySelector('input#thumbnail')

    let url;
    if(item == 'product') {
      url = `/${this.locale}/item/${inp_uuid.value}.json?images=true`;
    } else {
      url = `/${this.locale}/item/category/${inp_uuid.value}`
    }

    Rails.ajax({
      url: url,
      type: "get",
      success: function(data) {
        window.toggleStyle = function() {
          let sectionsList = document.querySelector('.bootbox-body').querySelectorAll("section[data-view=style-list]");
          let sectionsNonList = document.querySelector('.bootbox-body').querySelectorAll("section[data-view=style-nonlist]");
          if(event.target.value == 'list' || event.target.value == 'gallery') {
            sectionsNonList.forEach((section, i) => {
              section.classList.add('d-none');
            });
            sectionsList.forEach((section, i) => {
              section.classList.remove('d-none');

              let nav = section.querySelector("div.nav.nav-tabs")
              let navContent = section.querySelector("div.tab-content");
              if(nav) {
                if(event.target.value == 'list') {
                  nav.classList.remove('d-none');
                  navContent.classList.remove('d-none');
                } else {
                  nav.classList.add('d-none');
                  navContent.classList.add('d-none');
                }
              }
            });
          } else {
            sectionsList.forEach((section, i) => {
              section.classList.add('d-none');
            });
            sectionsNonList.forEach((section, i) => {
              section.classList.remove('d-none');
            });
          }
        }
        window.listDeleteItem = function() {
          event.target.closest('div.item').remove()

          let modal = document.querySelector(".bootbox-body")
          let style = modal.querySelector("input#style").value;
          if(style == 'gallery') {
            let divItems = modal.querySelector("div.items");

            if(divItems.children.length < 4) {
              document.querySelectorAll('button.btn-xs.add').forEach((btn, i) => {
                btn.removeAttribute("disabled")
              });
            }
          }
        }

        let i18n;
        let div_item = card.querySelector("div.small.item");
        let div_header = card.querySelector("div[data-header='style']");
        let div_items = card.querySelector("div.items");

        let item = card.querySelector("input#item").value;
        let style = card.querySelector("input#style").value;
        let name = []

        let html = `
          <input id="style" type="hidden" value="${style}">
          <input id="uuid" type="hidden" value="${inp_uuid.value}">
          <input id="item" type="hidden" value="${item}">
        `;

        for(let l = 0; l < that.locales.length; l++) {
          card.querySelectorAll(`div[data-locale="${that.locales[l]}"]`).forEach((div, i) => {
            if(div.querySelector('input.form-control-plaintext')) {
              name.push(div.querySelector('input.form-control-plaintext').value);
            }
            if(div.querySelector("div.trix-content")) {
              html += `<input id="edit_description_${that.locales[l]}" type="hidden" name="content" value="${div.querySelector("div.trix-content").innerHTML.trim()}">`
            }
          });
        }

        let image = card.querySelector("img.figure-img");

        if(data && data.i18n) {
          let arr = data.i18n.filter(i18n => {
            return i18n.locale == locale;
          });

          i18n = {}
          if(arr.length > 0) {
            i18n = arr[0];
          }
        }

        html += `
          <div class="d-flex justify-content-start mb-3 pb-2 border-bottom">
            <div class="form-check ps-1">
              <input class="form-check-input ms-2 mb-1" type="radio" name="style" id="single-left" value="single-left" ${style == 'single-left' ? 'checked' : ''} onclick="toggleStyle()">
              <label class="form-check-label smaller" for="single-left">
                <i class="fa-solid fa-mattress-pillow fa-rotate-180 ms-1"></i> ${I18n.t('webshop.sections.single_left')}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input ms-2 mb-1" type="radio" name="style" id="single-right" value="single-right" ${style == 'single-right' ? 'checked' : ''} onclick="toggleStyle()">
              <label class="form-check-label smaller" for="single-right">
                <i class="fa-solid fa-mattress-pillow ms-1"></i> ${I18n.t('webshop.sections.single_right')}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input ms-2 mb-1" type="radio" name="style" id="banner" value="banner" ${style == 'banner' ? 'checked' : ''} onclick="toggleStyle()">
              <label class="form-check-label smaller" for="banner">
                <i class="fa-solid fa-image ms-1"></i>
                ${I18n.t('webshop.sections.banner')}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input ms-2 mb-1" type="radio" name="style" id="gallery" value="gallery" ${style == 'gallery' ? 'checked' : ''} onclick="toggleStyle()">
              <label class="form-check-label smaller" for="gallery">
                <i class="fa-solid fa-ellipsis-stroke ms-1"></i>
                ${I18n.t('webshop.sections.gallery')}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input ms-2 mb-1" type="radio" name="style" id="list" value="list" ${style == 'list' ? 'checked' : ''} onclick="toggleStyle()">
              <label class="form-check-label smaller" for="list">
                <i class="fa-solid fa-ellipsis ms-1"></i>
                ${I18n.t('webshop.sections.list')}
              </label>
            </div>
          </div>
          <section data-view="style-nonlist" class="${(style == 'list' || style == 'gallery') ? 'd-none' : ''}">
            <div class="nav nav-tabs" role="tablist">
          `

          for(let l = 0; l < that.locales.length; l++) {
            html += `
              <button class="nav-item nav-link smaller ${l == 0 ? 'active' : ''}" type="button" data-bs-target="#section_${that.locales[l]}" role="tab" data-bs-toggle="tab" aria-selected="${l == 0 ? 'true' : ''}">
                ${I18n.t(`language.${that.locales[l]}`)}
              </button>
            `
          }
          html += `
            </div>
            <div class="tab-content">
            `
          for(let l = 0; l < that.locales.length; l++) {
            html += `
              <div role="tabpanel" class="tab-pane pt-2 ps-2 pe-2 ${l == 0 ? 'active show' : ''}" id="section_${that.locales[l]}">
                <div class="d-flex justify-content-between mb-3" data-controller="settings">
                  <div class="flex-fill">
                    <label for="item" class="form-label">
                      ${item == 'product' ? I18n.t('item.item') : I18n.t('item.category.category')}
                    </label>
                    <input type="text" class="form-control-plaintext" value="${i18n ? i18n.name : ''}" readonly>
                  </div>
                  <button type="button" class="btn btn-xs btn-outline-secondary my-auto text-nowrap" data-action="click->settings#shopSelectItem">
                    <i class="fa-solid fa-search"></i>
                    ${I18n.t('item.item')}
                  </button>
                  <button type="button" class="btn btn-xs btn-outline-secondary ms-2 my-auto text-nowrap" data-action="click->settings#shopSelectCategory">
                    <i class="fa-solid fa-search"></i>
                    ${I18n.t('item.category.category')}
                  </button>
                </div>
                <label for="name" class="form-label">${I18n.t('item.name')}</label>
                <input id="edit_name_${that.locales[l]}" type="text" name="name" class="form-control form-control-sm mb-3" value="${name[l]}">

                <label for="description" class="form-label">${I18n.t('item.description')}</label>
                <div class="trix-content">
                  <trix-editor input="edit_description_${that.locales[l]}" style="min-height:300px;" data-direct-upload-url="${that.directUrlValue}" data-blob-url-template="${that.blobUrlTemplateValue}">
                  </trix-editor>
                </div>
              </div>
            `
          }
          html += `
              </div>
            </section>
            <section data-view="style-list" class="${(style == 'list' || style == 'gallery') ? '' : 'd-none'}">
            <div class="nav nav-tabs ${style == 'list' ? '' : 'd-none'}" role="tablist">
          `

          for(let l = 0; l < that.locales.length; l++) {
            html += `
              <button class="nav-item nav-link smaller ${l == 0 ? 'active' : ''}" type="button" data-bs-target="#section_list_${that.locales[l]}" role="tab" data-bs-toggle="tab" aria-selected="${l == 0 ? 'true' : ''}">
                ${I18n.t(`language.${that.locales[l]}`)}
              </button>
            `
          }

          html += `
            </div>
            <div class="tab-content mb-4 ${style == 'list' ? '' : 'd-none'}">
            `

          for(let l = 0; l < that.locales.length; l++) {
            let header = card.querySelector(`div[data-locale="${that.locales[l]}"]`).querySelector('input.form-control-plaintext');
            if(header) {
              html += `
                <div role="tabpanel" class="tab-pane pt-2 ps-2 pe-2 ${l == 0 ? 'active show' : ''}" id="section_list_${that.locales[l]}">
                  <label for="header" class="form-label">${I18n.t('webshop.header')}</label>
                  <input type="text" class="form-control form-control-sm" name="header" id="header_${that.locales[l]}" data-locale="${that.locales[l]}" value="${header.value}">
                </div>
              `
            }
          }

          html += `
            </div>
          `

          html += `
            <div class="mb-4 ms-2" data-controller="settings">
              <button type="button" class="btn btn-xs btn-outline-secondary my-auto text-nowrap add" data-action="click->settings#shopSelectItem" ${(style == "gallery" && items.length >= 4) ? 'disabled' : ''}>
                <i class="fa-solid fa-plus"></i>
                ${I18n.t('item.item')}
              </button>
              <button type="button" class="btn btn-xs btn-outline-secondary ms-2 my-auto text-nowrap add" data-action="click->settings#shopSelectCategory" ${(style == "gallery" && items.length >= 4) ? 'disabled' : ''}>
                <i class="fa-solid fa-plus"></i>
                ${I18n.t('item.category.category')}
              </button>
            </div>
            <div class="d-flex justify-content-start ms-2 items">
        `
            for(let ii = 0; ii < items.length; ii++) {
              let uuid = items[ii].querySelector("input#uuid").value;
              let item = items[ii].querySelector("input#item").value;
              let picture = items[ii].querySelector("input#picture").value;
              let thumbnail = items[ii].querySelector("input#thumbnail").value;
              let name = []
              let description = []

              for(let l = 0; l < that.locales.length; l++) {
                name.push(items[ii].querySelector(`input#name_${that.locales[l]}`).value)
                description.push(items[ii].querySelector(`input#description_${that.locales[l]}`).value)
              }

              let width = "160px";
              if(style == "gallery") {
                width = "24%";
              }

              html += `
                <div class="item me-2" style="width:${width}">
                  <div class="position-absolute d-flex justify-content-between" style="">
                    <div class="handle p-1">
                      <i class="fa-solid fa-grip-vertical"></i>
                    </div>
                    <div>
                      <button type="button" class="btn btn-xs btn-danger" onclick="listDeleteItem()" tabindex=-1>
                        <i class="fa-solid fa-trash fa-fw"></i>
                      </button>
                    </div>
                  </div>

                  <input type="hidden" name="items[]" id="uuid" value="${uuid}">
                  <input type="hidden" name="items[]" id="style" value="">
                  <input type="hidden" name="items[]" id="item" value="${item}">
                  <input type="hidden" name="items[]" id="picture" value="${picture}">
                  <input type="hidden" name="items[]" id="thumbnail" value="${thumbnail}">

                  <figure class="figure me-2" style="max-height:90%;max-width:90%;">
                    <img src="${thumbnail}" class="figure-img img-fluid rounded-1">
                  </figure>

                  <div class="nav nav-tabs" role="tablist">
                `

                for(let l = 0; l < that.locales.length; l++) {

                  html += `
                    <button class="nav-item nav-link smaller ${l == 0 ? 'active' : ''}" type="button" data-bs-target="#item_${ii}_${that.locales[l]}" role="tab" data-bs-toggle="tab" aria-selected="${l == 0 ? 'true' : ''}">
                      ${that.locales[l].toUpperCase()}
                    </button>
                  `
                }
              html += `
                </div>
                <div class="tab-content mb-4">
              `

              for(let l = 0; l < that.locales.length; l++) {
                html += `
                  <div role="tabpanel" class="tab-pane pt-2 ps-2 pe-2 ${l == 0 ? 'active show' : ''}" id="item_${ii}_${that.locales[l]}">
                    <div class="input-group input-group-sm mb-3">
                      <span class="input-group-text">${that.locales[l].toUpperCase()}</span>
                      <input type="text" class="form-control form-control-sm" name="items[]" id="name" data-locale="${that.locales[l]}" value="${name[l]}">
                    </div>
                    <textarea class="form-control ${style == 'gallery' ? '' : 'd-none'}" name="items[]" id="description" data-locale="${that.locales[l]}" rows="3">${description[l]}</textarea>
                  </div>
                `
              }

              html += `
                  </div>
                </div>
              `
            }
        html += `
            </div>
          </section>
          <section data-view="style-nonlist" class="ms-2 ${(style == 'list' || style == 'gallery') ? 'd-none' : ''}">

            <label for="images" class="form-label mt-3 mb-0">${I18n.t('item.images')}</label><br>
            <div class="d-flex flex-wrap images">
          `
          if(data && data.images) {
            for(let i = 0; i < data.images.length; i++) {
              html += `
                <div>
                  <div class="form-check d-flex flex-column ps-0">
                    <input class="form-check-input ms-2 mb-1" type="radio" name="image" id="img${i}" data-uuid="${data.images[i].uuid}" data-image="${data.images[i].image ? data.images[i].image : data.images[i].picture}" data-thumbnail="${data.images[i].thumbnail}" ${data.images[i].thumbnail == image.src ? 'checked' : ''}>
                    <label class="form-check-label" for="img${i}">
                      <img src="${data.images[i].thumbnail}" class="img-thumbnail m-1" style="max-width:150px;max-height:150px;">
                    </label>
                  </div>
                </div>
              `
            }
          }
        html += `
            </div>
          </section>
        `
        var box = bootbox.dialog({
          title: I18n.t('webshop.settings.startpage_sections'),
          message: html,
          onEscape: true,
          size: 'xl',
          buttons: {
            cancel: {
              label: I18n.t('btn.cancel'),
              className: "btn-outline-secondary",
            },
            apply: {
              label: I18n.t('btn.apply'),
              className: "btn-success",
              callback: function() {
                if(inp_style.value == 'list') {
                  div_header.innerHTML = `
                    <i class="fa-solid fa-ellipsis ms-1"></i>
                    ${I18n.t('webshop.sections.list')}
                  `
                  for(let l = 0; l < that.locales.length; l++) {
                    card.querySelectorAll(`div[data-locale="${that.locales[l]}"]`).forEach((div, i) => {
                      div.querySelector('input.form-control-plaintext').value = document.querySelector('.bootbox-body').querySelector(`#header_${that.locales[l]}`).value;
                    });
                  }

                  div_items.innerHTML = ''
                  document.querySelectorAll('.bootbox-body .items > .item').forEach((item, i) => {
                    let html = `
                      <div class="item">
                        <input type="hidden" name="items[]" id="uuid" value="${item.querySelector("input#uuid").value}">
                        <input type="hidden" name="items[]" id="item" value="${item.querySelector("input#item").value}">
                        <input type="hidden" name="items[]" id="picture" value=${item.querySelector("input#picture").value}>
                        <input type="hidden" name="items[]" id="thumbnail" value=${item.querySelector("input#thumbnail").value}>

                        <figure class="figure me-2" style="max-height:150px;max-width:150px;">
                          <img src="${item.querySelector("input#thumbnail").value}" class="figure-img img-fluid rounded-1">
                      `
                    for(let l = 0; l < that.locales.length; l++) {
                      html += `
                        <figcaption class="figure-caption small ${that.locales[l] == locale ? '' : 'd-none'}" data-locale="${that.locales[l]}">
                          ${item.querySelector(`input#name[data-locale=${that.locales[l]}]`).value}
                        </figcaption>
                      `
                      }
                    html += `
                        </figure>
                      `

                    for(let l = 0; l < that.locales.length; l++) {
                      html += `
                        <input type="hidden" name="items[]" id="name_${that.locales[l]}" value="${item.querySelector(`input#name[data-locale=${that.locales[l]}]`).value}">
                        <input type="hidden" name="items[]" id="description_${that.locales[l]}" value="${item.querySelector(`textarea#description[data-locale=${that.locales[l]}]`).value}">
                      `
                    }

                    html += `
                      </div>
                    `
                    div_items.innerHTML += html;
                  });

                } else if(inp_style.value == 'gallery') {
                  div_header.innerHTML = `
                    <i class="fa-solid fa-ellipsis-stroke ms-1"></i>
                    ${I18n.t('webshop.sections.gallery')}
                  `
                  for(let l = 0; l < that.locales.length; l++) {
                    card.querySelectorAll(`div[data-locale="${that.locales[l]}"]`).forEach((div, i) => {
                      div.querySelector('input.form-control-plaintext').value = document.querySelector('.bootbox-body').querySelector(`#header_${that.locales[l]}`).value;
                    });
                  }

                  div_items.innerHTML = ''
                  document.querySelectorAll('.bootbox-body .items > .item').forEach((item, i) => {
                    let html = `
                      <div class="item">
                        <input type="hidden" name="items[]" id="uuid" value="${item.querySelector("input#uuid").value}">
                        <input type="hidden" name="items[]" id="item" value="${item.querySelector("input#item").value}">
                        <input type="hidden" name="items[]" id="picture" value=${item.querySelector("input#picture").value}>
                        <input type="hidden" name="items[]" id="thumbnail" value=${item.querySelector("input#thumbnail").value}>

                        <figure class="figure me-2" style="max-height:150px;max-width:150px;">
                          <img src="${item.querySelector("input#thumbnail").value}" class="figure-img img-fluid rounded-1">
                      `
                    for(let l = 0; l < that.locales.length; l++) {
                      html += `
                        <figcaption class="figure-caption small ${that.locales[l] == locale ? '' : 'd-none'}" data-locale="${that.locales[l]}">
                          ${item.querySelector(`input#name[data-locale=${that.locales[l]}]`).value}
                        </figcaption>
                      `
                      }
                    html += `
                        </figure>
                      `

                    for(let l = 0; l < that.locales.length; l++) {
                      html += `
                        <input type="hidden" name="items[]" id="name_${that.locales[l]}" value="${item.querySelector(`input#name[data-locale=${that.locales[l]}]`).value}">
                        <input type="hidden" name="items[]" id="description_${that.locales[l]}" value="${item.querySelector(`textarea#description[data-locale=${that.locales[l]}]`).value}">
                      `
                    }

                    html += `
                      </div>
                    `
                    div_items.innerHTML += html;
                  });

                } else {

                  inp_uuid.value = document.querySelector('.bootbox-body').querySelector("input#uuid").value;
                  inp_item.value = document.querySelector('.bootbox-body').querySelector("input#item").value;
                  inp_style.value = document.querySelector('.bootbox-body').querySelector("input[name=style]:checked").value;

                  if(inp_item.value == 'product') {
                    div_item.textContent = I18n.t('item.item');
                  }
                  if(inp_item.value == 'category') {
                    div_item.textContent = I18n.t('item.category.category');
                  }

                  switch(inp_style.value) {
                    case 'single-left':
                      div_header.innerHTML = `
                        <i class="fa-solid fa-mattress-pillow fa-rotate-180"></i> ${I18n.t('webshop.sections.single_left')}
                      `
                      break;
                    case 'single-right':
                      div_header.innerHTML = `
                        <i class="fa-solid fa-mattress-pillow"></i> ${I18n.t('webshop.sections.single_right')}
                      `
                      break;
                    case 'banner':
                      div_header.innerHTML = `
                        <i class="fa-solid fa-image"></i>
                        ${I18n.t('webshop.sections.banner')}
                      `
                      break;
                    case 'gallery':
                    case 'list':

                      break;
                  }

                  if(document.querySelector('.bootbox-body').querySelector('input[name=image]:checked')) {
                    inp_image.value = document.querySelector('.bootbox-body').querySelector('input[name=image]:checked').dataset.image;
                    inp_thumbnail.value = document.querySelector('.bootbox-body').querySelector('input[name=image]:checked').dataset.thumbnail;

                    image.src = inp_thumbnail.value;
                  }

                  for(let l = 0; l < that.locales.length; l++) {
                    card.querySelectorAll(`div[data-locale="${that.locales[l]}"]`).forEach((div, i) => {
                      div.querySelector('input.form-control-plaintext').value = document.querySelector('.bootbox-body').querySelector(`#edit_name_${that.locales[l]}`).value;

                      div.querySelector("div.trix-content").innerHTML = document.querySelector('.bootbox-body').querySelector(`#edit_description_${that.locales[l]}`).value;
                    });
                  }
                }
              }
            }
          }
        });

        new Sortable(box[0].querySelector("div.items"), {
            handle: '.handle',
            animation: 150
        });
      },
      error: function(data) {
      }
    });
  }

  shopDeleteItem() {
    let pos = event.target.dataset.index;
    let cards = this.webshop_tab_content_startpage_countriesTarget.querySelector(".tab-pane.active").querySelectorAll('.card');
    bootbox.confirm({
      message: I18n.t('webshop.confirm.remove_position'),
      buttons: {
        confirm: {
          label: I18n.t('btn.delete'),
          className: 'btn-danger'
        },
        cancel: {
          label: I18n.t('btn.cancel'),
          className: 'btn-outline-secondary'
        }
      },
      callback: function (result) {
        if(result) {
          cards[pos].remove();
        }
      }
    });
  }

  shopAppendSection() {
    let style = event.target.dataset.style;
    let cards = this.webshop_tab_content_startpage_countriesTarget.querySelector(".tab-pane.active").querySelectorAll('.card');
    let locale = this.locale;
    let locales = this.locales;
    let uuid = self.crypto.randomUUID();

    let card = document.createElement('div');
    let html = `
      <div class="card mb-3 rounded-1" data-style="${style}" data-uuid="" data-item="product" style="max-width:600px;">
        <input type="hidden" id="uuid" value="${uuid}">
        <input type="hidden" id="style" value="${style}">
        <input type="hidden" id="item" value="product">
        <input type="hidden" id="picture" value="">
        <input type="hidden" id="thumbnail" value="">

        <div class="d-flex justify-content-between align-items-center border-bottom mb-3">
          <div class="handle pointer-move-up-down p-2">
            <i class="fa-solid fa-grip-vertical fa-fw"></i>
          </div>
          <div class="ms-0 me-auto small semi-bold item">

          </div>
          <div class="mt-1">
            <button type="button" class="btn btn-xs btn-outline-secondary my-auto" data-action="click->settings#shopEditItem">
              <i class="fa-solid fa-pen"></i>
              ${I18n.t('btn.edit')}
            </button>
            <button type="button" class="btn btn-xs btn-danger my-auto me-1" data-action="click->settings#shopDeleteItem" data-index="${cards.length}">
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
        </div>
        <div class="card-body pt-0">
    `
      switch(style) {
        case 'single-left':
          html += `
            <div class="d-flex justify-content-between small border-bottom mb-2">
              <div data-header="style">
                <i class="fa-solid fa-mattress-pillow fa-rotate-180"></i> ${I18n.t('webshop.sections.single_left')}
              </div>
              <div>
          `
          for(let l = 0; l < locales.length; l++) {
            html += `
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="locales_${cards.length}" id="locale_${cards.length}_${locales[l]}" value="${locales[l]}" ${locales[l] == locale ? 'checked' : ''} data-locale="${locales[l]}" data-action="click->settings#shopToggleLocale">
                <label class="form-check-label" for="locale_${cards.length}_${locales[l]}">
                  ${locales[l].toUpperCase()}
                </label>
              </div>
            `
          }
          html += `
              </div>
            </div>
            <div class="d-flex justify-content-between">
          `
          for(let l = 0; l < locales.length; l++) {
            html += `
              <div class="content smaller pe-2 ${locales[l] == locale ? '' : 'd-none'}" data-locale="${locales[l]}" data-url="">
                <input type="text" class="form-control-plaintext form-control-sm border p-1" id="name_${cards.length}" value="-" readonly>
                <div class="trix-content border pointer-text rounded-1 p-1 mt-2">
                  -
                </div>
              </div>
            `
          }
          html += `
              <figure class="figure" style="max-height:200px;max-width:200px;">
                <img src="" class="figure-img img-fluid rounded-1">
              </figure>
            </div>
          `
          break;
        case 'single-right':
          html += `
            <div class="d-flex justify-content-between small border-bottom mb-2">
              <div data-header="style">
                <i class="fa-solid fa-mattress-pillow"></i> ${I18n.t('webshop.sections.single_right')}
              </div>
              <div>
          `
            for(let l = 0; l < locales.length; l++) {
              html += `
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="locales_${cards.length}" id="locale_${cards.length}_${locales[l]}" value="${locales[l]}" ${locales[l] == locale ? 'checked' : ''} data-locale="${locales[l]}" data-action="click->settings#shopToggleLocale">
                  <label class="form-check-label" for="locale_${cards.length}_${locales[l]}">
                    ${locales[l].toUpperCase()}
                  </label>
                </div>
              `
            }
            html += `
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <figure class="figure" style="max-height:200px;max-width:200px;">
                  <img src="" class="figure-img img-fluid rounded-1">
                </figure>
            `
            for(let l = 0; l < locales.length; l++) {
              html += `
                <div class="content smaller ps-2 ${locales[l] == locale ? '' : 'd-none'}" data-locale="${locales[l]}" data-url="">
                  <input type="text" class="form-control-plaintext form-control-sm border p-1" id="name_${cards.length}" value="-" readonly>
                  <div class="trix-content border pointer-text rounded-1 p-1 mt-2">
                    &nbsp;
                  </div>
                </div>
              `
            }
          html += `
            </div>
          `
          break;
        case 'banner':
          html += `
            <div class="d-flex justify-content-between small border-bottom mb-2">
              <div data-header="style">
                <i class="fa-solid fa-image"></i>
                ${I18n.t('webshop.sections.banner')}
              </div>
              <div>
          `
          for(let l = 0; l < locales.length; l++) {
            html += `
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="locales_${cards.length}" id="locale_${cards.length}_${locales[l]}" value="${locales[l]}" ${locales[l] == locale ? 'checked' : ''} data-locale="${locales[l]}" data-action="click->settings#shopToggleLocale">
                <label class="form-check-label" for="locale_${cards.length}_${locales[l]}">
                  ${locales[l].toUpperCase()}
                </label>
              </div>
            `
          }
          html += `
              </div>
            </div>
            <figure class="figure w-100 me-2 text-center">
              <img src="" class="figure-img img-fluid rounded-1" style="max-height:250px;">
            </figure>
          `

          for(let l = 0; l < locales.length; l++) {
            html += `
              <div class="content smaller pe-2 ${locales[l] == locale ? '' : 'd-none'}" data-locale="${locales[l]}" data-url="">
                <input type="text" class="form-control-plaintext form-control-sm border p-1" id="name_${cards.length}" value="-" readonly>
                <div class="trix-content border pointer-text rounded-1 p-1 mt-2">
                  -
                </div>
              </div>
              `
          }
          break;
        case 'gallery':
        case 'list':
          html += `
            <div class="d-flex justify-content-between small border-bottom mb-2">
              <div data-header="style">
                <i class="fa-solid fa-ellipsis"></i>
                ${I18n.t(`webshop.sections.${style}`)}
              </div>
            <div>
          `
          for(let l = 0; l < locales.length; l++) {
            html += `
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="locales_${cards.length}" id="locale_${cards.length}_${locales[l]}" value="${locales[l]}" ${locales[l] == locale ? 'checked' : ''} data-locale="${locales[l]}" data-action="click->settings#shopToggleLocale">
                <label class="form-check-label" for="locale_${cards.length}_${locales[l]}">
                  ${locales[l].toUpperCase()}
                </label>
              </div>
            `
          }
          html += `
              </div>
            </div>
          `

          for(let l = 0; l < locales.length; l++) {
            html += `
              <div class="content smaller mb-2 pe-2 ${locales[l] == locale ? '' : 'd-none'}" data-locale="${locales[l]}">
                <input type="text" class="form-control-plaintext form-control-sm border p-1 ${style == 'gallery' ? "d-none" : ""}" id="header" readonly>
              </div>
              `
          }

          html += `
            <div class="d-flex justify-content-between items">
            </div>
          `
          break;
      }
    `
        </div>
      </div>
    `

    card.innerHTML = html;

    this.webshop_tab_content_startpage_countriesTarget.querySelector(".tab-pane.active").append(card);
  }

  shopSaveSections() {
    let sections = {}
    this.webshop_tab_content_startpage_countriesTarget.querySelectorAll("div.tab-pane").forEach((div, i) => {
      sections[div.dataset.country] = []

      let cards = div.querySelectorAll('.card');
      for(let i = 0; i < cards.length; i++) {
        let locales = cards[i].querySelectorAll('.content');

        let section = {}
        if(cards[i].querySelector('#style').value == 'list' || cards[i].querySelector('#style').value == 'gallery') {
          section = {
            "style": cards[i].querySelector('#style').value,
            "items": []
          }

          let locales = cards[i].querySelectorAll('.content');
          for(let ii = 0; ii < locales.length; ii++) {
            section[locales[ii].dataset.locale] = {
              "header": locales[ii].querySelector('input').value,
            }
          }

          let items = cards[i].querySelectorAll('.items > .item');
          for(let ii = 0; ii < items.length; ii++) {
            let section_item = {
              item: items[ii].querySelector('#item').value,
              picture: items[ii].querySelector('#picture').value,
              thumbnail: items[ii].querySelector('#thumbnail').value,
              uuid: items[ii].querySelector('#uuid').value
            }

            for(let l = 0; l < this.locales.length; l++) {
              section_item[this.locales[l]] = {
                name: items[ii].querySelector(`#name_${this.locales[l]}`).value,
                description: items[ii].querySelector(`#description_${this.locales[l]}`).value,
                url: ''
              }
            }

            section['items'].push(section_item)
          }
        } else {
          section = {
            "uuid": cards[i].querySelector('#uuid').value,
            "style": cards[i].querySelector('#style').value,
            "picture": cards[i].querySelector('#picture').value,
            "thumbnail": cards[i].querySelector('#thumbnail').value,
            "item": cards[i].querySelector('#item').value
          }
          let locales = cards[i].querySelectorAll('.content');
          for(let ii = 0; ii < locales.length; ii++) {
            section[locales[ii].dataset.locale] = {
              "name": locales[ii].querySelector('input').value,
              "description": locales[ii].querySelector('div.trix-content').innerHTML.trim(),
              "url": locales[ii].dataset.url,
            }
          }
        }
        sections[div.dataset.country].push(section)
      }
    });

    var fd = new FormData();
    fd.append("webshop[uuid]", this.uuid);
    fd.append("start[sections]", JSON.stringify(sections));

    Rails.ajax({
      url: `/${this.locale}/webshop/config`,
      type: "post",
      data: fd,
      success: function(data) {
      },
      error: function(data) {
        bootbox.alert(`${error}<br><br><b>${data}</b>`);
      }
    });
  }

  shopSelectItem() {
    let dialog = bootstrap.Modal.getOrCreateInstance(document.querySelector('#itemSelectDialog'));
    dialog.show();
  }

  shopSelectCategory() {
    let dialog = bootstrap.Modal.getOrCreateInstance(document.querySelector('#categorySelectDialog'));
    dialog.show();
  }

  shopCategorySelected() {
    let that = this;
    let uuid = event.target.parentNode.dataset.uuid;

    Rails.ajax({
      url: `/${this.locale}/item/category/${uuid}?descriptions=true`,
      type: "get",
      success: function(data) {
        let modal = document.querySelector(".bootbox-body");

        let style = modal.querySelector("input[name=style]:checked").value;
        if(style == "list" || style == "gallery") {
          let divItems = modal.querySelector(".items");
          let picture;
          let thumbnail;
          if(data.images.length > 0) {
            picture = data.images[0].picture;
            thumbnail = data.images[0].thumbnail;
          }
          let width = "160px";
          if(style == "gallery") {
            width = "24%"
          }

          let html = `
            <div class="item me-2" style="width:${width};">
              <input type="hidden" name="items[]" id="uuid" value="${data.uuid}">
              <input type="hidden" name="items[]" id="style" value="">
              <input type="hidden" name="items[]" id="item" value="category">
              <input type="hidden" name="items[]" id="picture" value="${picture}">
              <input type="hidden" name="items[]" id="thumbnail" value="${thumbnail}">

              <div class="position-absolute d-flex justify-content-between">
                <div class="handle pointer-grab p-1">
                  <i class="fa-solid fa-grip-vertical"></i>
                </div>
                <div>
                  <button type="button" class="btn btn-xs btn-danger" onclick="listDeleteItem()" tabindex=-1>
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </div>
              </div>

              <figure class="figure me-2" style="max-height:90%;max-width:90%;">
                <img src="${thumbnail}" class="figure-img img-fluid rounded-1">
              </figure>

              <div class="nav nav-tabs" role="tablist">
              `

              for(let l = 0; l < that.locales.length; l++) {
              html += `
                  <button class="nav-item nav-link smaller ${l == 0 ? 'active' : ''}" type="button" data-bs-target="#item_${divItems.children.length}_${that.locales[l]}" role="tab" data-bs-toggle="tab" aria-selected="${l == 0 ? 'true' : ''}">
                  ${that.locales[l].toUpperCase()}
                  </button>
              `
              }

            html += `
              </div>
              <div class="tab-content mb-4">
            `
            for(let l = 0; l < that.locales.length; l++) {

              let name;
              let arr = data.i18n.filter(i18n => {
                return i18n.locale == that.locales[l];
              });
              if(arr.length > 0) {
                name = arr[0].name;
              }

              html += `
                <div role="tabpanel" class="tab-pane pt-2 ps-2 pe-2 ${l == 0 ? 'active show' : ''}" id="item_${divItems.children.length}_${that.locales[l]}">
                  <div class="input-group input-group-sm mb-3">
                    <span class="input-group-text" id="basic-addon1">${that.locales[l].toUpperCase()}</span>
                    <input type="text" class="form-control form-control-sm" name="items[]" id="name" data-locale="${that.locales[l]}" value="${name}">
                  </div>
                  <textarea class="form-control ${style == "gallery" ? '' : 'd-none'}" name="items[]" id="description" data-locale="${that.locales[l]}" rows="3"></textarea>
                </div>
              `
            }
          html += `
              </div>
            </div>
          `
          divItems.innerHTML += html;
        } else {
          let inp_i18n;
          let inp_uuid = modal.querySelector('input#uuid');
          let inp_name;
          let inp_item = modal.querySelector('input#item');
          let div_images = modal.querySelector('div.images');

          for(let l = 0; l < that.locales.length; l++) {
            inp_i18n = modal.querySelector(`div#section_${that.locales[l]} input.form-control-plaintext`);
            inp_name = modal.querySelector(`input#name_${that.locales[l]}`);
            let element = modal.querySelector(`div#section_${that.locales[l]} trix-editor`)
            if(inp_name) {
              let arr = data.i18n.filter(i18n => {
                return i18n.locale == that.locales[l];
              });
              if(arr.length > 0) {
                inp_i18n.value = arr[0].name;
                inp_name.value = arr[0].name;
                if(arr[0].description) {
                  element.editor.loadHTML(arr[0].description)
                }
              }
            }
          }

          inp_item.value = 'category';
          inp_uuid.value = data.uuid;

          div_images.innerHTML = '';
          for(let i = 0;i < data.images.length; i++) {
            div_images.innerHTML += `
              <div>
                <div class="form-check d-flex flex-column">
                  <input class="form-check-input ms-2 mb-1" type="radio" name="image" id="img${i}" data-uuid="" data-image="${data.images[i].picture}" data-thumbnail="${data.images[i].thumbnail}" ${i == 0 ? 'checked' : ''}>
                  <label class="form-check-label" for="img${i}">
                    <img src="${data.images[i].thumbnail}" class="img-thumbnail m-1" style="max-width:150px;max-height:150px;">
                  </label>
                </div>
              </div>
            `
          }
        }

        that.categorySelectDialog.hide();
      },
      error: function(data) {}
    })
  }

  shopSaveCatgoriesOrders() {
    let json = []
    let cards = this.webshop_categoriesTarget.querySelectorAll(".card");
    for(let i = 0; i < cards.length; i++) {
      let j = {
        uuid: cards[i].dataset.uuid,
        categories: []
      }
      let li = cards[i].querySelectorAll('li');
      for(let ii = 0; ii < li.length; ii++) {
        j.categories.push(li[ii].dataset.uuid);
      }
      json.push(j)
    }

    var fd = new FormData();
    fd.append("webshop[uuid]", this.uuid);
    fd.append("categories", JSON.stringify(json));

    Rails.ajax({
      url: `/${this.locale}/webshop/config`,
      type: "post",
      data: fd,
      success: function(data) {
      },
      error: function(data) {
        bootbox.alert(`${error}<br><br><b>${data}</b>`);
      }
    });
  }

  shopInitCategorySelectDialog() {
    this.categorySelectDialog = new bootstrap.Modal(document.querySelector('#categorySelectDialog'), {
      keyboard: true,
      focus: true
    });
  }

  shopCategoryItems() {
    let that = this;
    var $table;
    let category = event.target.dataset.uuid;

    Rails.ajax({
      url: `/${this.locale}/webshop/${this.uuid}/category/${category}/items`,
      type: "get",
      success: function(data) {
        let html = `
        <input type="hidden" id="items">
        <table class="table table-sm table-striped mb-4" data-height="${window.innerHeight-300}">
          <thead>
            <tr class="small">
              <th data-field="uuid" class="d-none">UUID</th>
              <th data-field="i18n_name" data-sortable="true">${I18n.t('item.name')}</th>
              <th data-field="number" data-sortable="true">${I18n.t('item.number')}</th>
              <th data-field="ean" data-sortable="true">${I18n.t('item.ean')}</th>
              <th data-field="color" data-sortable="true">${I18n.t('item.color')}</th>
              <th data-field="size" data-sortable="true">${I18n.t('item.size')}</th>
            </tr>
          </thead>
          <tbody class="smaller pointer-grab">
          </tbody>
          </table>
        `

        var box = bootbox.dialog({
          title: I18n.t('item.items'),
          message: html,
          onEscape: true,
          size: 'xl',
          buttons: {
            cancel: {
              label: I18n.t('btn.cancel'),
              className: "btn-outline-secondary",
            },
            delete: {
              label: I18n.t('btn.apply'),
              className: "btn-success",
              callback: function() {
                var fd = new FormData();
                fd.append("webshop", that.uuid);
                fd.append("category", category);
                fd.append("items", box[0].querySelector('input').value);

                Rails.ajax({
                  url: `/${this.locale}/webshop/category/items`,
                  type: "post",
                  data: fd,
                  success: function(data) {
                  },
                  error: function(data) {}
                });
              }
            }
          }
        });

        $table = $(box[0].querySelector('table')).bootstrapTable({data: data});
        let uuids = []
        for (var i = 0, row; row = $table[0].rows[i]; i++) {
          if(i>0) {
            uuids.push(row.cells[0].textContent)
          }
        }
        box[0].querySelector('input').value = JSON.stringify(uuids)

        new Sortable(box[0].querySelector('table > tbody'), {
          animation: 150,
          onSort: function (/**Event*/evt) {
            let uuids = []
            for (var i = 0, row; row = $table[0].rows[i]; i++) {
              if(i>0) {
                uuids.push(row.cells[0].textContent)
              }
            }
            box[0].querySelector('input').value = JSON.stringify(uuids)
        	},
        });
        box[0].querySelector(".bootbox-body").style.paddingBottom = "20px";

      },
      error: function(data) {}
    });
  }

  shopInitItemSelectDialog() {
    let that = this;

    this.itemSelectDialog = new bootstrap.Modal(document.querySelector('#itemSelectDialog'), {
      keyboard: true,
      focus: true
    });

    let table = $('#itemSelect').bootstrapTable({
      locale: this.locale,
      url: `/${this.locale}/orders/items`,
      search: true,
      loadingFontSize: '1rem',
      classes: 'table',
      searchTimeOut: 500,
      onClickRow: (row, $element, field) => {
        Rails.ajax({
          url: `/${this.locale}/item/${row.uuid}.json?images=true&descriptions=true`,
          type: "get",
          success: function(data) {

            window.switchImage = function() {
              let div = event.target.closest('div.item');
              div.querySelector("input#picture").value = event.target.dataset.picture;
              div.querySelector("input#thumbnail").value = event.target.src;
              div.querySelector("figure > img").src = event.target.src;

              bootstrap.Popover.getOrCreateInstance(div.querySelector('[data-bs-toggle="popover"]')).hide();
            }

            let modal = document.querySelector(".bootbox-body");

            let style = modal.querySelector("input[name=style]:checked").value;
            if(style == "list" || style == "gallery") {

              let divItems = modal.querySelector(".items");

              if(style == 'gallery') {
                if(divItems.children.length >= 4) {
                  return;
                }
              }
              let picture;
              let thumbnail;
              let popover = "";
              if(data.images.length > 0) {
                picture = data.images[0].image;
                thumbnail = data.images[0].thumbnail;

                for(let i = 0; i < data.images.length; i++) {
                  popover += `<img src='${data.images[i].thumbnail}' data-picture='${data.images[i].image}' class='img-thumbnail pointer-hand m-2' onclick='switchImage()'>`
                }
              }

              let width = "160px;"
              if(style == "gallery") {
                width = "24%;"
              }

              let html = `
                <div class="item me-2" style="width:${width};">
                  <input type="hidden" name="items[]" id="uuid" value="${data.uuid}">
                  <input type="hidden" name="items[]" id="style" value="">
                  <input type="hidden" name="items[]" id="item" value="product">
                  <input type="hidden" name="items[]" id="picture" value="${picture}">
                  <input type="hidden" name="items[]" id="thumbnail" value="${thumbnail}">

                  <div class="position-absolute d-flex justify-content-between">
                    <div class="handle pointer-grab p-1">
                      <i class="fa-solid fa-grip-vertical"></i>
                    </div>
                    <div>
                      <button type="button" class="btn btn-xs btn-outline-secondary" tabindex=-1 data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-content="${popover}">
                        <i class="fa-solid fa-images fa-fw"></i>
                      </button>
                      <br>
                      <button type="button" class="btn btn-xs btn-danger" onclick="listDeleteItem()" tabindex=-1>
                        <i class="fa-solid fa-trash fa-fw"></i>
                      </button>
                    </div>
                  </div>

                  <figure class="figure me-2" style="max-height:90%;max-width:90%;">
                    <img src="${thumbnail}" class="figure-img img-fluid rounded-1">
                  </figure>

                  <div class="nav nav-tabs" role="tablist">
                  `
                  for(let l = 0; l < that.locales.length; l++) {
                    html += `
                      <button class="nav-item nav-link smaller ${l == 0 ? 'active' : ''}" type="button" data-bs-target="#item_${divItems.children.length}_${that.locales[l]}" role="tab" data-bs-toggle="tab" aria-selected="${l == 0 ? 'true' : ''}">
                        ${that.locales[l].toUpperCase()}
                      </button>
                    `
                  }

                  html += `
                    </div>
                    <div class="tab-content mb-4">
                  `
                for(let l = 0; l < that.locales.length; l++) {

                  let name;
                  let arr = data.i18n.filter(i18n => {
                    return i18n.locale == that.locales[l];
                  });
                  if(arr.length > 0) {
                    name = arr[0].name;
                  }

                  html += `
                    <div role="tabpanel" class="tab-pane pt-2 ps-2 pe-2 ${l == 0 ? 'active show' : ''}" id="item_${divItems.children.length}_${that.locales[l]}">
                      <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text" id="basic-addon1">${that.locales[l].toUpperCase()}</span>
                        <input type="text" class="form-control form-control-sm" name="items[]" id="name" data-locale="${that.locales[l]}" value="${name}">
                      </div>
                      <textarea class="form-control ${style == "gallery" ? '' : 'd-none'}" name="items[]" id="description" data-locale="${that.locales[l]}" rows="3"></textarea>
                    </div>
                  `
                }
              html += `
                  </div>
                </div>
              `
              divItems.innerHTML += html;
              setTimeout(() => {
                const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
                const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl, {container: popoverTriggerEl.closest('div.item'), sanitize: false}))

                if(style == 'gallery') {
                  if(divItems.children.length == 4) {
                    modal.querySelectorAll('button.btn-xs.add').forEach((btn, i) => {
                      btn.setAttribute("disabled", "true")
                    });
                  }
                }
              }, 250);
            } else {
              let inp_uuid = modal.querySelector('input#uuid');
              let inp_i18n;
              let inp_name;
              let inp_item = modal.querySelector('input#item');
              let div_images = modal.querySelector('div.images');

              for(let l = 0; l < that.locales.length; l++) {
                inp_i18n = modal.querySelector(`div#section_${that.locales[l]} input.form-control-plaintext`);
                inp_name = modal.querySelector(`input#name_${that.locales[l]}`);
                let element = modal.querySelector(`div#section_${that.locales[l]} trix-editor`)
                if(inp_name) {
                  let arr = data.i18n.filter(i18n => {
                    return i18n.locale == that.locales[l];
                  });
                  if(arr.length > 0) {
                    inp_i18n.value = arr[0].name;
                    inp_name.value = arr[0].name;
                    element.editor.loadHTML(arr[0].description_short.body)
                  }
                }
              }

              inp_item.value = 'product';
              inp_uuid.value = data.uuid;

              div_images.innerHTML = '';
              for(let i = 0;i < data.images.length; i++) {
                div_images.innerHTML += `
                  <div>
                    <div class="form-check d-flex flex-column">
                      <input class="form-check-input ms-2 mb-1" type="radio" name="image" id="img${i}" data-uuid="${data.images[i].uuid}" data-image="${data.images[i].image}" data-thumbnail="${data.images[i].thumbnail}" ${i == 0 ? 'checked' : ''}>
                      <label class="form-check-label" for="img${i}">
                        <img src="${data.images[i].thumbnail}" class="img-thumbnail m-1" style="max-width:150px;max-height:150px;">
                      </label>
                    </div>
                  </div>
                `
              }
            }
          },
          error: function(data) {

          }
        });

        that.itemSelectDialog.hide();
      }
    });

    document.querySelector('#itemSelectDialog div.search').classList.add('w-75');
    document.querySelector('#itemSelectDialog input.search-input').style.height = '34px';

    that.vat_select = '';
    Rails.ajax({
      url: `/${that.locale}/orders/vats`,
      type: "get",
      data: "",
      success: function(data) {
        that.vat = data;
      },
      error: function(data) {
      }
    });

    that.warehouse_select = '';
    Rails.ajax({
      url: `/${that.locale}/warehouses`,
      type: "get",
      data: "",
      success: function(data) {
        that.warehouses = data;
        data.forEach((warehouse, i) => {
          that.warehouse_select += `<option value="${warehouse.uuid}" style="color: ${warehouse.data.color}">${warehouse.name}</option>`
        });
      },
      error: function(data) {
      }
    });

    window.itemNameFormatter = function(name, item) {
      return `${name}&nbsp;&nbsp;<span class="small text-muted">${[item.size, item.color].filter(item => item).join(' | ')}</span>`
    }

    window.itemStockFormatter = function(stock, item) {
      if(stock == null) {
        stock = '-'
      }
      if(item.with_stock) {
        return `
          <button type="button" class="btn btn-outline-light btn-sm text-dark" data-action="click->items#loadStockQuantities" data-uuid="${item.uuid}" data-loaded="false">
            ${stock}
          </button>
          <table class="small table-borderless table-sm warehouse-quantities"></table>
        `
      } else {
        return ''
      }
    }

    window.itemStatusFormatter = function(status, item) {
      let str = '<span class="badge bg-secondary">';
      if(item.statusname) {
        if(item.status_subscription_id) {
          str += item.statusname;
        } else {
          str += I18n.t(`item.states.${item.statusname}`);
        }
      }
      str += '</span>';
      return str
    }

    window.itemPriceFormatter = function(price, item) {
      if(price && !isNaN(price)) {
        if(item.price_with_discount) {
          return `
            <span class="text-danger">${item.price_with_discount.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}</span>
            <br>
            <small class="text-muted">
              ${item.price_with_vat.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}
            </small>
          `
        } else {
          return `
            ${price.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}
            <br>
            <small class="text-muted">
              ${item.price_with_vat.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}
            </small>
          `
        }
      } else {
        return ''
      }
    }

    window.itemReservedFormatter = function(reserved, item) {
      if(item.with_stock && item.reserved && item.reserved > 0) {
        return reserved
      } else {
        return ''
      }
    }

    window.itemAvailabilityFormatter = function(available, item) {
      if(item.with_stock) {
        return item.availability
      } else {
        return ''
      }
    }
  }
}
